import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";
import img from "../assets/images/buy_sell_section_bg.png";

function Home() {
  return (
    <Layout pageName="Home">
      <div className="main" id="home">
        {/* <div className="jumbotron">
          <div className="container">
            <h2>THE XCHANGE MARKETPLACE</h2>
            <h4>for Buyers &amp; Sellers of Testing Equipment</h4>
            <div>
              <a
                className="btn btn-primary btn-md"
                href="/loginpage/seller"
                role="button"
              >
                Join Us
              </a>
            </div>
          </div>
        </div> */}
        <Grid container sx={{ my: 5 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "url(/static/media/banner-02.553b564a.png);",
                backgroundSize: { xs: "100% 100%" },
                minHeight: "50vh",
                py: 7,
                px: { xs: 2, md: 6 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: "1.2rem", md: "3rem" },
                  mt: { xs: 3, md: 6 },
                }}
              >
                THE XCHANGE MARKETPLACE
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  pt: 3,
                  fontSize: { xs: "1rem", md: "2.5rem" },
                }}
              >
                for Buyers &amp; Sellers of Testing Equipment{" "}
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  href="/xchangeShop"
                  sx={{
                    backgroundColor: "rgb(248, 158, 35)",
                    mt: 3,
                    px: 3,
                    borderRadius: "15px",
                    fontWeight: "bold",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                    "&:hover": {
                      backgroundColor: "rgb(248, 158, 35)",
                      color: "white",
                    },
                  }}
                >
                  Start Shopping
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* end jumbotron*/}
        <div className="welcome_section">
          <p style={{ fontSize: "1em" }}>
            Developed by Scientists and Engineers, XCHANGEMarketplace.com is an
            independent subscription-based marketplace website for buying and
            selling new, used, ex-demonstration and refurbished testing
            equipment and supplies. Our aim is to offer a resale platform which
            specifically targets the underserved industrial and academic
            sectors.
          </p>
          <div className="container">
            <div className="row">
              <div className="col-md-6" style={{ marginTop: "5%" }}>
                <div className="video-wrapper">
                  <video
                    width="100%"
                    height={500}
                    src={video}
                    title="Welcome to THE XCHANGE MARKETPLACE"
                    frameBorder={0}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    autoPlay={false}
                    controls // Add controls for user interaction (play, pause, volume, etc.)
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="welcome-block">
                  <div className="heading-style1">
                    <div>
                      <img src="assets/images/icon-17.png" alt="" />
                    </div>
                    <h2>
                      <span>Welcome to</span>
                      THE XCHANGE MARKETPLACE
                    </h2>
                  </div>
                  <p style={{ fontSize: "1em" }}>
                    Developed by Scientists and Engineers,
                    XCHANGEMarketplace.com is an independent subscription-based
                    marketplace website for buying and selling new, used,
                    ex-demonstration and refurbished testing equipment and
                    supplies. Our aim is to offer a resale platform which
                    specifically targets the underserved industrial and academic
                    sectors.
                  </p>
                  <p style={{ fontSize: "1em" }}>
                    Our founders have more than 100 years working with the
                    instrumentation industry, connecting testing personnel to
                    the equipment and supplies they need. Our platform is a
                    dynamic and innovative online marketplace which is one of
                    the only to support uploaded video clips which enhances
                    users experience.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end welcome section */}
        <div
          className="buy_sell_section"
          // style={{ backgroundImage: `url(${img})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 text-center buyleft-section">
                <div className="img-block">
                  <img
                    alt=""
                    src="assets/images/img-buy.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span style={{ color: "#4ba26b", fontWeight: "bold" }}>
                    Buy
                  </span>{" "}
                  anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <div className="row">
                    <div className="col-sm-1 col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/sign-up-home">
                          <img
                            src="assets/images/icon-buy-1.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Sign up</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-sm-1 col-lg-2">
                      {" "}
                      <li>
                        <a href="https://thexchangemarketplace.com/shop-for-it-buy">
                          <img
                            src="assets/images/icon-buy-2.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Shop for it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <li>
                        <a href="https://thexchangemarketplace.com/buy-it">
                          <img
                            src="assets/images/icon-buy-3.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Buy it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <li>
                        <a href="https://thexchangemarketplace.com/receive-it-buy">
                          <img
                            src="assets/images/icon-buy-4.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Receive it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/use-it">
                          <img
                            src="assets/images/icon-buy-5.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Use it</h3>
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
              <div className="col-md-6 text-center sellright-section">
                <div className="img-block ">
                  <img
                    alt=""
                    src="assets/images/img-sell.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span style={{ fontWeight: "bold" }}>Sell</span> anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <div className="row">
                    <div className="col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/sign-up-home">
                          <img
                            src="assets/images/icon-buy-1.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Sign up</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/list-it-home">
                          <img
                            src="assets/images/icon-sell-2.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>List it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <li>
                        <a href="https://thexchangemarketplace.com/sell-it-home ">
                          <img
                            src="assets/images/icon-sell-3.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Sell it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/ship-it-home">
                          <img
                            src="assets/images/Ship-it.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Ship it</h3>
                        </a>
                      </li>
                    </div>
                    <div className="col-lg-2">
                      <li>
                        <a href="https://thexchangemarketplace.com/get-paid-home">
                          <img
                            src="assets/images/Get-Paid.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>Get Paid</h3>
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6">
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="http://metalanalysisgroup-com.sandbox.hs-sites.com/learn-more-buying-on-the-xchange"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1"
                    href="https://thexchangemarketplace.com/start-buying-home"
                    role="button"
                  >
                    Start Buying
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="http://metalanalysisgroup-com.sandbox.hs-sites.com/learn-more-seller"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1"
                    href="https://thexchangemarketplace.com/start-selling-home"
                    role="button"
                  >
                    Start Selling
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="buy_sell_section">
          <div className="container">
            <div className="row" style={{ backgroundImage: `url(${img})` }}>
              <div className="col-md-6 text-center buyleft-section">
                <div className="img-block">
                  <img
                    alt=""
                    src="assets/images/img-buy.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span>Buy</span> anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon-buy-1.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sign up</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-2.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Shop for it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-3.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Buy it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-4.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Receive it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-5.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Use it</h3>
                  </li>
                </ul>
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="#"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-buying"
                    href="buy.html"
                    role="button"
                  >
                    Start Buying
                  </a>
                </div>
              </div>
              <div className="col-md-6 text-center sellright-section">
                <div className="img-block ">
                  <img
                    alt=""
                    src="assets/images/img-sell.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span>Sell</span> anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon-buy-1.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sign up</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-sell-2.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>List it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-sell-3.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sell it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/Ship-it.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Ship it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/Get-Paid.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Get Paid</h3>
                  </li>
                </ul>
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="#"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1"
                    href="sell.html"
                    role="button"
                  >
                    Start Selling
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="buy_sell_section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center buyleft-section">
                <div className="img-block">
                  <img
                    alt=""
                    src="assets/images/img-buy.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span>Buy</span> anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon-buy-1.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sign up</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-2.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Shop for it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-3.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Buy it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-4.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Receive it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-buy-5.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Use it</h3>
                  </li>
                </ul>
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="#"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-buying"
                    href="buy.html"
                    role="button"
                  >
                    Start Buying
                  </a>
                </div>
              </div>
              <div className="col-md-6 text-center sellright-section">
                <div className="img-block ">
                  <img
                    alt=""
                    src="assets/images/img-sell.png"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <span>Sell</span> anytime with
                </h3>
                <h2>the Xchange marketplace</h2>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon-buy-1.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sign up</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-sell-2.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>List it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon-sell-3.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Sell it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/Ship-it.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Ship it</h3>
                  </li>
                  <li>
                    <img
                      src="assets/images/Get-Paid.png"
                      alt=""
                      className="img-fluid"
                    />
                    <h3>Get Paid</h3>
                  </li>
                </ul>
                <div className="mt-4 sb-btn-group">
                  <a
                    className="btn btn-primary btn-md btn-style1 btn-green mr-4"
                    href="#"
                    role="button"
                  >
                    Learn more
                  </a>
                  <a
                    className="btn btn-primary btn-md btn-style1"
                    href="sell.html"
                    role="button"
                  >
                    Start Selling
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* end buy_sell_section */}
        {/* <div className="gettheapp_section mb-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>
                  Get the App.
                  <img
                    src="assets/images/icon-mobile.png"
                    alt=""
                    className="icon-mob"
                  />
                </h2>
                <h3>Stay Connected . Get Matched . Buy &amp; Sell</h3>
                <h4>THE XCHANGE MARKETPLACE</h4>
                <div className="app-widget">
                  <h4>App Now Available</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <img src="assets/images/app-store.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/images/app-store-google.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center pt-5  mob-view">
                <img
                  src="assets/images/bg-2.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* end buy_sell_section */}
        <div className="recent_reviews_section">
          <div className="container">
            <h2 className="text-center">
              <span>Recent reviews</span> from buyers and sellers
            </h2>
            {/*Carousel Wrapper*/}
            <div
              id="multi-item-example"
              className="carousel slide carousel-multi-item"
              data-ride="carousel"
            >
              {/*Controls*/}
              <div className="controls-top">
                <a
                  className="btn-floating btn-left"
                  href="#multi-item-example"
                  data-slide="prev"
                >
                  <i className="fas fa-chevron-left" />
                </a>
                <a
                  className="btn-floating btn-right"
                  href="#multi-item-example"
                  data-slide="next"
                >
                  <i className="fas fa-chevron-right" />
                </a>
              </div>
              {/*/.Controls*/}
              {/*Slides*/}
              <div className="carousel-inner" role="listbox">
                {/*First slide*/}
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Fantastic Experience !</h4>
                          <p className="card-text">
                            I have started using the XCHANGE MARKETPLACE and it
                            has consistently exceeded my expectations. The user
                            interface is intuitive, making transactions smooth
                            and hassle-free. The customer support team is
                            responsive and helpful. I highly recommend this
                            platform for anyone looking for a reliable and
                            user-friendly exchange marketplace.
                          </p>
                          <div className="custmoer_name">
                            Cassandra Marie Thornton
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title"> Great Platform</h4>
                          <p className="card-text">
                            Overall, my experience with this exchange
                            marketplace has been positive. The variety of
                            options available for trading is impressive, and the
                            platform is easy to navigate. However, there have
                            been a few minor technical glitches that affected my
                            experience. Nevertheless, the customer support team
                            addressed my concerns promptly, earning them four
                            stars.
                          </p>
                          <div className="custmoer_name">
                            Elijah Jackson Bennett
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Top-Notch Security</h4>
                          <p className="card-text">
                            Security is my top priority when it comes to
                            exchange marketplaces, and this platform doesn't
                            disappoint. The two-factor authentication and
                            encryption measures in place provide a strong sense
                            of confidence in the safety of my transactions. The
                            seamless integration of security features with user
                            experience earns this platform a well-deserved five
                            stars.
                          </p>
                          <div className="custmoer_name">
                            Olivia Grace Henderson
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*/.First slide*/}
                {/*Second slide*/}
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">
                            Excellent Customer Service
                          </h4>
                          <p className="card-text">
                            What sets the XCHANGE MARKETPLACE apart is its
                            exceptional customer service. The support team is
                            knowledgeable, responsive, and genuinely interested
                            in resolving any issues that users may encounter.
                            Their dedication to customer satisfaction deserves a
                            five-star rating.
                          </p>
                          <div className="custmoer_name">
                            Madeline Rose Mitchell
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">
                            Reliable, Trustworthy, and Innovative!
                          </h4>
                          <p className="card-text">
                            I love the capability to post videos. This is a very
                            special feature that other marketplaces lack. Trust
                            is paramount in the world of exchange marketplaces,
                            and this platform has earned mine. The transparent
                            fee structure, quick transaction processing, and
                            reliable performance makes it a trustworthy choice.
                            I've had a seamless experience, earning this
                            platform a well-deserved five stars.
                          </p>
                          <div className="custmoer_name">
                            Abigail Elizabeth Turner
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Seamless Transactions</h4>
                          <p className="card-text">
                            I've been using this XCHANGE MARKETPLACE for various
                            transactions, and it has consistently delivered a
                            seamless experience. The quick processing times and
                            minimal downtime ensure that I can execute trades
                            efficiently. Five stars for reliability and
                            efficiency.
                          </p>
                          <div className="custmoer_name">
                            Victoria Lynn Sullivan
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*/.Second slide*/}
                {/*Third slide*/}
                {/* <div className="carousel-item">
                  <div className="row">
                    
                  </div>
                </div> */}
                {/*/.Third slide*/}
              </div>
              {/*/.Slides*/}
            </div>
            {/*/.Carousel Wrapper*/}
          </div>
        </div>
        {/* end recent_reviews_section */}
      </div>
      {/* end main */}

      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
    </Layout>
  );
}

export default Home;
