import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
// import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logout } from "../../utils/utils";
import { styled } from "@mui/material";
import api from "../../api";

const Dot = styled("div")({
  height: "13px",
  width: "13px",
  borderRadius: "50%",
  backgroundColor: "red",
});
function BuyerSidebar(props) {
  const { pageName } = props;
  const { push } = useHistory();
  // const [messages, setMessages] = useState();
  // const [messageColumn, setMessageColumn] = useState();
  const [notify, setNotify] = useState(false);
  const fetchData = async () => {
    try {
      const { data: messages } = await api.get(`/message/getAll`);
      for (let message of messages) {
        for (let item of message.messageContentObject) {
          if (item.usertype === "seller" && item.notify) {
            setNotify(true);
            break;
          }
        }
      }
      //   ?user=${JSON.parse(localStorage.getItem("data")).id}
      // setMessages(data);
    } catch (error) {
      // if (
      //   error.response !== null ||
      //   error.response.data !== null ||
      //   error.response.data.errors.length !== 0
      // ) {
      //   error.response.data.errors.forEach((e) => openSnackbar("error", e));
      // } else {
      //   openSnackbar("error", error.message);
      // }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>

      <div className="logo" onClick={() => push("/home")}>
        <img src="assets/images/logo-full.png" alt="" className="img-fluid" />
      </div>
      {/* end logo */}
      <nav className="sidebar card" style={{ overflowY: "hidden" }}>
        <ul className="nav flex-column" id="nav_accordion">
          <li
            className={
              pageName == "myaccount" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/myacc">
              {" "}
              <Link to="/myacc"> My Account</Link>
            </a>
          </li>
          <li
            className={
              pageName == "watchlist" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link">
              <Link to="/watchlist"> Watchlist</Link>{" "}
            </a>
          </li>
          <li
            className={
              pageName == "orders" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a href="/buyerorder" className="nav-link">
              <Link to="/buyerorder"> Orders</Link>{" "}
            </a>
          </li>
          <li
            className={
              pageName == "ordersummary" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/ordersummary">
              <Link to="/ordersummary"> Cart</Link>{" "}
            </a>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={
              pageName == "messages" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link " href="/msg">
              {" "}
              <Link to="/msg"> Messages</Link>
            </a>
            {notify && <Dot sx={{ mx: 2 }} />}
          </li>
          <li
            className={
              pageName == "payments" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/buyerpayment">
              <Link to="/buyerpayment">Payments</Link>
            </a>
          </li>
        </ul>
      </nav>
      <div className="logout-btn" onClick={logout}>
        <a href="#">
          <img src="assets/images/icon-logout.png" alt="" />
          Logout
        </a>
      </div>
    </div>
  );
}

export default BuyerSidebar;
