export const items = [
	{
		id: 0,
		name: 'Home',
		link: '/home',
	},
	{
		id: 1,
		name: 'The Xchange Shop',
		link: '/xchangeShop',
	},
	{
		id: 2,
		name: 'Seller Hub',
		link: '/sellerhubpage',
	},
	{
		id: 3,
		name: 'Buyer Hub',
		link: '/buyerhubpage',
	},
	// {
	// 	id: 4,
	// 	name: 'Settings',
	// 	link: '/settings',
	// },
	// {
	// 	id: 5,
	// 	name: 'Sign Out',
	// 	link: '/signout',
	// },
];
