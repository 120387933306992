import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function CustomButton(props) {
	const {
		variant,
		onClick,
		color,
		children,
		style,
		icon,
		isIcon,
		disabled,
		iconBackgroundColor,
		...rest
	} = props;
	const useStyles = makeStyles((theme) => ({
		button: (props) => ({
			...theme.typography.button,
			background: disabled
				? 'transparent'
				: variant === 'contained'
				? theme.palette[color || 'secondary'].main
				: '',
			padding: '4px 0px',
			height: 'fit-content',
			// minHeight: '30px',
			borderRadius: '3px',
			boxShadow:
				'0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06)',
			border: disabled
				? '1px solid #00000042!important'
				: variant === 'contained'
				? 'solid 1px #fb9d1f'
				: 'solid 1px #898f8f',
			color: disabled
				? '#00000042!important'
				: variant === 'contained'
				? 'white'
				: '#898f8f',
			fontWeight: 'bold!important',
			width: 'fit-content',
			transition: '0.2s all',
			outline: 'none',
			'&:active': {
				transform: 'scale(0.98)',
				boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
			},
		}),
	}));

	const classes = useStyles();
	return (
		<div>
			<ButtonBase
				variant={variant}
				className={classes.button}
				style={style}
				onClick={onClick}
				disabled={disabled}
				disableRipple
				{...rest}
			>
				<span
					style={{
						margin: '0 1em',
						paddingRight: isIcon ? '25px' : '0px',
					}}
				>
					{children}
				</span>
				{isIcon && (
					<div
						style={{
							backgroundColor: disabled
								? '#00000042'
								: iconBackgroundColor,
							borderRadius: '0 15px 15px 0',
							height: '100%',
							width: '30px',
							display: 'flex',
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							position: 'absolute',
							right: 0,
						}}
					>
						{icon == 'more' ? (
							<ExpandMoreIcon
								style={{
									color: disabled ? '#00000042' : 'white',
								}}
							/>
						) : (
							<img
								src="/images/chevron-right.svg"
								style={{
									height: '15px',
								}}
							/>
						)}
					</div>
				)}
			</ButtonBase>
		</div>
	);
}

CustomButton.defaultProps = {
	color: 'primary',
	onClick: undefined,
	variant: 'outlined',
	height: '30px',
	width: '120px',
	margin: '0px',
	isIcon: false,
	iconBackgroundColor: '#fb4868',
};

export default CustomButton;
