export function logout() {
  localStorage.clear();
  window.location.pathname = "/loginpage";
}

export function formatDate(d) {
  var formattedDate = "";
  var date = d.substring(8, 10);
  var month = d.substring(5, 7);
  var year = d.substring(0, 4);
  return month + "/" + date + "/" + year;
}

export function formatDate2(d) {
  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let month = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
  let date = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return month + " " + date + ", " + year;
}
export const formatDate3 = (usDate) => {
  // let usDate = shiftTzDateToPickerDate(date);
  return (
    (usDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    usDate.getDate().toString().padStart(2, "0") +
    "-" +
    usDate.getFullYear()
  );
};

export const formatTime = (date) => {
  return date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
