import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { useSnackbar } from "../lib/context";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import api from "../api";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../redux/actions";
import CustomLoader from "./../components/CustomLoader";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import videoplaceholder1 from "../assets/images2/video-placeholder.jpg";
import ReactPlayer from "react-player";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CompressOutlined } from "@mui/icons-material";

function Shop() {
  const { openSnackbar } = useSnackbar();
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [fproducts, setFproducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);
  const [info, setInfo] = useState({
    manufacturer: [],
    seller: [],
    categories: [],
  });
  const [hideAddToCart, setHideAddToCart] = useState(false);
  const [hideWishlist, setHideWishlist] = useState(false);
  const myState = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const [videoModal, setVideoModal] = useState({ state: false, url: "" });
  const [isDialogOpen, setIsDialogOpen] = useState({
    state: false,
    data: "",
  });
  const [option, setOption] = useState({
    page: 1,
    size: 9,
    sortBy: "orderstatus",
    sortOrder: "asc",
    search: "",
    brand: "",
    category: "",
    minPrice: 0,
    maxPrice: 1000000,
    location:[]
});
  const [search, setSearch] = useState("");
  const [totalElem, setTotalElem] = useState();
  const [filter, setFilter] = useState({});
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [errMsg,setErrMsg]=useState('')
  const handleLocationChange = (e, location) => {
    const newLocation = [...option.location];
    if (e.target.checked) {
        newLocation.push(location);
    } else {
        const index = newLocation.indexOf(location);
        if (index > -1) {
            newLocation.splice(index, 1);
        }
    }
    setOption({ ...option, location: newLocation });
};
  const fetchData = async () => {
    try {
        let payload = { ...option };
        payload.minPrice = payload.minPrice || 0;
        payload.maxPrice = payload.maxPrice || 0;
        if (!payload.search) {
            delete payload.search;
        }
        if (!payload.location || payload.location.length === 0) {
          delete payload.location;
      }
        if (!payload.minPrice) {
            delete payload.minPrice;
        }
        if (!payload.maxPrice) {
            delete payload.maxPrice;
        }
        if (!payload.brand) {
            delete payload.brand;
        }
        if (!payload.category) {
            delete payload.category;
        }
        if (option.minPrice !== undefined && option.maxPrice !== undefined) {
          payload.minPrice = option.minPrice;
          payload.maxPrice = option.maxPrice;
      }

      // Adjusted to include under $5000 filter
      // if (option.maxPrice === 5000) {
      //     payload.maxPrice = 5000;
      // }
      if (option.maxPrice === 5000) {
        payload.maxPrice = 5000;
    } else if (option.maxPrice === 20000) {
        payload.minPrice = 5000;
        payload.maxPrice = 20000;
    } else if (option.maxPrice === 50000) {
        payload.minPrice = 20000;
        payload.maxPrice = 50000;
    }
     else if (option.maxPrice === 90000) {
        payload.minPrice = 50000;
        payload.maxPrice = 90000;
    }
      
        const { data } = await api.post("/home/homepage", payload);
        if (data.statusCodeValue === 200) {
            setFeaturedProducts(data.body.content);
            setTotalElem(data.body.totalElements);
            setErrMsg(data)
            console.log(data)
        } else {
          setErrMsg(data)
          // setFeaturedProducts(data.body.content);
            openSnackbar("error", data.body.message);
        }
    } catch (error) {
        // if (error.response && error.response.data && error.response.data.errors.length !== 0) {
        //     error.response.data.errors.forEach((e) => openSnackbar("error", e));
        // } else {
            openSnackbar("error", error.message);
        
    }
};
const handleInputChange = (e) => {
  const { name, value } = e.target;
  const updatedValue = parseInt(value, 10); // Ensure value is parsed as integer
  setOption({ ...option, [name]: updatedValue });
};

  const fetchProd = async () => {
    try {
      const { data } = await api.get("sellerprofile/getSellerFeatureProducts");
      console.log(data);
      setFproducts(data);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors?.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const fetchInfo = async () => {
    try {
      const { data } = await api.get("/home/lookups");
      setInfo(data);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const [options, setOptions] = useState({
    page: 1,
    size: 5,
    category: "",
    sortBy: "price",
    sortOrder: "asc",
    search: "",
    seller: "",
  });

  const fetchAdvertisements = async () => {
    try {
      const { data } = await api.post("/advert", options);
      setAdvertisements(data.content);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const addWishlist = async (productId) => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null
    ) {
      openSnackbar("info", "Please login first to add watchlist");
    } else {
      try {
        const { data } = await api.post(`wishlist/add?productid=${productId}`);
        // setFeaturedProducts(data);
        openSnackbar("success", "Added to Watchlist");
        fetchData();
      } catch (error) {
        if (
          error.response !== null ||
          error.response.data !== null ||
          error.response.data.errors.length !== 0
        ) {
          error.response.data.errors.forEach((e) => openSnackbar("error", e));
        } else {
          openSnackbar("error", error.message);
        }
      }
    }
  };
  const removeWishlist = async (productId) => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null
    ) {
      openSnackbar("info", "Please login first to add watchlist");
    } else {
      try {
        const { data } = await api.post(
          `wishlist/remove?productid=${productId}`
        );
        openSnackbar("success", "Removed from Watchlist");
        // setFeaturedProducts(data);
        fetchData();
      } catch (error) {
        if (
          error.response !== null ||
          error.response.data !== null ||
          error.response.data.errors.length !== 0
        ) {
          error.response.data.errors.forEach((e) => openSnackbar("error", e));
        } else {
          openSnackbar("error", error.message);
        }
      }
    }
  };
  // const handleSearch = (event) => {
  //   setFilteredProducts(
  //     featuredProducts.filter((item) =>
  //       item.productname
  //         .toLowerCase()
  //         .includes(event.target.value.toLowerCase())
  //     )
  //   );
  // };
  const handleFilter = (e) => {
    console.log(e.target.name, "HanFil", e.target.value);
    let filterProds = [];
    if (e.target.name == "price") {
      let pFil = ["0", "5000", "20000", "50000", "95000"];
      for (let i = 0; i < 4; i++) {
        featuredProducts.forEach((item) => {
          //   console.log(item.price,"PP", parseInt(item.price.replace(",", "")));
          if (
            parseInt(item.price.replace(",", "")) >= parseInt(pFil[i]) &&
            parseInt(item.price.replace(",", "")) <= parseInt(pFil[i + 1]) &&
            e.target.value == pFil[i + 1]
          ) {
            filterProds.push(item);
          }
        });
      }
    } else if (e.target.name == "brand") {
      featuredProducts.forEach((item) => {
        if (item.tags[0] == e.target.value) {
          filterProds.push(item);
        } else if (item.tags[0] != "Hitachi" && e.target.value == "Others") {
          filterProds.push(item);
        }
      });
    }
    setFilteredProducts(filterProds);
  };
  const applyPriceFilter = () => {
    setOption({
      ...option,
      minPrice: minPrice || 0,
      maxPrice: maxPrice || 0,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData();
};
  const handleDialogClose = () => {
    setIsDialogOpen({ state: false, data: {} });
  };
  const handleDialogOpen = (data) => {
    setIsDialogOpen({ state: true, data });
  };
  useEffect(() => {
    fetchData();
    fetchInfo();
    fetchProd();
    fetchAdvertisements();
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("type") == "seller"
    ) {
      setHideAddToCart(true);
      setHideWishlist(true);
    }
  }, [option]);
  useEffect(() => {
    // setFilteredProducts(featuredProducts);
  }, []);

  // if (!featuredProducts) {
  //   return <CustomLoader />;
  // }
  // const filterProducts = featuredProducts.filter((prod) => {
  //   if (filter.brand && prod.manufacturer != filter.brand) {
  //     return false;
  //   }
  //   return true;
  // });
  return (
    <Layout pageName="The Xchange Shop">
      <Dialog
        open={videoModal.state}
        onClose={() => setVideoModal({ state: false, url: "" })}
        TransitionComponent={Grow}
        // style={{ backgroundColor: 'transparent' }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          {/* <HighlightOffIcon
						style={{ color: '#48a068', float: 'right' }}
						onClick={() => setVideoModal({ state: false, url: '' })}
					/> */}
          {!videoModal.isPrimary && videoModal.current > 0 && (
            <ArrowBackIosIcon
              style={{ fontSize: "60px", color: "white" }}
              onClick={() => {
                setVideoModal({
                  ...videoModal,
                  url: isDialogOpen.data.productfiles[videoModal.current - 1]
                    .url,
                  type: isDialogOpen.data.productfiles[videoModal.current - 1]
                    .fileType,
                  current: videoModal.current - 1,
                });
              }}
            />
          )}
          {videoModal.type == "video" ? (
            <ReactPlayer
              url={videoModal.url}
              // width="100%"
              // height="calc(100vh - 300px)"
              playing
              controls
            />
          ) : (
            <img src={videoModal.url} style={{ maxHeight: "70vh" }} />
          )}
          {!videoModal.isPrimary &&
            videoModal.current + 1 < videoModal.total && (
              <ArrowForwardIosIcon
                style={{ fontSize: "60px", color: "white" }}
                onClick={() => {
                  setVideoModal({
                    ...videoModal,
                    url: isDialogOpen.data.productfiles[videoModal.current + 1]
                      .url,
                    type: isDialogOpen.data.productfiles[videoModal.current + 1]
                      .fileType,
                    current: videoModal.current + 1,
                  });
                }}
              />
            )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDialogOpen.state}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDialogClose}
          />
          {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}

          <div className="order-details brdr-none">
            <div id="watchlist-img" className="od-list mt-2 mb-2">
              {/* <img src={Image} alt="" />
							<img src={Image} alt="" />
							<img src={Image} alt="" /> */}
              <div
                className="od-list mt-2 mb-2"
                style={{
                  display: "flex",
                  marginBottom: "32px",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    height: "193px",
                    minWidth: "150px",
                    // backgroundColor: 'blue',
                  }}
                >
                  <img
                    src={
                      isDialogOpen.data.primaryfile &&
                      isDialogOpen.data.primaryfile.url &&
                      isDialogOpen.data.primaryfile.url != null
                        ? isDialogOpen.data.primaryfile.url
                        : Image
                    }
                    alt=""
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "100%",
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #b1b0b0",
                    }}
                    onClick={() =>
                      setVideoModal({
                        state: true,
                        url: isDialogOpen.data.primaryfile.url,
                        type: "image",
                        isPrimary: true,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    minWidth: "250px",
                    height: "200px",
                    marginLeft: "6px",
                    // backgroundColor: 'red',
                  }}
                >
                  {isDialogOpen.state &&
                    isDialogOpen.data &&
                    isDialogOpen.data != null &&
                    isDialogOpen.data.productfiles?.map((pro_files, index) =>
                      pro_files != null &&
                      pro_files.url != null &&
                      pro_files.fileType == "video" ? (
                        <img
                          src={videoplaceholder1}
                          alt=""
                          style={{
                            objectFit: "fill",
                            width: "90px",
                            height: "90px",
                            padding: "6px",
                            borderRadius: "8px",
                            border: "1px solid #b1b0b0",
                            margin: "4px",
                          }}
                          onClick={() =>
                            setVideoModal({
                              state: true,
                              url: pro_files.url,
                              type: "video",
                              isPrimary: false,
                              current: index,
                              total: isDialogOpen.data.productfiles.length,
                            })
                          }
                        />
                      ) : (
                        <img
                          src={
                            pro_files.url && pro_files.url != null
                              ? pro_files.url
                              : Image
                          }
                          alt=""
                          style={{
                            objectFit: "fill",
                            width: "90px",
                            height: "90px",
                            padding: "6px",
                            borderRadius: "8px",
                            border: "1px solid #b1b0b0",
                            margin: "4px",
                          }}
                          onClick={() =>
                            setVideoModal({
                              state: true,
                              url: pro_files.url,
                              type: "image",
                              isPrimary: false,
                              current: index,
                              total: isDialogOpen.data.productfiles.length,
                            })
                          }
                        />
                      )
                    )}
                </div>
              </div>
            </div>

            <div className="od-list mb-2" style={{ marginTop: "30px" }}>
              <strong>{isDialogOpen?.data?.productname}</strong>
            </div>
            <div className="od-list mb-2">
              <div className="order-details brdr-none">
                {isDialogOpen?.data?.description}
              </div>
            </div>
            <div className="od-list">
              {/* <label id="watchlistitem">Catagory:</label>{' '}
							Electronics &amp; Components */}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Brand:</label>{" "}
              {isDialogOpen?.data?.manufacturer}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Location:</label> {isDialogOpen.data.address}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Price:</label> $
              {isDialogOpen.data.discountprice !== ""
                            ? isDialogOpen.data.discountprice
                            : isDialogOpen.data.price}{" "}
            </div>
          </div>
          {/* end  order-details */}

          {/* end  order-details */}
         
        </DialogContent>
      </Dialog>
      <div className="main">
        <div className="shop-feature-product">
          {/* <div className="shop-filter-nav">
            <div className="container">
              <ul className="d-flex">
                 <li className="active">
									<a href="#">Shop by Category</a>
								</li> 
                <li className="active">
                  <a href="#">Shop by Brand</a>
                </li>
                <li>
                  <a href="#">Shop by Seller</a>
                </li>
                <li>
                  <a href="#">Shop by Price</a>
                </li>
                <li>
                  <a href="#">Shop by location</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="container">
            <h2 className="text-center">Featured Products</h2>
            <div className="row">
              {fproducts && fproducts.length === 0 ? (
                <p>No Products</p>
              ) : (
                fproducts.slice(-4).map((item) => (
                  <div
                    className="col-md-3 col-6"
                    onClick={() => handleDialogOpen(item)}
                  >
                    <div className="pro-block" style={{ height: "430px" }}>
                      <div className="pro-block-img">
                        {item.primaryfile && item.primaryfile.url ? (
                          <img
                            style={{ height: "200px" }}
                            src={item.primaryfile.url}
                            alt=""
                          />
                        ) : (
                          <ImageNotSupportedIcon
                            style={{
                              fontSize: "200px",
                              color: "#6e6e6e",
                            }}
                          />
                        )}
                        {!hideWishlist && (
                          <div
                            className={
                              item.isStarred
                                ? "icon-wish-list active"
                                : "icon-wish-list"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              item.isStarred
                                ? removeWishlist(item.id)
                                : addWishlist(item.id);
                            }}
                          >
                            <i className="fas fa-heart" />
                          </div>
                        )}
                        {!hideAddToCart && (
                          <div
                            style={{
                              position: "absolute",
                              right: "15px",
                              bottom: "65px",
                              height: "35px",
                              width: "35px",
                              lineHeight: "35px",
                              background: "#fff",
                              border: "solid 1px #ccc",
                              borderRadius: "50%",
                              textAlign: "center",
                              transition: "all 0.5s",
                            }}
                            className={
                              // myState.find((p) => p.id == item.id)
                              myState.includes(item)
                                ? "icon-add-cart active"
                                : "icon-add-cart"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              // myState.find((p) => p.id == item.id)
                              myState.includes(item)
                                ? dispatch(removeFromCart(item.id))
                                : dispatch(addToCart(item));
                            }}
                          >
                            <i className="fas fa-shopping-cart" />
                          </div>
                        )}
                      </div>
                      <div className="pro-block-content">
                        <h3>{item.productname}</h3>
                        <div className="pro-price">
                          $
                          {item.discountprice !== ""
                            ? item.discountprice
                            : item.price}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end shop-feature-product */}
        <div className="search-block-sec">
          <div className="container">
            <div className="search-area">
              <input
                type="text"
                name="search"
                className="input-field"
                placeholder="Search THE XCHANGE SHOP"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <a
                className="btn btn-primary btn-md btn-style1"
                role="button"
                onClick={() => setOption({ ...option, search: search })}
              >
                Start Browsing
              </a>
              <i className="fas fa-search" />
            </div>
          </div>
        </div>
        <p style={{ fontSize: "1em" }}>
          Our aim is to offer a resale platform which specifically targets the
          underserved industrial and academic sectors.
        </p>
        {/* end search-block-sec*/}
        <div className="shop-content mt-4">
          <div className="container">
            <div className="row ">
              <div className="col-md-2">
                <h5 className="mt-2 title-shopBy">Shop by</h5>
                <div className="filter-widget mt-2">
                  {/* <div className="fw-head">
                    <a
                      className="btn btn-block"
                      data-toggle="collapse"
                      href="#collapseCatagory"
                      role="button"
                    >
                      Category
                      <i className="fas fa-chevron-down" />
                    </a>
                  </div> */}
                  <div className="collapse  " id="collapseCatagory">
                    <ul>
                      <li>
                        <a href="#">Rigaku</a>
                      </li>
                      <li>
                        <a href="#">Yamato Scientific</a>
                      </li>
                      <li>
                        <a href="#">RDM Industrial Inc.</a>
                      </li>
                      <li>
                        <a href="#">Rigaku</a>
                      </li>
                      <li>
                        <a href="#">Yamato Scientific</a>
                      </li>
                      <li>
                        <a href="#">RDM Industrial Inc.</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* end filter-widget */}
                <div className="filter-widget">
                  <div className="fw-head">
                    <a
                      className="btn btn-block"
                      data-toggle="collapse"
                      href="#collapseBrand"
                      role="button"
                    >
                      Brand
                      <i className="fas fa-chevron-down" />
                    </a>
                  </div>

                  <div className="collapse show" id="collapseBrand">
                    
                    <ul>
                    <button
                      className="btn btn-sm btn-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOption({ ...option, brand: "" })}
                    >
                      Reset
                    </button>
                      {info.manufacturer.map((item) => (
                        <li>
                          <input
                            type="radio"
                            name="brand"
                            checked={option.brand === item}
                            value={item}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              // setFilter({ ...filter, brand: e.target.value });
                              setOption({
                                ...option,
                                brand: e.target.value,
                              });
                            }}
                          />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="filter-widget">
                  <div className="fw-head">
                    <a
                      className="btn btn-block"
                      data-toggle="collapse"
                      href="#collapseCategory"
                      role="button"
                    >
                      Condition
                      <i className="fas fa-chevron-down" />
                    </a>
                  </div>

                  <div className="collapse show" id="collapseCategory">
                    
                    <ul>
                    <button
                      className="btn btn-sm btn-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOption({ ...option, category: "" })}
                    >
                      Reset
                    </button>
                      {info.categories.map((item) => (
                        <li>
                          <input
                            type="radio"
                            name="category"
                            checked={option.category === item}
                            value={item}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              // setFilter({ ...filter, brand: e.target.value });
                              setOption({
                                ...option,
                                category: e.target.value,
                              });
                            }}
                          />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* end filter-widget */}
                <div className="filter-widget">
                  <div className="fw-head">
                    <a
                      className="btn btn-block"
                      data-toggle="collapse"
                      href="#collapseSeller"
                      role="button"
                    >
                      Seller
                      <i className="fas fa-chevron-down" />
                    </a>
                  </div>
                  <div className="collapse" id="collapseSeller">
                    <ul>
                      {info.seller.map((item) => (
                        <li>
                          <input
                            type="radio"
                            name="seller"
                            checked={
                              option.seller ===
                              item.firstname + "" + item.lastname
                            }
                            value={item.firstname + "" + item.lastname}
                            // onChange={handleFilter}
                            onChange={(e) => {
                              // setFilter({ ...filter, brand: e.target.value });
                              setOption({
                                ...option,
                                seller: e.target.value,
                              });
                            }}
                          />
                          {item.firstname + " " + item.lastname}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* end filter-widget */}
                <div className="filter-widget">
                  <div className="fw-head">
                    <a
                      className="btn btn-block"
                      data-toggle="collapse"
                      href="#collapsePrice"
                      role="button"
                    >
                      Price
                      <i className="fas fa-chevron-down" />
                    </a>
                  </div>
                  <div className="collapse show" id="collapsePrice">
                    <div className="priceMinMax full-block mt-3">
                      <div className="row">
                        <div className="col-12">
                        <input
                            type="number"
                            name="minPrice"
                            min="0"
                            className="input-field"
                            placeholder="Min Price"
                            value={option.minPrice}
                            
                            onChange={(e) =>
                              setOption({ ...option, minPrice: parseInt(e.target.value, 10) || 0 })
                              // setMinPrice(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-12">
                        <input
                            type="number"
                            name="maxPrice"
                            min="0"
                            className="input-field"
                            placeholder="Max Price"
                            value={option.maxPrice}
                            onChange={(e) =>
                              setOption({ ...option, maxPrice: parseInt(e.target.value, 10) || 0 })
                              // setMaxPrice(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-12">
                          <input type="submit" defaultValue="Apply" onClick={handleSubmit} />
                        </div>
                      </div>
                    </div>
                    <ul>
                    <button
                      className="btn btn-sm btn-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOption({ ...option, maxPrice: 0 })}
                    >
                      Reset
                    </button>
                      <li>
                      <input
                                type="radio"
                                name="maxPrice"
                                checked={option.maxPrice === 5000}
                                value={5000}

                                onChange={handleInputChange}
                            />
                        Under $5000
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="maxPrice"
                          checked={option.maxPrice === 20000}

                          value={20000}
                          onChange={handleInputChange}
                        />{" "}
                        $5000 to $20000
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="maxPrice"
                          checked={option.maxPrice === 50000}
                          value={50000}
                          onChange={handleInputChange}
                        />
                        $20000 to $50000
                      </li>
                      <li>
                        <input
                          type="radio"
                          name="maxPrice"
                          checked={option.maxPrice === 90000}
                          value={90000}
                          onChange={handleInputChange}
                        />
                        $50000 to $90000
                      </li>
                    </ul>
                  </div>
                </div>
               {/* <div className="filter-widget">
                    <div className="fw-head">
                      <a
                        className="btn btn-block"
                        data-toggle="collapse"
                        href="#collapsePrice"
                        role="button"
                      >
                        Price
                        <i className="fas fa-chevron-down pull-right" />
                      </a>
                    </div>
                    <div className="collapse show" id="collapsePrice">
                        <div className="d-flex">
                          <input
                            type="number"
                            name="minPrice"
                            className="input-field"
                            placeholder="Min Price"
                            value={option.minPrice}
                            onChange={(e) =>
                              setOption({ ...option, minPrice: e.target.value })
                            }
                          />
                          <input
                            type="number"
                            name="maxPrice"
                            className="input-field"
                            placeholder="Max Price"
                            value={option.maxPrice}
                            onChange={(e) =>
                              setOption({ ...option, maxPrice: e.target.value })
                            }
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-md btn-style1 mt-2"
                          onClick={handleSubmit}
                        >
                          Apply
                        </button>
                    </div>
                  </div> */}
                {/* end filter-widget */}
                <div className="filter-widget">
    <div className="fw-head">
        <a
            className="btn btn-block"
            data-toggle="collapse"
            href="#collapseLocation"
            role="button"
        >
            Location
            <i className="fas fa-chevron-down" />
        </a>
    </div>
    <div className="collapse show" id="collapseLocation">
        <ul>
          {[...new Set (info.location?.map((address,index)=>(
 <li key={index}>
 <input
     type="checkbox"
     name="location"
     checked={option.location.includes(address)}
     onChange={(e) => handleLocationChange(e, address)}
 />{' '}
 {address}
</li>
          )))]}
            {/* {[...new Set(info?.location.map((location) => location.address))].map((address, index) => (
               
            ))} */}
        </ul>
    </div>
</div>

                {/* end filter-widget */}
              </div>
              <div className="col-md-8 ">
                <div className="full-block shopTop-filter">
                  <div className="row mb-2 ">
                    <div className="col-md-6">
                      <label className="mt-2">
                        {/* Showing 1 - {filteredProducts.length} OF {filteredProducts.length} Items */}
                      </label>
                    </div>
                    <div className="col-md-6 ">
                      {/* <div className="page-filter">
                        <label>Sort by</label>
                        <select name="page_length">
                          <option value="Best Match">Best Match</option>
                          <option value="New Arrivals">New Arrivals</option>
                          <option value="Recent products">
                            Recent products
                          </option>
                          <option value="Sale products">Sale products</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                  {/* end row */}
                </div>
                {/* <div className="full-block filter-view">
                  <ul>
                    <li>
                      <label>Rigaku</label>
                      <a href="#">
                        <i className="fas fa-times-circle" />
                      </a>
                    </li>
                    <li>
                      <label>Min $5000</label>
                      <a href="#">
                        <i className="fas fa-times-circle" />
                      </a>
                    </li>
                    <li>
                      <label>Max $20000</label>
                      <a href="#">
                        <i className="fas fa-times-circle" />
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="row">
                  {console.log(featuredProducts.length)}
                  {console.log(errMsg.statusCode)}
                  {/* featuredProducts.length === 0 && */}
                  {errMsg.statusCode ==="NOT_FOUND"   ? (
                    <div className="no-products-message">
                      No Products available
                    </div>
                  ) : (
                    featuredProducts.map((item) => (
                      <div className="col-md-4 col-6">
                        <div
                          className="pro-block"
                          style={{ height: "430px" }}
                          onClick={() => handleDialogOpen(item)}
                        >
                          <div className="pro-block-img">
                            {item.primaryfile && item.primaryfile.url ? (
                              <img
                                src={item.primaryfile.url}
                                alt=""
                                height={200}
                              />
                            ) : (
                              <ImageNotSupportedIcon
                                style={{
                                  fontSize: "200px",
                                  color: "#6e6e6e",
                                }}
                              />
                            )}
                            {!hideWishlist && (
                              <div
                                className={
                                  item.isStarred
                                    ? "icon-wish-list active"
                                    : "icon-wish-list"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  item.isStarred
                                    ? removeWishlist(item.id)
                                    : addWishlist(item.id);
                                }}
                              >
                                <i className="fas fa-heart" />
                              </div>
                            )}
                            {!hideAddToCart && (
                              <div
                                style={{
                                  position: "absolute",
                                  right: "15px",
                                  bottom: "65px",
                                  height: "35px",
                                  width: "35px",
                                  lineHeight: "35px",
                                  background: "#fff",
                                  border: "solid 1px #ccc",
                                  borderRadius: "50%",
                                  textAlign: "center",
                                  transition: "all 0.5s",
                                }}
                                className={
                                  myState.find((p) => p.id == item.id)
                                    ? // myState.includes(item)
                                      "icon-add-cart active"
                                    : "icon-add-cart"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // myState.includes(item)
                                  myState.find((p) => p.id == item.id)
                                    ? dispatch(removeFromCart(item.id))
                                    : dispatch(addToCart(item));
                                }}
                              >
                                <i className="fas fa-shopping-cart" />
                              </div>
                            )}
                          </div>
                          <div className="pro-block-content">
                            <h3 style={{ fontSize: "18px" }}>
                              {item.productname}
                            </h3>
                            <div className="pro-price">
                              $
                              {item.discountprice !== ""
                                ? item.discountprice
                                : item.price}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className="d-flex flex-row-reverse">
                  <div>
                    <select
                      className="form-select mt-2 mx-2"
                      value={option.size}
                      onChange={(e) =>
                        setOption({ ...option, size: e.target.value })
                      }
                    >
                      {/* <option selected>{option.size}</option> */}
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>

                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          option.page === 1 ? "disabled" : ""
                        }`}
                      >
                        <span
                          className="page-link"
                          onClick={() =>
                            setOption({ ...option, page: option.page - 1 })
                          }
                          style={{ color: "black" }}
                        >
                          Previous
                        </span>
                      </li>
                      {Array.from(
                        { length: Math.ceil(totalElem / option.size) },
                        (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              option.page === index + 1 ? "active" : ""
                            }`}
                          >
                            <span
                              className="page-link"
                              onClick={() =>
                                setOption({
                                  ...option,
                                  page: index + 1,
                                })
                              }
                              style={
                                {
                                  // backgroundColor: "#4ba26b",
                                  // color: "black",
                                }
                              }
                            >
                              {index + 1}
                            </span>
                          </li>
                        )
                      )}
                      <li
                        className={`page-item ${
                          option.page === Math.ceil(totalElem / option.size)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <span
                          className="page-link"
                          onClick={() =>
                            setOption({
                              ...option,
                              page: option.page + 1,
                            })
                          }
                          style={{ color: "black" }}
                        >
                          Next
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* end row */}
              </div>
              <div className="col-md-2 mt-4 pt-5">
                {advertisements.slice(-3).map((item) => {
                  if (item.file) {
                    if (item.file.fileType == "video") {
                      return (
                        <div
                          role="button"
                          className="full-block mb-4"
                          onClick={() => {
                            window.open(item.url, "_blank");
                          }}
                        >
                          <video
                            style={{ width: "100%", height: "10em" }}
                            controls
                            preload="auto"
                          >
                            <source src={item.file.url} type="video/mp4" />
                            {/* <source src='myvid.ogg' type='video/ogg' /> */}
                          </video>
                        </div>
                      );
                    } else if (item.file.fileType == "image") {
                      return (
                        <div
                          role="button"
                          className="full-block mb-4"
                          onClick={() => {
                            window.open(item.url, "_blank");
                          }}
                        >
                          <img
                            src={item.file.url}
                            // className='img-fluid'
                            alt="Advertisement"
                            style={{ width: "100%", height: "10em" }}
                          />
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div className="full-block mb-4">
                        <img
                          src="assets/images/dummy-img.png"
                          className="img-fluid"
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end main */}

      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
    </Layout>
  );
}

export default Shop;
