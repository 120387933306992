import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import productImage from "../../assets/images/pro-img.jpg";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Image from "../../assets/images/img-02.jpg";
import { useHistory } from "react-router-dom";
import { formatDate, logout } from "../../utils/utils";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import api from "../../api";
import { useSnackbar } from "../../lib/context";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import MessageIcon from "@mui/icons-material/Message";
import CustomLoader from "../../components/CustomLoader";
import { Formik, Field, Form } from "formik";
import CustomTextarea from "../../components/CustomTextarea";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Footer from "../../layout/Footer";

export const signupSchema = yup.object().shape({
  messagetext: yup.string().required("Required."),
});
function BuyerOrders() {
  const [isOpen, setIsOpen] = useState(true);
  const { push } = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState({ state: false });
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState({
    state: false,
    id: "",
    message: "",
  });
  const [orders, setOrders] = useState([]);
  const { openSnackbar } = useSnackbar();
  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "orderdate",
    sortOrder: "asc",
    search: "",
  });
  const [totalElem, setTotalElem] = useState();
  const [search, setSearch] = useState();
  const fetchData = async (signal) => {
    try {
      const { data } = await api.post("order", option, { signal: signal });
      // setOrders(data.content.filter((d) => d.orderstatus == "New Order"));
      setOrders(data.content);
      setTotalElem(data.totalElements);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [option]);

  const handleDialogClose = () => {
    setIsDialogOpen({ state: false });
  };
  const handleDialogOpen = (data) => {
    setIsDialogOpen({ state: true, data });
  };
  const handleMessageDialogClose = () => {
    setIsMessageDialogOpen({ state: false });
  };
  const handleMessageDialogOpen = (id, buyerId, sellerId) => {
    setIsMessageDialogOpen({
      state: true,
      id: id,
      buyerId: buyerId,
      sellerId: sellerId,
    });
  };
  const handleSubmit = async (values) => {
    try {
      const { data } = await api.post("message", values);
      handleMessageDialogClose();
      openSnackbar("success", "Message Sent Successfully");
      // setOrders(data.filter((d) => d.orderstatus == 'New Order'));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  // if (!orders) {
  //   return <CustomLoader />;
  // }
  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };

  const onPageOptChange = () => {};
  console.log(totalElem / option.size);
  return (
    <div>
      <Dialog
        open={isDialogOpen.state}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {isDialogOpen.state && isDialogOpen.data && (
            <>
              {/* <div className="modal" onClick={handleDialogClose}> */}
              {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
              {/* <div className="modal-body"> */}
              <HighlightOffIcon
                style={{ color: "#48a068", float: "right" }}
                onClick={handleDialogClose}
              />
              {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}
              <div className="order-details brdr-none">
                <h2>ORDER DETAILS</h2>
                <div className="od-list">
                  <label>Order Date:</label>{" "}
                  {formatDate(isDialogOpen.data?.orderdate)}
                </div>
                <div className="od-list">
                  <label>Order Number:</label> #{isDialogOpen.data?.ordernumber}
                </div>
                <div className="od-list">
                  <label>Order Total:</label> ${" "}
                  {/* {isDialogOpen.data.product[0].price} */}
                  {isDialogOpen.data?.product[0]?.discountprice !== ""
                    ? isDialogOpen.data?.product[0]?.discountprice *
                      isDialogOpen.data?.product[0]?.quantity
                    : isDialogOpen.data?.product[0]?.price *
                      isDialogOpen.data?.product[0]?.quantity}
                </div>
              </div>
              {/* end  order-details */}
              <div className="order-details brdr-none">
                <h2>PRODUCT DETAILS</h2>
                <div className="od-list">
                  <strong>Product</strong> <br />
                </div>
                <div className="od-list mt-2 mb-2">
                  {isDialogOpen.data.product &&
                  isDialogOpen.data.product[0] &&
                  isDialogOpen.data.product[0].primaryfile &&
                  isDialogOpen.data.product[0].primaryfile.url ? (
                    <img
                      src={isDialogOpen.data.product[0].primaryfile.url}
                      style={{
                        width: "80px",
                      }}
                      alt=""
                      // className="img-responsive"
                    />
                  ) : (
                    <ImageNotSupportedIcon
                      style={{
                        fontSize: "50px",
                        color: "#6e6e6e",
                      }}
                    />
                  )}
                </div>
                <div className="od-list mb-2">
                  {isDialogOpen.data.product[0].productname}
                </div>
                <div className="od-list">
                  <label>Catagory:</label> Test &amp; Measurement
                </div>
                <div className="od-list">
                  <label>Brand:</label>{" "}
                  {isDialogOpen.data.product[0].manufacturer}
                </div>
                <div className="od-list">
                  <label>Seller:</label> SeqGen
                </div>
              </div>
              {/* end  order-details */}
              <div className="row">
                <div className="col-md-6">
                  <div className="order-details brdr-none">
                    <h2>SHIPPING DETAILS</h2>
                    {/* <div className="od-list">Chris Wattson</div> */}
                    <div className="od-list">
                      {" "}
                      {isDialogOpen.data.buyerAccountData.firstname}
                    </div>
                    <div className="od-list mt-2">
                      <strong>Shipping Address</strong> <br />
                      {/* {isDialogOpen.data.deliveryaddress} */}
                      {isDialogOpen.data.buyerAccountData.address}
                    </div>
                  </div>
                  {/* end  order-details */}
                </div>
                <div className="col-md-6">
                  <div className="order-details brdr-none">
                    <h2>PREFERRED SHIPPING METHOD</h2>
                    <div className="od-list">
                      Ground Shipping
                      <br />
                      Seller Preferred Shipping
                    </div>
                  </div>
                  {/* end  order-details */}
                </div>
              </div>
              <div className="order-details brdr-none">
                <h2>PREFERRED PAYMENT METHOD</h2>
                <div className="od-list">
                  {isDialogOpen.data ? isDialogOpen.data.postcode : "NA"}
                </div>
              </div>
              {/* end  order-details */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={isMessageDialogOpen.state}
        onClose={handleMessageDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          <Formik
            initialValues={{
              orderid: isMessageDialogOpen.id,
              buyerId: isMessageDialogOpen.buyerId,
              sellerId: isMessageDialogOpen.sellerId,
              messagetext: "",
              usertype: "buyer",
            }}
            onSubmit={handleSubmit}
            validationSchema={signupSchema}
          >
            {({ values, setFieldValue }) => {
              console.log(values);
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="messagetext"
                        label="Type Message"
                        // className="form-control style2"
                        placeholder="Type Message"
                        rows={3}
                        as={CustomTextarea}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        style={{
                          float: "right",
                          backgroundColor: "#51a470",
                          marginBottom: 10,
                          color: "white",
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>

      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <BuyerSidebar pageName="orders" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <BuyerTitle />
            </div>
            {/* end box-content */}
            <div className="box-content minColheight">
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <nav>
                    <div className="nav nav-tabs">
                      <a
                        className="nav-item active"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="/buyerorder"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Current Orders
                      </a>
                      {/* <a
                        className="nav-item "
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="/buyerorderhistory"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Order History
                      </a> */}

                      <div className="order-right-filter">
                        <div className="search-blk">
                          <input
                            type="text"
                            placeholder="Search all orders"
                            className="form-control"
                            value={search}
                            onChange={(e) => {
                              // setSearch(e.target.value);
                              setOption({ ...option, search: e.target.value });
                            }}
                          />
                          <button
                            type="submit"
                            defaultValue="Search"
                            className="btn-search"
                            onClick={() => setOption({ ...option, search })}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>

                  <table class="table">
                    {orders && orders.length > 0 ? (
                      <>
                        <thead>
                          <tr>
                            {/* <th scope="col">Product</th> */}
                            <th
                              scope="col"
                              onClick={() =>
                                handleSort("product.0.productname")
                              }
                            >
                              Product{" "}
                              <span>
                                {option.sortBy === "product.0.productname" &&
                                option.sortOrder === "asc"
                                  ? "↑"
                                  : "↓"}
                              </span>
                            </th>
                            <th scope="col">Seller Name</th>
                            <th scope="col">Seller Email</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Order No.</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Order Status</th>
                            {/* <th scope="col">Payment Status</th> */}
                            <th scope="col">Chat</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order, index) => (
                            <>
                              <tr>
                                <td>
                                  <div className="td-col tg-pro">
                                    <div className="tg-pro-img">
                                      {order.product.length > 0 &&
                                      order.product[0].primaryfile &&
                                      order.product[0].primaryfile.url ? (
                                        <img
                                          src={order.product[0].primaryfile.url}
                                          style={{
                                            width: "60px",
                                            height: "50px",
                                          }}
                                          alt=""
                                          className="img-responsive"
                                        />
                                      ) : (
                                        <ImageNotSupportedIcon
                                          style={{
                                            fontSize: "50px",
                                            color: "#6e6e6e",
                                          }}
                                        />
                                      )}
                                    </div>
                                    {order.product.length > 0 &&
                                    order.product[0].productname
                                      ? order.product[0].productname
                                      : "NA"}
                                    <button
                                      type="button"
                                      className="view-more pt-3"
                                      data-toggle="modal"
                                      data-target="#viewOrderDetail"
                                      onClick={() => handleDialogOpen(order)}
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {order.sellerAccountData
                                    ? order.sellerAccountData.firstname
                                    : "NA"}{" "}
                                  {order.sellerAccountData
                                    ? order.sellerAccountData.lastname
                                    : "NA"}
                                </td>
                                <td>
                                  {order.buyerAccountData
                                    ? order.sellerAccountData.username
                                    : "NA"}
                                </td>
                                <td> {formatDate(order.orderdate)}</td>
                                <td> {order.ordernumber ?? "NA"}</td>
                                <td className="text-center">
                                  {" "}
                                  {order.product[0].quantity}
                                </td>
                                <td>
                                  $
                                  {order.product[0].discountprice !== ""
                                    ? order.product[0].discountprice
                                    : order.product[0].price}
                                </td>
                                <td>
                                  $
                                  {order.product[0].discountprice !== ""
                                    ? order.product[0].discountprice *
                                      order.product[0].quantity
                                    : order.product[0].price *
                                      order.product[0].quantity}
                                </td>
                                <td>{order.orderstatus}</td>
                                <td>
                                  <MessageIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMessageDialogOpen(order.id)
                                    }
                                  />
                                </td>
                                {/* <td></td> */}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <div>
                        <h5 className="text-center">No Orders</h5>
                      </div>
                    )}
                  </table>
                  <div className="d-flex flex-row-reverse">
                    <div>
                      <select
                        className="form-select mt-2 mx-2"
                        value={option.size}
                        onChange={(e) =>
                          setOption({ ...option, size: e.target.value })
                        }
                      >
                        {/* <option selected>{option.size}</option> */}
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            option.page === 1 ? "disabled" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({ ...option, page: option.page - 1 })
                            }
                            style={{ color: "black" }}
                          >
                            Previous
                          </span>
                        </li>
                        {Array.from(
                          { length: Math.ceil(totalElem / option.size) },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                option.page === index + 1 ? "active" : ""
                              }`}
                            >
                              <span
                                className="page-link"
                                onClick={() =>
                                  setOption({
                                    ...option,
                                    page: index + 1,
                                  })
                                }
                                style={
                                  {
                                    // backgroundColor: "#4ba26b",
                                    // color: "black",
                                  }
                                }
                              >
                                {index + 1}
                              </span>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            option.page === Math.ceil(totalElem / option.size)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({
                                ...option,
                                page: option.page + 1,
                              })
                            }
                            style={{ color: "black" }}
                          >
                            Next
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default BuyerOrders;
