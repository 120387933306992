import React from 'react'
import Layout from '../layout/Layout'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Grid,
  Button,
  ButtonBase
} from '@material-ui/core'
// import backgroundImage from '../assets/images/icon-10.jpg';
import backgroundImage from '../assets/images2/main-bg2.jpg'
import LoginForm from '../Forms/LoginForm'
import ForgotPasswordForm from '../Forms/ForgotPasswordForm'
import SignupForm from '../Forms/SignupForm'
import { useState, useEffect } from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

function Loginpage(props) {
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [dialog, setDialog] = useState({ open: false, message: '' })

  let url = new URL(window.location.href)


  useEffect(() => {
    console.log(url.searchParams.get('message'), 'Win ', window.location.href)
    if (url.searchParams.has('message')) {
      console.log('Msg ', url.searchParams.get('message'))
      setDialog({ open: true, message: url.searchParams.get('message') })
    }
  }, [])


  return (
    <>
      <Layout pageName=''>
        <div
          style={{
            minHeight: '80vh',
            height: 'fit-content',
            width: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'round'
          }}
        >
          {/* <img
					src={backgroundImage}
					style={{ width: '100%', height: '100%' }}
				/> */}
          <Grid
            container
            spacing={3}
            justifyContent='center'
            alignItems='center'
            style={{ height: '90%', paddingBottom: '12px' }}
          >
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {showForgotPassword ? (
                <ForgotPasswordForm
                  setShowForgotPassword={setShowForgotPassword}
                />
              ) : (
                <LoginForm setShowForgotPassword={setShowForgotPassword} />
              )}
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SignupForm />
            </Grid>
          </Grid>
        </div>
      </Layout>
      <Dialog
        open={dialog.open}
        fullWidth={true}
        maxWidth='xs'
        onClose={() => setDialog({ open: false })}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							 className="modal-content"
							 onClick={(e) => e.stopPropagation()}
						 > */}
          {/* <div className="modal-body"> */}
          <div>
            <HighlightOffIcon
              style={{ color: '#48a068', float: 'right', cursor: 'pointer' }}
              onClick={() => {
                setDialog({ open: false, message: dialog.message })
              }}
            />
          </div>
          <div>
            {dialog.message.includes('Successful') ? (
              <Typography
                style={{
                  color: '#48a068',
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  margin: '.7em .5em 1em .5em'
                }}
              >
                Payment Successfull !!
              </Typography>
            ) : (
              <Typography
                style={{
                  color: 'red',
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  margin: '.7em .5em 1em .5em'
                }}
              >
                Payment Failed !!
              </Typography>
            )}
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={() => setDialog({ open: false })}>Cancel</Button>
              <Button onClick={() => handleDelete()}>Confirm</Button>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Loginpage
