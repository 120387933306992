import React from 'react';
import {
  Grid,
  InputLabel,
  Card,
  MenuItem,
  FormControl,
  Select,
  Chip,
  FormHelperText,
  ListItemText,
  Checkbox
} from '@material-ui/core';

function FormikSelect(props) {
  const {
    form,
    field,
    setFieldValue,
    label,
    value,
    onChange,
    options,
    textField,
    valueField,
    placeholder,
    size,
    variant,
    multiple,
    fullWidth
  } = {
    ...props
  };

  const currentError = form.touched[field.name] && form.errors[field.name];
  return (
    <FormControl
      fullWidth={fullWidth != null ? fullWidth : true}
      variant={variant ? variant : 'outlined'}
      className="text-white-50"
      error={currentError}
      size={size}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        //   labelId="demo-simple-select-outlined-label"
        //   id="demo-simple-select-outlined"
        value={value}
        onChange={onChange}
        label={label}
        multiple={multiple}
        renderValue={
          multiple
            ? (selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        options.filter((item) => item.value == value)[0].label
                      }
                    />
                  ))}
                </div>
              )
            : false
        }
        {...props}>
        {/* {value == '' && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )} */}
        {!multiple &&
          options.map((option, index) => (
            <MenuItem value={option[valueField]}>{option[textField]}</MenuItem>
          ))}
        {multiple &&
          options.map((option) => (
            <MenuItem key={option[valueField]} value={option[valueField]}>
              <Checkbox checked={value.indexOf(option[valueField]) > -1} />
              <ListItemText primary={option[textField]} />
            </MenuItem>
          ))}
      </Select>
      {currentError && (
        <FormHelperText className="text-danger ml-3">
          {currentError}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormikSelect;
