import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/utils";
import "font-awesome/css/font-awesome.min.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { styled } from "@mui/material";
import api from "../../api";

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem;
  font-weight: 800;
  font-size: 1rem;
`;

const options = [
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/order"> In Progress</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/completedorder">Completed</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/shipped">Shipped</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/canceled">Cancelled</Link>{" "}
    </a>
  </li>,
];

const optionz = [
  <li id="active" className="nav-item">
    <a className="nav-link">
      <Link to="/products">Recent Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/allproducts">All Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a className="nav-link">
      <Link to="/addproduct">Add A Product</Link>{" "}
    </a>
  </li>,
];
const Dot = styled("div")({
  height: "13px",
  width: "13px",
  borderRadius: "50%",
  backgroundColor: "red",
});
function SellerSidebar(props) {
  const { pageName } = props;
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(false);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const [notify, setNotify] = useState(false);
  const fetchData = async () => {
    try {
      const { data: messages } = await api.get(`/message/getAll`);
      for (let message of messages) {
        for (let item of message.messageContentObject) {
          if (item.usertype === "buyer" && item.notify) {
            setNotify(true);
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="logo" onClick={() => push("/home")}>
        <img src="assets/images/logo-full.png" alt="" className="img-fluid" />
      </div>
      <nav className="sidebar card" style={{ overflowY: "hidden" }}>
        <ul className="nav flex-column" id="nav_accordion">
          <li
            className={
              pageName === "overview" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/overview">
              {" "}
              <Link to="/overview"> Overview</Link>
            </a>
          </li>
          <li
            className={
              pageName === "products"
                ? `nav-item has-submenu active`
                : `nav-item has-submenu `
            }
          >
            <a
              onClick={() => {
                toggling1();
              }}
              className="nav-link"
              style={{ cursor: "pointer" }}
            >
              Products
              <i
                className="fas fa-chevron-right"
                style={{ transform: isOn ? "rotate(90deg)" : "rotate(0deg)" }}
              />
            </a>

            {isOn && (
              <DropDownListContainer>
                <DropDownList>
                  {optionz.map((option) => (
                    <ListItem onClick={() => onOptionzClicked(optionz)}>
                      {option}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </li>
          <li
            className={
              pageName === "orders"
                ? `nav-item has-submenu active`
                : `nav-item has-submenu `
            }
          >
            <a
              onClick={toggling}
              className="nav-link"
              style={{ cursor: "pointer" }}
            >
              Orders
              <i
                className="fas fa-chevron-right"
                style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
              />
            </a>

            {isOpen && (
              <DropDownListContainer className="abs">
                <DropDownList>
                  {options?.map((option) => (
                    <ListItem
                      onClick={onOptionClicked(option)}
                      key={Math.random()}
                    >
                      {option}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={
              pageName === "messages" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/messages">
              <Link to="/messages"> Messages</Link>{" "}
            </a>
            {notify && <Dot sx={{ mx: 2 }} />}
          </li>
          <li
            className={
              pageName === "payments" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/payments">
              {" "}
              <Link to="/payments"> Payments</Link>
            </a>
          </li>
          <li
            className={
              pageName === "myaccount" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/myaccount">
              <Link to="/myaccount">My Account</Link>
            </a>
          </li>
        </ul>
      </nav>
      <div className="logout-btn" onClick={logout}>
        <a href="#">
          <img src="assets/images/icon-logout.png" alt="" />
          Logout
        </a>
      </div>
    </>
  );
}

export default SellerSidebar;
