import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../lib/context";
import api from "../../api";
import { toast } from "react-toastify";
import CustomTextField2 from "../../components/CustomTextField2";
import CustomTextField from "../../components/CustomTextField";
import { Formik, Form, Field } from "formik";
import CustomTextField3 from "../../components/CustomTextField3";
import CustomLoader from "../../components/CustomLoader";
import * as yup from "yup";
import { logout } from "../../utils/utils";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Grid } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import Footer from "../../layout/Footer";

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};
export const validationSchema = yup.object().shape({
  username: yup.string().email("Invalid Email.").required("Email is required."),
  firstname: yup.string().required("First Name is required."),
  lastname: yup.string().required("Last Name is required."),
  phone: yup.string().required("Phone is required."),
  address: yup.string().required("Address is required."),
  company: yup.string().required("Company Name is required."),
});
export const dialogSchema = yup.object().shape({
  oldPassword: yup.string().required("Please enter old password"),
  newPassword: yup
    .string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
  confirmPassword: yup
    .string()
    .required("Please re-type your password")

    .oneOf([yup.ref("newPassword")], "Passwords does not match"),
});

function MyAcc() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [myAccountDetails, setMyAccountDetails] = useState();
  const { push } = useHistory();
  const { openSnackbar } = useSnackbar();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showCrop, setShowCrop] = useState(true); // Added state for showing crop UI
  const dropzoneRef = useRef();
  const [files, setFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const imageFiles = acceptedFiles.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );
    if (imageFiles.length > 0) {
      setFiles(acceptedFiles);
      console.log("hererererer");
      console.log(acceptedFiles);
    } else {
      console.log("Please upload only image files (JFIF, PNG).");
    }
    handleUpload(acceptedFiles);
  };

  const acceptedFileTypes = ["image/jpeg"]; // Define the accepted image file types
  const onDrop = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );
    if (imageFiles.length > 0) {
      setFiles(acceptedFiles);
      console.log("hererererer");
      console.log(acceptedFiles);
    } else {
      console.log("Please upload only image files (JFIF, JPEG, PNG).");
    }
  }, []);
  const initialValues = {
    username: "",
    password: "",
    isRemeberMe: false,
    type: "buyer",
  };
  const initialValuesDialog = {
    oldPassword: "",
    newPassword: "",
    accountType: "",
    confirmPassword: "",
    accountType: "Buyer",
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes.join(","),
    onDrop,
  });

  useEffect(() => {
    fetchData();
  }, []);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const fetchData = async () => {
    try {
      const { data } = await api.get("buyerprofile/get-profile");
      setMyAccountDetails(data);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const handleSubmit = async (values) => {
    try {
      const { data } = await api.put("buyerprofile/update", values);
      // localStorage.setItem('data', JSON.stringify(data.data));
      // localStorage.setItem('token', 'Bearer ' + data.authToken);
      // window.location.pathname == '/loginpage/buyer'
      // 	? push('/myacc')
      // 	: push('/overview');
      openSnackbar("success", "My account is updated");
      // push('/allproducts');
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const handleChangePassword = async (values) => {
    try {
      console.log("values", values);
      const newVal = { ...values, id: myAccountDetails.id };

      const { data } = await api.post("auth/buyer-change-password", newVal);
      handleDialogClose();
      openSnackbar("success", "Password is updated");
      // push('/allproducts');
      // fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  if (!myAccountDetails) {
    // return <CustomLoader />;
  }
  // const [showImage,set]
  const handleUpload = async (fileUpload) => {
    console.log(fileUpload);
    const formData = new FormData();
    // for (let i = 0; i < files.length; i++) {
    formData.append("file", fileUpload[0]);
    // }
    setTimeout(async (e) => {
      const { data, status } = await api.put(
        `/signup/uploadBuyerProfilePicture?email=${myAccountDetails.username}`,
        formData
      );

      if (status == 200) {
      } else {
      }
    }, 2000);
  };
  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDialogClose}
          />
          {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}
          <Formik
            initialValues={initialValuesDialog}
            onSubmit={handleChangePassword}
            validationSchema={dialogSchema}
          >
            {({ values, setFieldValue }) => {
              console.log("values", values);
              return (
                <div id="changepassword" className="form-sec full-block mb-4">
                  <h3 id="text">CHANGE PASSWORD</h3>

                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          as={CustomTextField}
                          name="oldPassword"
                          label="Old Password"
                          placeholder="Password"
                          type="password"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={CustomTextField}
                          name="newPassword"
                          label="New Password"
                          placeholder="Password"
                          type="password"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={CustomTextField}
                          name="confirmPassword"
                          label="Confirm Password"
                          placeholder="Password"
                          type="password"
                          setFieldValue={setFieldValue}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "12px" }}>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          style={{ width: "100%" }}
                          color="primary"
                          // disabled={rest.isSubmitting}
                        >
                          Change Password
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Form>

                  {/* <div className="row mt-4">
              <div className="col-md-12">
                <input
                  type="password"
                  name="Old Password"
                  placeholder="Old Password"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <input
                  type="password"
                  name="New Password"
                  placeholder="New Password"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <input
                  type="password"
                  name="New Password"
                  placeholder="Confirm Password"
                  className="form-control"
                />
              </div>
            </div>
            <div className="full-block mt-5">
              <button
                onClick={() => handleSubmit}
                className="btn btn-style1 btn-green"
              >
                Change Password
              </button>
            </div> */}
                </div>
              );
            }}
          </Formik>

          {/* <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue, ...rest }) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name="username"
                        label="Email"
                        placeholder="Email"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type="password"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "12px" }}>
                      <CustomButton
                        type="submit"
                        variant="contained"
                        style={{ width: "100%" }}
                        color="primary"
                        disabled={rest.isSubmitting}
                      >
                        Change Password
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik> */}

          {/* end  order-details */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </DialogContent>
      </Dialog>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <BuyerSidebar pageName="myaccount" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <BuyerTitle />
          </div>
          {/* end box-content */}

          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item active "
                      id="nav-account-tab"
                      data-toggle="tab"
                      href="#nav-account"
                      role="tab"
                      aria-controls="nav-account"
                      aria-selected="true"
                    >
                      <Link to="/sellerhub">MY ACCOUNT</Link>
                    </a>
                  </div>
                </nav>
                <Formik
                  enableReinitialize
                  initialValues={myAccountDetails}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => {
                    console.log("values", values);
                    return (
                      <Form>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade  show active"
                            id="nav-account"
                            role="tabpanel"
                            aria-labelledby="nav-account-tab"
                          >
                            <div className="form-sec full-block mb-4">
                              <div className="col-md-12">
                                <h3>
                                  Upload a public profile picture{" "}
                                  <small>(optional) </small>
                                </h3>
                                <Dropzone
                                  onDrop={handleDrop}
                                  accept="image/jpeg"
                                  multiple={false}
                                  ref={dropzoneRef}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      {...getRootProps()}
                                      className="dropzone"
                                    >
                                      <input {...getInputProps()} />
                                      <a>
                                        <img
                                          style={{ borderRadius: "200px" }}
                                          src={
                                            myAccountDetails?.profilePhoto
                                              ? myAccountDetails?.profilePhoto
                                              : "assets/images/upload-img.png"
                                          }
                                          alt=""
                                          width={150}
                                          height={150}
                                        />
                                      </a>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                              <h3 className="my-5">Personal info</h3>
                              <div className="row mt-2">
                                <div className="col-md-4">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="firstname"
                                    placeholder="First Name"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="lastname"
                                    placeholder="Last Name"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </div>
                              {/* end row */}
                              <div className="row mt-3">
                                <div className="col-md-4">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="username"
                                    placeholder="Email"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Field
                                    as={CustomTextField3}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                                <div
                                  id="changepasswordbutton"
                                  onClick={handleDialogOpen}
                                  className="col-md-4"
                                >
                                  <a>Change password</a>
                                </div>
                              </div>

                              {/* end row */}

                              <div className="row mt-3">
                                <div className="col-md-8">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="company"
                                    placeholder="Company Name"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </div>
                              {/* end row */}
                            </div>
                            {/* end full-block */}
                            <div className="form-sec full-block mb-4">
                              <h3>Contact info</h3>
                              <div className="row">
                                <div className="col-md-4">
                                  {/* <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    // label="Contact info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  /> */}
                                  <PhoneInput
                                    containerStyle={{ marginTop: "1%" }}
                                    country="us"
                                    // error={!!errors?.phone} // Convert errors.phone to boolean
                                    inputStyle={{
                                      // border: errors?.phone
                                      //   ? "1px solid red"
                                      //   : "1px solid #ced4da",
                                      width: "100%",
                                      height: "47px",
                                      borderRadius: "5px",
                                      marginTop: "1px",
                                      paddingLeft: "50px",
                                    }}
                                    value={values?.phone}
                                    onChange={(e) => {
                                      setFieldValue("phone", e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="username"
                                    placeholder="Email"
                                    // label="Personal Info"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                              {/* end row */}
                            </div>
                            {/* end full-block */}
                            <div className="form-sec full-block mb-4">
                              <h3>Address</h3>
                              <div className="row">
                                <div className="col-md-8">
                                  <Field
                                    as={CustomTextField3}
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    // label="Addresses"
                                    className="form-control"
                                    // className1="row mt-2"
                                    // className2="col-md-8"
                                    setFieldValue={setFieldValue}
                                  />
                                </div>
                              </div>

                              {/* end row */}
                            </div>
                            {/* end full-block */}
                            <div className="emptyspace50"> &nbsp;</div>
                            <div className="full-block mt-3">
                              <button className="btn btn-style1 btn-green">
                                Save
                              </button>
                            </div>
                            {/* end full-block */}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </div>
      {/* end right -sidebar */}
      <Footer />
    </div>
  );
}

export default MyAcc;
