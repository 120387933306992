import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "../../assets/css/style.css";
import AddIcon from "@material-ui/icons/Add";
import {
  Typography,
  Button,
  Dialog,
  Grow,
  DialogContent,
} from "@material-ui/core";
import uploadImage from "../../assets/images2/upload5.png";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikAutocomplete from "../../components/FormikAutocomplete";
import CustomTextField2 from "../../components/CustomTextField2";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../lib/context";
import api, { toFormData } from "../../api";
import videoplaceholder from "../../assets/images2/video-placeholder1.jpg";
import videoplaceholder1 from "../../assets/images2/video-placeholder.jpg";
import ModalImage from "react-modal-image";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomLoader from "../../components/CustomLoader";
import ReactPlayer from "react-player";
import { logout } from "../../utils/utils";
import SellerSidebar from "../Sidebar/SellerSidebar";
import SellerTitle from "../PageTitle/SellerTitle";
import CustomTextarea2 from "../../components/CustomTextarea2";
import * as yup from "yup";
import Footer from "../../layout/Footer";
import CreatableSelect from "react-select/creatable";

export const addProdSchema = yup.object().shape({
  productname: yup
    .string()
    .max(40, "Product Name must be at most 40 characters.")
    .required("Required."),
  // discountprice: yup.number().min(1, "Invalid Discount Price"),
  discountprice: yup.number(),
  price: yup.number().min(1, "Invalid Price").required("Required."),
  sellinginstrument: yup.string().required("Required."),
  description: yup
    .string()
    .max(100, "Description must be at most 100 characters.")
    .required("Required."),
  manufacturer: yup.string().required("Required."),
});

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem;
  font-weight: 800;
  font-size: 1rem;
`;
const options = [
  <li className="nav-item">
    <a href="/order" className="nav-link">
      <Link to="/order"> In Progress</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/completedorder" className="nav-link">
      <Link to="/completedorder">Completed</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/shipped" className="nav-link">
      <Link to="/shipped">Shipped</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/canceled" className="nav-link">
      <Link to="/canceled">Canceled</Link>{" "}
    </a>
  </li>,
];

const optionz = [
  <li className="nav-item">
    <a href="/products" className="nav-link">
      <Link to="/products">Recent Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/allproducts" className="nav-link">
      <Link to="/allproducts">All Products</Link>{" "}
    </a>
  </li>,
  <li id="active" className="nav-item">
    <a href="/addproduct" className="nav-link">
      <Link to="/addproduct">Add A Product</Link>{" "}
    </a>
  </li>,
];

function Products() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [primaryImagePreview, setPrimaryImagePreview] = useState();
  const { openSnackbar } = useSnackbar();
  const [product, setProduct] = useState({
    address: "",
    description: "",
    discountprice: "",
    manufacturer: "",
    price: 0,
    pfile: {},
    // primaryfile: {
    // 	fileName: 'string',
    // 	fileType: 'string',
    // 	originalFileName: 'string',
    // 	url: 'string',
    // },
    sfile: [
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
    ],
    // productfiles: [
    // {
    // 	fileName: 'string',
    // 	fileType: 'string',
    // 	originalFileName: 'string',
    // 	url: 'string',
    // },
    // ],
    productname: "",
    sellerid: "",
    sellinginstrument: "",
    tags: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };
  const [secondaryFilesPreview, setSecondaryFilesPreview] = useState([]);
  const { push } = useHistory();
  const [videoModal, setVideoModal] = useState({ state: false, url: "" });

  const [isOn, setIsOn] = useState(true);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };
  const useStyles = makeStyles({
    input: {
      border: "0px",
      height: "26px",
      width: "100%",
      outline: "none",
      marginRight: "4px",
      color: "#898f8f",
      "&:focus": {
        border: "0px",
        outline: "none",
      },
      "&:hover": {
        border: "0px",
        outline: "none",
      },
      "&:active": {
        border: "0px",
        outline: "none",
      },
      // '&::placeholder': {
      // 	color: '#898f8f',
      // 	fontSize: '14px',
      // 	fontFamily: 'Cabin',
      // },
    },
    icon: {
      fontSize: "19px",
      margin: "3px 5px 0px 8px",
      color: "#898f8f",
    },
    container: {
      border:
        // meta.error && meta.touched
        // 	? '1px solid #fb4868'
        // :
        "1px solid #ffffff",
      height: "46px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      marginTop: "2px",
      boxShadow: "1px 0px 5px #cfcfcf",
    },
    iconTextField: {
      //   color: "#898f8f",
      color: "white",
      lineHeight: 1.43,
      fontSize: "18px",
      fontWeight: "bold",
      borderRadius: "6px 0 0 6px",
      letterSpacing: "normal",
      padding: "12px 16px",
      borderRight: "1px solid #9fabc6",
      backgroundColor: "#fb9d1e",
      height: "100%",
      marginRight: "6px",
    },
  });
  const classes = useStyles();
  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [option, setOption] = useState({
    page: 1,
    size: 200,
    sortBy: "firstname",
    sortOrder: "asc",
    search: "",
  });
  const fetchBrands = async () => {
    try {
      const { data } = await api.post("brands/getBrands", option);
      console.log(data);

      setBrands(data.content);
    } catch (error) {
        openSnackbar("error", error.message);
      
    }
  };
  const fetchCategory = async () => {
    try {
      const { data } = await api.post("categories/getCategories", option);
      setCategory(data.body.content);
      // setTotalElem(data.body.totalElements);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.errors.forEach(e => openSnackbar('error', e))
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  useEffect(() => {
    fetchBrands();
    fetchCategory();
  }, []);
  const handleSubmit = async (values) => {
    if (Number(values.discountprice) > Number(values.price)) {
      openSnackbar("error", "Discount price cannot be greater than the original price.");
      return; // Exit the function if the condition is met
  }
    if (Number(values.discountprice) == Number(values.price)) {
      openSnackbar("error", " Discount price cannot be same as Original price");
      return; // Exit the function if the condition is met
  }
    
    console.log(values)
    try {
      if (values.discountprice === "") {
        values.discountprice = values.price;
      }

      const formData = new FormData();
      const { data } = await api.post(
        `product/savewithfiles`,
        toFormData({
          ...values,
          tags: values.tags,
          pfile: values.pfile,
          sfile: values.sfiles,
        })
      );

      openSnackbar("success", "Product is added");
      push("/allproducts");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length !== 0) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
};

  return (
    <div>
      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <SellerSidebar pageName="products" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <SellerTitle />
            </div>
            {/* end box-content */}
            <div className="">
              <Dialog
                open={videoModal.state}
                onClose={() => setVideoModal({ state: false, url: "" })}
                TransitionComponent={Grow}
                // style={{ backgroundColor: 'transparent' }}
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
                fullWidth={true}
                maxWidth={"md"}
              >
                <DialogContent>
                  <ReactPlayer
                    url={videoModal.url}
                    // width="100%"
                    // height="calc(100vh - 300px)"
                    playing
                    controls
                  />
                </DialogContent>
              </Dialog>
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <Formik
                    initialValues={{
                      address: "",
                      description: "",
                      discountprice: "",
                      manufacturer: "",
                      price: 0,
                      pfile: {},
                      // primaryfile: {
                      // 	fileName: 'string',
                      // 	fileType: 'string',
                      // 	originalFileName: 'string',
                      // 	url: 'string',
                      // },
                      sfile: [],
                      // productfiles: [
                      // {
                      // 	fileName: 'string',
                      // 	fileType: 'string',
                      // 	originalFileName: 'string',
                      // 	url: 'string',
                      // },
                      // ],
                      productname: "",
                      sellerid: "",
                      sellinginstrument: "",
                      tags: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={addProdSchema}
                  >
                    {({ values, setFieldValue, errors, ...rest }) => {
                      // const errorsVal = Object.values(errors);
                      // console.log(errors);
                      // if (errorsVal.length > 0) {
                      //   openSnackbar("error", errorsVal[0]);
                      // }

                      return (
                        <Form>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-activeOrders"
                              role="tabpanel"
                              aria-labelledby="nav-activeOrders-tab"
                            >
                              <div className="box-content minColheight">
                                <div
                                  className="row"
                                  id="dashboard-tabs"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <div className="col-md-3">
                                    <div
                                      className="tab-content"
                                      id="nav-tabContent"
                                    >
                                      <div
                                        className="tab-pane fade  show active"
                                        id="nav-account"
                                        role="tabpanel"
                                        aria-labelledby="nav-account-tab"
                                      >
                                        <div className="form-sec full-block mb-4">
                                          {/* <h2>
																						Upload
																						a
																						Primary
																						Photo/Video{' '}
																					</h2> */}
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              style={{
                                                // backgroundColor:
                                                // 	'green',
                                                borderColor: "#e9ecef",
                                                border: "2px solid #e9ecef",
                                                boxShadow:
                                                  "0 0 4px 4px #e9ecef",
                                                margin: "20px 0",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                paddingTop: "48px",
                                                paddingBottom: "48px",
                                                borderRadius: "12px",
                                                alignItems: "center",
                                              }}
                                            >
                                              {values.pfile &&
                                              primaryImagePreview ? (
                                                // values
                                                // 	.primaryfile
                                                // 	.fileType ==
                                                // 		'image'
                                                true ? (
                                                  <div
                                                  // style={{
                                                  // 	position:
                                                  // 		'relative',
                                                  // }}
                                                  >
                                                    <ModalImage
                                                      small={
                                                        primaryImagePreview
                                                      }
                                                      large={
                                                        primaryImagePreview
                                                      }
                                                      alt=""
                                                      style={{
                                                        objectFit: "cover",
                                                        minHeight: "100%",
                                                        minWidth: "100%",
                                                      }}
                                                      hideDownload={true}
                                                      hideZoom={true}
                                                    />
                                                    <CancelIcon
                                                      style={{
                                                        position: "absolute",
                                                        color: "red",
                                                        right: "-10px",
                                                        top: "-10px",
                                                      }}
                                                      onClick={() => {
                                                        setFieldValue(
                                                          "pfile",
                                                          {}
                                                        );
                                                        setPrimaryImagePreview(
                                                          ""
                                                        );
                                                        setProduct({
                                                          ...product,
                                                          pfile: {},
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <img
                                                    src={videoplaceholder1}
                                                    style={{
                                                      objectFit: "cover",
                                                      height: "150px",
                                                      width: "200px",
                                                      // minHeight:
                                                      // 	'100%',
                                                      // minWidth:
                                                      // 	'100%',
                                                    }}
                                                    onClick={() =>
                                                      setVideoModal({
                                                        state: true,
                                                        url: product.pfile.url,
                                                      })
                                                    }
                                                  />
                                                )
                                              ) : (
                                                <img
                                                  src={
                                                    product.pfile &&
                                                    primaryImagePreview
                                                      ? primaryImagePreview
                                                      : uploadImage
                                                  }
                                                  alt=""
                                                  width={150}
                                                />
                                              )}

                                              {/* <CloudUploadIcon
																				style={{
																					color: '#ababab',
																					fontSize:
																						'80px',
																				}}
																			/> */}
                                              <input
                                                accept="image/*,video/*"
                                                style={{
                                                  display: "none",
                                                  outline: "none",
                                                }}
                                                id="outlined-button-file"
                                                // multiple
                                                type="file"
                                                onChange={async (e) => {
                                                  e.preventDefault();
                                                  let file = e.target.files[0];
                                                  e.target.value = "";
                                                  setIsLoading(true);
                                                  setFieldValue("pfile", file);
                                                  setProduct({
                                                    ...product,
                                                    pfile: file,
                                                  });
                                                  setIsLoading(false);
                                                  let reader = new FileReader();
                                                  reader.onloadend = () => {
                                                    setPrimaryImagePreview(
                                                      reader.result
                                                    );
                                                  };
                                                  reader.readAsDataURL(file);
                                                }}
                                              />
                                              <label htmlFor="outlined-button-file">
                                                <Button
                                                  component="span"
                                                  style={{
                                                    float: "right",
                                                    backgroundColor: "#51a470",
                                                    marginBottom: "2px",
                                                    marginTop: "20px",
                                                    color: "white",
                                                  }}
                                                  variant="contained"
                                                  color="primary"
                                                >
                                                  Upload primary photo/video
                                                </Button>
                                              </label>
                                            </div>
                                          </div>
                                          <div
                                            className="row"
                                            style={{
                                              marginTop: "12px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="col-md-12"
                                              style={{
                                                marginBottom: "18px",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  fontSize: "20px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Upload a Secondary Photo/Video{" "}
                                              </Typography>
                                            </div>
                                            {[0, 1, 2, 3, 4, 5].map(
                                              (item, index) => (
                                                <>
                                                  <div
                                                    className="col-md-3"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      borderColor: "#e9ecef",
                                                      border:
                                                        "2px solid #e9ecef",
                                                      boxShadow:
                                                        "0 0 1px 1px #e9ecef",
                                                      padding:
                                                        product.sfile[index] &&
                                                        product.sfile[index].url
                                                          ? 0
                                                          : "15px",
                                                      borderRadius: "6px",
                                                      marginLeft:
                                                        index == 0 || index == 3
                                                          ? 0
                                                          : "4px",
                                                      marginTop: "8px",
                                                      marginBottom: "8px",
                                                    }}
                                                  >
                                                    {product.sfile[index] &&
                                                    product.sfile[index].url ? (
                                                      product.sfile[index]
                                                        .type == "image" ? (
                                                        // <img
                                                        // 	src={
                                                        // 		product
                                                        // 			.productfiles[
                                                        // 			index
                                                        // 		]
                                                        // 			.url
                                                        // 	}
                                                        // 	style={{
                                                        // 		objectFit:
                                                        // 			'cover',
                                                        // 		minHeight:
                                                        // 			'100%',
                                                        // 		minWidth:
                                                        // 			'100%',
                                                        // 	}}
                                                        // />
                                                        <>
                                                          <ModalImage
                                                            small={
                                                              product.sfile[
                                                                index
                                                              ].url
                                                            }
                                                            large={
                                                              product.sfile[
                                                                index
                                                              ].url
                                                            }
                                                            alt=""
                                                            className={
                                                              classes.modalImage
                                                            }
                                                            // style={{
                                                            // 	objectFit:
                                                            // 		'cover',
                                                            // 	minHeight:
                                                            // 		'100%',
                                                            // 	minWidth:
                                                            // 		'100%',
                                                            // }}
                                                            hideDownload={true}
                                                            hideZoom={true}
                                                          />
                                                          <CancelIcon
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              color: "red",
                                                              right: "-10px",
                                                              top: "-10px",
                                                            }}
                                                            onClick={() => {
                                                              setFieldValue(
                                                                "sfile",
                                                                values.sfile.filter(
                                                                  (
                                                                    item,
                                                                    sIndex
                                                                  ) =>
                                                                    index !=
                                                                    sIndex
                                                                )
                                                              );
                                                              const filteredList =
                                                                product.sfile.filter(
                                                                  (
                                                                    item,
                                                                    sIndex
                                                                  ) =>
                                                                    index !=
                                                                    sIndex
                                                                );
                                                              // filteredList.push(
                                                              // 	{
                                                              // 		url: '',
                                                              // 	}
                                                              // );
                                                              setProduct({
                                                                ...product,
                                                                sfile:
                                                                  filteredList,
                                                              });
                                                            }}
                                                          />
                                                        </>
                                                      ) : (
                                                        <>
                                                          <img
                                                            src={
                                                              videoplaceholder
                                                            }
                                                            style={{
                                                              objectFit:
                                                                "cover",
                                                              minHeight: "100%",
                                                              minWidth: "100%",
                                                            }}
                                                            onClick={() =>
                                                              setVideoModal({
                                                                state: true,
                                                                url: product
                                                                  .sfile[index]
                                                                  .url,
                                                              })
                                                            }
                                                          />
                                                          <CancelIcon
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              color: "red",
                                                              right: "-10px",
                                                              top: "-10px",
                                                            }}
                                                            onClick={() => {
                                                              setFieldValue(
                                                                "sfile",
                                                                values.sfile.filter(
                                                                  (
                                                                    item,
                                                                    sIndex
                                                                  ) =>
                                                                    sIndex !=
                                                                    index
                                                                )
                                                              );
                                                              const filteredList =
                                                                product.sfile.filter(
                                                                  (
                                                                    item,
                                                                    sIndex
                                                                  ) =>
                                                                    index !=
                                                                    sIndex
                                                                );
                                                              // filteredList.push(
                                                              // 	{
                                                              // 		url: '',
                                                              // 	}
                                                              // );
                                                              setProduct({
                                                                ...product,
                                                                sfile:
                                                                  filteredList,
                                                              });
                                                            }}
                                                          />
                                                        </>
                                                      )
                                                    ) : (
                                                      <>
                                                        <label htmlFor="outlined-button-file1">
                                                          <AddIcon
                                                            style={{
                                                              color: "#939495",
                                                              fontSize: "42",
                                                            }}
                                                          />
                                                        </label>
                                                        <input
                                                          accept="image/*,video/*"
                                                          style={{
                                                            display: "none",
                                                            outline: "none",
                                                          }}
                                                          id="outlined-button-file1"
                                                          // multiple
                                                          type="file"
                                                          onChange={async (
                                                            e
                                                          ) => {
                                                            e.preventDefault();
                                                            let file =
                                                              e.target.files[0];
                                                            console.log(
                                                              "typeeee",
                                                              file.type
                                                            );
                                                            let fileType =
                                                              file.type;
                                                            e.target.value = "";
                                                            setIsLoading(true);

                                                            setIsLoading(false);
                                                            console.log(
                                                              file,
                                                              "222222",
                                                              values.sfile
                                                            );
                                                            setFieldValue(
                                                              "sfile",
                                                              [
                                                                ...values.sfile,
                                                                file,
                                                              ]
                                                            );
                                                            // setProduct(
                                                            // 	{
                                                            // 		...product,
                                                            // 		sfile: [
                                                            // 			...product.sfile,
                                                            // 			file,
                                                            // 		],
                                                            // 	}
                                                            // );
                                                            let reader =
                                                              new FileReader();
                                                            reader.onloadend =
                                                              () => {
                                                                setProduct({
                                                                  ...product,
                                                                  sfile: [
                                                                    ...product.sfile,
                                                                    {
                                                                      file: file,
                                                                      url: reader.result,
                                                                      type: fileType.substring(
                                                                        0,
                                                                        5
                                                                      ),
                                                                    },
                                                                  ],
                                                                });
                                                              };
                                                            reader.readAsDataURL(
                                                              file
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )}
                                                  </div>
                                                </>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        {/* end full-block */}
                                        <div className="emptyspace50">
                                          {" "}
                                          &nbsp;
                                        </div>
                                        <div className="full-block mt-3">
                                          <button
                                            className="btn btn-style1 btn-green"
                                            type="submit"
                                            disabled={rest.isSubmitting}
                                          >
                                            Save
                                          </button>
                                        </div>
                                        {/* end full-block */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="form-sec full-block">
                                      <Field
                                        as={CustomTextField2}
                                        type="text"
                                        name="productname"
                                        placeholder="Product Name"
                                        m
                                        label={
                                          <span
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Product Name
                                          </span>
                                        }
                                        className="form-control"
                                        className1="row mt-2"
                                        className2="col-md-8"
                                        setFieldValue={setFieldValue}
                                      />
                                      {/* {errors.productname && (
                                        <div className="error-message text-danger">
                                          {errors.productname}
                                        </div>
                                      )} */}
                                      {/* end row */}
                                    </div>
                                    <ErrorMessage
                                      name="productname"
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                    <div className="form-sec full-block mb-3">
                                      <Field
                                        as={CustomTextarea2}
                                        type="text"
                                        name="description"
                                        placeholder="Product Description"
                                        label={
                                          <span
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Product Description
                                          </span>
                                        }
                                        className="form-control"
                                        className1="row mt-2"
                                        className2="col-md-8"
                                        setFieldValue={setFieldValue}
                                        multiline={true}
                                        row={3}
                                      />
                                      {/* {errors.description && (
                                        <div className="error-message text-danger">
                                          {errors.description}
                                        </div>
                                      )} */}
                                      {/* end row */}
                                    </div>
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                    {/* end full-block */}
                                    <div className="form-sec full-block mb-3">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Category
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-8">
                                          <select
                                            className="form-control"
                                            style={{
                                              height: "43px",
                                            }}
                                            onChange={(event) =>
                                              setFieldValue(
                                                "sellinginstrument",
                                                event.target.value
                                              )
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Category
                                            </option>
                                            {category?.map((item) => (
                                              <option
                                                key={item.value}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      {/* {errors.sellinginstrument && (
                                        <div className="error-message text-danger">
                                          {errors.sellinginstrument}
                                        </div>
                                      )} */}
                                      {/* end row */}
                                    </div>
                                    <ErrorMessage
                                      name="sellinginstrument"
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                    <div className="form-sec full-block mb-2">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Manufacturer
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-8">
                                          <select
                                            style={{
                                              height: "43px",
                                            }}
                                            onChange={(event) =>
                                              setFieldValue(
                                                "manufacturer",
                                                event.target.value
                                              )
                                            }
                                            className="form-control"
                                          >
                                            <option value="" disabled selected>
                                              Select Manufacturer
                                            </option>
                                            {brands?.map((item) => (
                                              <option
                                                key={item.value}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                      {/* end row */}
                                    </div>
                                    {/* {errors.manufacturer && (
                                      <div className="error-message text-danger">
                                        {errors.manufacturer}
                                      </div>
                                    )} */}
                                    
                                    <ErrorMessage
                                      name="manufacturer"
                                      component="div"
                                      style={{ color: "red" }}
                                    />
                                    <div className="form-sec full-block mb-4">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Price
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-4">
                                          {/* <div className=""> */}
                                          <div className={classes.container}>
                                            {/* <AttachMoneyIcon className={classes.icon} /> */}
                                            {/* <img src='/images/currency.png' style={{ height: '25px', width: '25px', margin: '2px 5px 2px 5px' }} /> */}
                                            <Typography
                                              className={classes.iconTextField}
                                            >
                                              $
                                            </Typography>
                                            <input
                                              type="number"
                                              name="price"
                                              min="1"
                                              onBlur={(e) => {
                                                // const val = (
                                                //   e.target.value || 0
                                                // ).replace(/\s+/gi, " ");
                                                setFieldValue(
                                                  "price",
                                                  e.target.value
                                                  // val.trim()
                                                );
                                              }}
                                              placeholder="Price"
                                              className={classes.input}
                                              style={{
                                                outline: "none",
                                              }}
                                              // className="form-control"
                                            />
                                          </div>
                                          {/* </div> */}
                                          {/* {errors.price && (
                                            <div className="error-message text-danger">
                                              {errors.price}
                                            </div>
                                          )} */}
                                        </div>
                                       
                                        <div className="col-md-4">
                                          <div className={classes.container}>
                                            {/* <AttachMoneyIcon className={classes.icon} /> */}
                                            {/* <img src='/images/currency.png' style={{ height: '25px', width: '25px', margin: '2px 5px 2px 5px' }} /> */}
                                            <Typography
                                              className={classes.iconTextField}
                                            >
                                              $
                                            </Typography>
                                            <input
                                              type="number"
                                              min="1"
                                              name="discountprice"
                                              onBlur={(e) => {
                                                // const val = (
                                                //   e.target.value || 
                                                // ).replace(/\s+/gi, "");
                                                setFieldValue(
                                                  "discountprice",
                                                  e.target.value
                                                  // val.trim()
                                                );
                                              }}
                                              placeholder="Discount Price"
                                              style={{
                                                outline: "none",
                                              }}
                                              // className="form-control"
                                              className={classes.input}
                                            />
                                          </div>
                                          {/* {errors.discountprice && (
                                            <div className="error-message text-danger">
                                              {errors.discountprice}
                                            </div>
                                          )} */}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                      </div>

                                      {/* end row */}
                                    </div>
                                    <div className="row">
                                        
                                    <div className="col-md-4">

                                    <ErrorMessage
                                          name="price"
                                          component="div"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                      <div className="col-md-4">

                                        <ErrorMessage
                                          name="discountprice"
                                          component="div"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                        </div>
                                    <div className="form-sec full-block mb-4">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Tags
                                      </h3>
                                      <div className="row mt-2">
                                        <div className="col-md-8">
                                          {/* <FormikAutocomplete
                                            value={selectedTags}
                                            options={[
                                              {
                                                title: "Hitachi",
                                                value: "Hitachi",
                                              },
                                              {
                                                title: "PMI",
                                                value: "PMI",
                                              },

                                              {
                                                title: "Buyer",
                                                value: "Buyer",
                                              },

                                              {
                                                title: "Seller",
                                                value: "Seller",
                                              },
                                            ]}
                                            onChange={(event, newValue) => {
                                              console.log(
                                                newValue,
                                                "3232",
                                                selectedTags
                                              );
                                              if (
                                                typeof newValue === "string"
                                              ) {
                                                console.log(111);
                                                // setValue({
                                                //   title: newValue
                                                // });
                                                // setFieldValue(
                                                // 	name,
                                                // 	{
                                                // 		title: newValue,
                                                // 	}
                                                // );
                                                setSelectedTags([
                                                  ...selectedTags,
                                                  {
                                                    title: newValue,
                                                  },
                                                ]);
                                              } else if (
                                                newValue &&
                                                newValue.inputValue
                                              ) {
                                                console.log(22);
                                                // Create a new value from the user input
                                                // setValue({
                                                //   title: newValue.inputValue
                                                // });
                                                // setFieldValue(
                                                // 	name,
                                                // 	{
                                                // 		title: newValue.inputValue,
                                                // 	}
                                                // );
                                                setSelectedTags([
                                                  ...selectedTags,
                                                  {
                                                    title: newValue.inputValue,
                                                  },
                                                ]);
                                              } else {
                                                // setValue(newValue);
                                                // setFieldValue(
                                                // 	name,
                                                // 	newValue
                                                // );
                                                console.log(3333);
                                                setSelectedTags(newValue);
                                              }
                                            }}
                                          /> */}
                                          <CreatableSelect
                                            isMulti
                                            placeholder="Select or Enter tag info"
                                            // value={values.tags.map((tag) => ({
                                            //   label: tag,
                                            //   value: tag,
                                            // }))}
                                            options={[
                                              {
                                                label: "Hitachi",
                                                value: "Hitachi",
                                              },
                                              { label: "PMI", value: "PMI" },
                                              {
                                                label: "Buyer",
                                                value: "Buyer",
                                              },
                                              {
                                                label: "Seller",
                                                value: "Seller",
                                              },
                                            ]}
                                            onChange={(
                                              newValue,
                                              actionMeta
                                            ) => {
                                              console.log(
                                                newValue,
                                                "react select"
                                              );
                                              setFieldValue(
                                                "tags",
                                                newValue.map((tag) => tag.value)
                                              );
                                              // if (actionMeta.action === "create-option") {
                                              //   setFieldValue("tags", [
                                              //     ...values.tags,
                                              //     newValue[actionMeta.option.value],
                                              //   ]);
                                              // } else {
                                              //   setFieldValue(
                                              //     "tags",
                                              //     newValue.map((tag) => tag.value)
                                              //   );
                                              // }
                                            }}
                                            formatCreateLabel={(inputValue) =>
                                              `Create tag: ${inputValue}`
                                            }
                                            onInputChange={(inputValue) =>
                                              console.log(inputValue)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-md-8">
                                          <div className="emptyspace50">
                                            {" "}
                                            &nbsp;
                                          </div>
                                          {/* <div className="full-block mt-3">
																						<button
																							className="btn btn-style1 btn-green"
																							type="submit"
																						>
																							Save
																						</button>
																					</div> */}
                                        </div>
                                      </div>
                                      {/* end row */}
                                    </div>
                                  </div>
                                </div>
                                {/* end row */}
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default Products;
