import React from 'react'
import { Box, Button, Grid, Typography, TextField, Container } from "@mui/material";
import { useForm, Controller } from 'react-hook-form';

import Layout from '../layout/Layout';


const ContactForm = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    }
    return (
        <>
            <Layout pageName="Faq">
                {/* <div className="container text-center my-2 mb-4">
                <h1>Faq with Us</h1>
            </div> */}
                <div className="main">
                    {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Frequently Asked Questions (FAQs)
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                    <Grid container sx={{ my: 5 }}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    background:
                                        "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                    backgroundSize: { xs: "100% 100%", md: "cover" },
                                    minHeight: "50vh",
                                    py: 7,
                                    px: { xs: 2, md: 6 },

                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: { xs: "1.5rem", md: "3rem" },
                                        mt: { xs: 10, md: 12 },
                                    }}
                                >
                                    Contact Us
                                </Typography>

                                <Box>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            backgroundColor: "rgb(248, 158, 35)",
                                            mt: 3,
                                            px: 3,
                                            borderRadius: "15px",
                                            fontSize: { xs: ".7rem", md: "1.3rem" },
                                        }}
                                    >
                                        Join Us
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Container maxWidth="sm" sx={{ boxShadow: 3, borderRadius: 4, p: 3, mb: 5 }}>
                        <Box sx={{ my: 4 }}>
                            <Typography variant='h2' fontWeight="bold">
                                Contact Form
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Name is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Name"
                                                fullWidth
                                                error={!!errors.name}
                                                helperText={errors.name?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Invalid email address',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Email"
                                                fullWidth
                                                error={!!errors.email}
                                                helperText={errors.email?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="message"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Message is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Message"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                error={!!errors.message}
                                                helperText={errors.message?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ my: 3 }}>
                                    <Box sx={{ textAlign: "end" }}>
                                        <Button size='large' type="submit" variant="contained" sx={{
                                            backgroundColor: "rgb(248, 158, 35)", px: 3
                                        }}>
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>

                </div>

            </Layout>
        </>
    )
}

export default ContactForm
