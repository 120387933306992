import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import productImage from "../../assets/images/pro-img.jpg";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Image from "../../assets/images/img-02.jpg";
import CustomLoader from "../../components/CustomLoader";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import api from "../../api";
import { useSnackbar } from "../../lib/context";
import { logout } from "../../utils/utils";
import videoplaceholder from "../../assets/images2/video-placeholder1.jpg";
import ReactPlayer from "react-player";
import videoplaceholder1 from "../../assets/images2/video-placeholder.jpg";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "./../../redux/actions/index";
import { Button } from "@mui/material";
import { set } from "react-hook-form";
import Footer from "../../layout/Footer";

function Watchlist() {
  const myState = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const { openSnackbar } = useSnackbar();
  const [videoModal, setVideoModal] = useState({ state: false, url: "" });
  const { push } = useHistory();
  const [watchlist, setWatchlist] = useState([]);
  const [buttonText, setButtonText] = useState("Add to cart"); // Initial button text

  const [isDialogOpen, setIsDialogOpen] = useState({
    state: false,
    data: "",
  });
  const handleDialogClose = () => {
    setIsDialogOpen({ state: false, data: {} });
  };
  const handleDialogOpen = (data) => {
    setIsDialogOpen({ state: true, data });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const { data } = await api.get("wishlist");
      setWatchlist(data ? data : []);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState();
  const [buttonStates, setButtonStates] = useState({});

  // if (!Array.isArray(watchlist) || watchlist.length === 0) {
  //   return <div>{/* <CustomLoader /> */}</div>;
  // }

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value); // Convert value to number if needed
    setCurrentPage(1); // Reset currentPage when items per page changes
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = watchlist.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const removeWishlist = async (productId) => {
    try {
      const { data } = await api.post(`wishlist/remove?productid=${productId}`);
      openSnackbar("success", "Watchlist removed.");
      // setFeaturedProducts(data);
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  // console.log("store1111111222222", myState.getState);
  // if (!watchlist) {
  //   return <CustomLoader />;
  // }

  const filterProd = currentOrders.filter((itm) =>
    new RegExp(search, "i").test(Object.values(itm).join())
  );
  const handleButtonClick = (item, id) => {
    // myState.find((p) => p.id == item.id);
    // dispatch(addToCart(item));

    if (buttonStates !== id) {
      myState.find((p) => p.id == item.id);
      dispatch(addToCart(item));
      setButtonStates(id === buttonStates ? null : id);
    } else {
      push("/ordersummary");
    }
  };
  return (
    <div>
      <Dialog
        open={videoModal.state}
        onClose={() => setVideoModal({ state: false, url: "" })}
        TransitionComponent={Grow}
        // style={{ backgroundColor: 'transparent' }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          {videoModal.type == "video" ? (
            <ReactPlayer
              url={videoModal.url}
              // width="100%"
              // height="calc(100vh - 300px)"
              playing
              controls
            />
          ) : (
            <img src={videoModal.url} style={{ maxHeight: "70vh" }} />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDialogOpen.state}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDialogClose}
          />
          {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}

          <div className="order-details brdr-none">
            <div id="watchlist-img" className="od-list mt-2 mb-2">
              {/* <img src={Image} alt="" />
							<img src={Image} alt="" />
							<img src={Image} alt="" /> */}
              <div
                className="od-list mt-2 mb-2"
                style={{
                  display: "flex",
                  marginBottom: "32px",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    height: "193px",
                    minWidth: "150px",
                    // backgroundColor: 'blue',
                  }}
                >
                  <img
                    src={
                      isDialogOpen.data.primaryfile &&
                      isDialogOpen.data.primaryfile.url &&
                      isDialogOpen.data.primaryfile.url != null
                        ? isDialogOpen.data.primaryfile.url
                        : Image
                    }
                    alt=""
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "100%",
                      padding: "12px",
                      borderRadius: "8px",
                      border: "1px solid #b1b0b0",
                    }}
                    onClick={() =>
                      setVideoModal({
                        state: true,
                        url: isDialogOpen.data.primaryfile.url,
                        type: "image",
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    minWidth: "250px",
                    height: "200px",
                    marginLeft: "6px",
                    // backgroundColor: 'red',
                  }}
                >
                  {isDialogOpen.state &&
                    isDialogOpen.data &&
                    isDialogOpen.data != null &&
                    isDialogOpen.data.productfiles.map((pro_files) =>
                      pro_files != null &&
                      pro_files.url != null &&
                      pro_files.fileType == "video" ? (
                        <img
                          src={videoplaceholder1}
                          alt=""
                          style={{
                            objectFit: "fill",
                            width: "90px",
                            height: "90px",
                            padding: "6px",
                            borderRadius: "8px",
                            border: "1px solid #b1b0b0",
                            margin: "4px",
                          }}
                          onClick={() =>
                            setVideoModal({
                              state: true,
                              url: pro_files.url,
                              type: "video",
                            })
                          }
                        />
                      ) : (
                        <img
                          src={
                            pro_files.url && pro_files.url != null
                              ? pro_files.url
                              : Image
                          }
                          alt=""
                          style={{
                            objectFit: "fill",
                            width: "90px",
                            height: "90px",
                            padding: "6px",
                            borderRadius: "8px",
                            border: "1px solid #b1b0b0",
                            margin: "4px",
                          }}
                          onClick={() =>
                            setVideoModal({
                              state: true,
                              url: pro_files.url,
                              type: "image",
                            })
                          }
                        />
                      )
                    )}
                </div>
              </div>
            </div>

            <div className="od-list mb-2" style={{ marginTop: "30px" }}>
              <strong>{isDialogOpen.data.productname}</strong>
            </div>
            <div className="od-list mb-2">
              <div className="order-details brdr-none">
                {isDialogOpen.data.description}
              </div>
            </div>
            <div className="od-list">
              {/* <label id="watchlistitem">Catagory:</label>{' '}
							Electronics &amp; Components */}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Brand:</label> {isDialogOpen.data.brand}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Location:</label> {isDialogOpen.data.address}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Price:</label> $
              {/* {isDialogOpen.data.price} */}
              {isDialogOpen.data.discountprice !== ""
                ? isDialogOpen.data.discountprice
                : isDialogOpen.data.price}
            </div>
          </div>
          {/* end  order-details */}

          {/* end  order-details */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </DialogContent>
      </Dialog>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <BuyerSidebar pageName="watchlist" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <BuyerTitle />
          </div>
          {/* end box-content */}

          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div className="nav nav-tabs">
                    <a className="nav-item active">Watchlist</a>
                    <div className="order-right-filter">
                      <div className="search-blk">
                        <input
                          type="text"
                          placeholder="Search all orders"
                          className="form-control"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                        />
                        <input
                          type="submit"
                          defaultValue="Search"
                          className="btn-search"
                        />
                      </div>
                    </div>
                  </div>
                </nav>
                {/* {watchlist.length == 0 ? (
                  <label style={{ margin: "18px 0" }}>No Record Found</label>
                ) : (
                  <div className="payments-content">
                    <div className="paymentsCon-thead">
                      <div id="prod" className="th-col tg-pro">
                        Product
                      </div>
                      <div className="th-col tg-price">Price</div>
                      <div className="th-col tg-button"></div>
                      <div className="th-col tg-action"></div>
                    </div>
                    {currentOrders.map((item) => (
                      <div className="paymentsCon-item-row">
                        <div id="prod" className="td-col tg-pro">
                          <div className="tg-pro-img">
                            {item.primaryfile && item.primaryfile.url ? (
                              <img
                                src={item.primaryfile.url}
                                style={{
                                  width: "60px",
                                }}
                                alt=""
                                className="img-responsive"
                              />
                            ) : (
                              <ImageNotSupportedIcon
                                style={{
                                  fontSize: "50px",
                                  color: "#6e6e6e",
                                }}
                              />
                            )}
                          </div>
                          {item.productname}
                          <button
                            type="button"
                            className="view-more pt-3"
                            data-toggle="modal"
                            data-target="#viewOrderDetail"
                            onClick={() => handleDialogOpen(item)}
                          >
                            View Details
                          </button>
                        </div>
                        <div className="td-col tg-price">
                          {item.discountprice !== ""
                            ? item.discountprice
                            : item.price}
                        </div>
                        <div className="td-col tg-button">
                          <a className="nav-link">
                            <Button
                              style={{
                                color: "white",
                                textTransform: "uppercase",
                                height: "2em",
                                fontSize: "1.3em",
                                float: "right",
                                whiteSpace: "nowrap",
                                backgroundColor: "#51a470",
                                marginBottom: "2px",
                                borderRadius: "1em",
                              }}
                              variant="contained"
                              color="primary"
                              onClick={() => dispatch(addToCart(item))}
                            >
                              Add to cart
                            </Button>
                          </a>
                        </div>
                        <div id="delete" className="td-col tg-action">
                          <DeleteIcon
                            style={{
                              fontSize: "36px",
                              color: "red",
                            }}
                            onClick={() => removeWishlist(item.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )} */}
                {filterProd.length == 0 ? (
                  <label style={{ margin: "18px 0" }}>No Record Found</label>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th className="text-center">Buy</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterProd.map((item, index) => (
                        <tr>
                          <td>
                            <div id="prod" className="td-col tg-pro">
                              <div className="tg-pro-img">
                                {item.primaryfile && item.primaryfile.url ? (
                                  <img
                                    src={item.primaryfile.url}
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                    }}
                                    alt=""
                                    className="img-responsive"
                                  />
                                ) : (
                                  <ImageNotSupportedIcon
                                    style={{
                                      fontSize: "50px",
                                      color: "#6e6e6e",
                                    }}
                                  />
                                )}
                              </div>
                              {item.productname}
                              <button
                                type="button"
                                className="view-more pt-3"
                                data-toggle="modal"
                                data-target="#viewOrderDetail"
                                onClick={() => handleDialogOpen(item)}
                              >
                                View Details
                              </button>
                            </div>
                          </td>
                          <td>
                            $
                            {item.discountprice !== ""
                              ? item.discountprice
                              : item.price}
                          </td>
                          <td>
                            <a className="nav-link text-center">
                              <Button
                                key={item.id}
                                sx={{
                                  color: "white",
                                  textTransform: "uppercase",
                                  // height: "2em",
                                  fontSize: { xs: "8px", md: "1rem" },
                                  // whiteSpace: "nowrap",
                                  backgroundColor: "#51a470",
                                  // marginBottom: "2px",
                                  borderRadius: "1em",
                                }}
                                variant="contained"
                                color="primary"
                                // onClick={() => {
                                //   myState.find((p) => p.id == item.id);
                                //   dispatch(addToCart(item));
                                // }}
                                onClick={() => handleButtonClick(item, index)}
                              >
                                {buttonStates === index
                                  ? "Proceed to Cart"
                                  : "Add to Cart"}
                              </Button>
                            </a>
                          </td>
                          <td>
                            {" "}
                            <DeleteIcon
                              style={{
                                fontSize: "36px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => removeWishlist(item.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <select
                className="form-select mt-2 mx-2"
                style={{ height: "35px" }}
                value={itemsPerPage}
                onChange={(e) => handleItemsPerPageChange(e.target.value)}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="100">100</option>
              </select>

              <nav aria-label="...">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      style={{ color: "black" }}
                    >
                      Previous
                    </span>
                  </li>
                  {Array.from(
                    {
                      length: Math.ceil(watchlist.length / itemsPerPage),
                    },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                        // style={{  }}
                      >
                        <span
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                          style={
                            {
                              // backgroundColor: "#4ba26b",
                              // color: "black",
                            }
                          }
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(watchlist.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <span
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      style={{ color: "black" }}
                    >
                      Next
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default Watchlist;
