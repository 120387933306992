import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function Contact() {
    return (
        <Layout pageName="Contact">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Contact with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>THE XCHANGE MARKETPLACE SUPPORT
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                THE XCHANGE MARKETPLACE SUPPORT
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        THE XCHANGE MARKETPLACE SUPPORT                                            </h2>
                    <p style={{ fontSize: "17px" }}>
                        Welcome to the THE XCHANGE MARIKETPLACE SUPPORT PAGE! We are here to assist you with any questions or issues you may encounter while using our platform. Your satisfaction is our top priority, and we are committed to providing you with the best possible support experience.
                    </p>

                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Contact Information                                      </h3>

                    <p style={{ fontSize: "17px" }}>
                        <strong>Email: </strong>Support@XCHANGEMARKETPLACE.COM
                    </p>
                    <p style={{ fontSize: "17px" }}>
                        <strong>Phone: </strong>+1-844-727-5001 <br />
                        Our dedicated support team is available to help you from Monday to Friday, 9:00 AM to 6:00 PM (UTC-5).

                    </p>


                </div>
                <div className="container my-2">
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Frequently Asked Questions (FAQs)
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Before reaching out to our support team, you may find answers to common questions in our FAQ section. Here are some frequently asked questions:
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Contacting Support                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        If you cannot find the answers you need in our FAQ section or have an issue that requires our assistance, please don't hesitate to reach out to our support team via email or phone. Our support agents are trained to provide prompt and helpful responses to your inquiries.                    </p>

                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Reporting Issues                               </h4>
                    <p style={{ fontSize: "17px" }}>
                        To help us assist you more effectively, please provide the following information when reporting an issue:
                    </p>
                    <ul>
                        <li>
                            Your username and registered email address.
                        </li>
                        <li>
                            A detailed description of the problem or question.
                        </li>
                        <li>
                            Any relevant order or transaction IDs.
                        </li>
                        <li>
                            Screenshots or error messages (if applicable).
                        </li>
                    </ul>
                    <p style={{ fontSize: "17px" }}>
                        We strive to resolve your issues as quickly as possible and appreciate your cooperation in providing the necessary information.
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Feedback
                    </h4>
                    <p style={{ fontSize: "17px" }}>We value your feedback and suggestions for improving THE XCHANGE MARKETPLACE. If you have any ideas or comments on how we can enhance your experience, please feel free to share them with us.</p>
                    <p style={{ fontSize: "17px" }}>Thank you for choosing THE XCHANGE MARKETPLACE. We look forward to serving you and ensuring that your experience is smooth and enjoyable. Your trust and satisfaction are of utmost importance to us.</p>
                </div>
                <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                    Sincerely,
                    The XCHANGE MARKETPLACE SUPPORT TEAM

                </h3>


            </div>

        </Layout>
    );
}

export default Contact;
