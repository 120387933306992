import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function Advertise() {
    return (
        <Layout pageName="Advertise">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Advertise with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Advertise with Us</h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png);",
                                backgroundSize: { xs: "100% 100%" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Advertise with Us
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        WHY ADVERTISE ON THE XCHANGE MARKETPLACE                     </h2>
                    <h2 style={{ fontSize: "20px", fontWeight: "600", marginTop: "20px" }}>
                        THE TOP 10 REASONS TO ADVERTISE ON THE XCHANGE MARKETPLACE                     </h2>
                </div>

                <div className="container my-2">
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Advertising on THE XCHANGE MARKETPLACE website can be a strategic and beneficial  decision for businesses, organizations, and individuals operating in Industrial Science related fields. Here are several compelling reasons why advertising on THE XCHANGE  MARKETPLACE platform can be advantageous:                     </h4>

                    <ul>
                        <li style={{ fontSize: "18px" }}> <strong>Targeted Audience: </strong>Scientific marketplace websites cater to a highly specialized and niche  audience, advertising here ensures that your message reaches a relevant and  engaged audience interested in scientific products or services.
                        </li>
                        <li style={{ fontSize: "18px" }}> <strong>Credibility and Trust: </strong>Scientific marketplaces often have a reputation for hosting reliable and  credible information, products, and services. Associating your brand with  such platforms can enhance your credibility and trustworthiness within the  scientific community.</li>


                        <li style={{ fontSize: "18px" }}> <strong> Access to Decision-Makers: </strong>Many scientific marketplace users are decision-makers within their  organizations, making purchasing decisions for research equipment,  laboratory supplies, software, and more. Advertising here allows you to  directly target those who can influence buying choices. </li>


                        <li style={{ fontSize: "18px" }}> <strong>Product/Service Visibility:</strong>Scientific marketplace websites provide a dedicated space for highlighting your products or services. This visibility can help you stand out in a competitive market and increase brand recognition.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Networking Opportunities:
                            </strong>
                            Advertising on scientific marketplaces can lead to valuable networking  opportunities. You may connect with potential collaborators, partners, or  customers who share your interests and goals.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Access to Specialized Tools:
                            </strong>
                            If your business offers specialized scientific tools or equipment, advertising  in a scientific marketplace allows you to reach a highly receptive audience  actively seeking such products.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Global Reach:
                            </strong>
                            Scientific marketplace websites often have a global user base. This provides  an opportunity to reach an international audience, potentially expanding  your market beyond regional boundaries.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Product Launches and Updates:
                            </strong>
                            Launching new scientific products or updates to existing offerings in a  scientific marketplace can generate excitement and attract early adopters  within the research community.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Enhanced SEO:
                            </strong>
                            Backlinks from reputable scientific marketplace websites can boost your  website's search engine optimization (SEO) and improve your online  visibility.
                        </li>
                        <li style={{ fontSize: "18px" }}>
                            <strong>
                                Community Engagement:
                            </strong>
                            Engaging with the scientific community through advertising on these  platforms allows you to participate in discussions, forums, and knowledge sharing, further establishing your presence.
                        </li>
                    </ul>
                </div>
                <div className="container">
                    <p style={{ fontSize: "17px" }}>In summary, advertising on a scientific marketplace website can provide a range of  benefits, from reaching a highly targeted audience to enhancing your brand's credibility  and expanding your network within the industrial scientific testing community. It is a  strategic choice for businesses and individuals looking to connect with this community and  achieve their marketing and business goals in these specialized fields.
                    </p>
                </div>

            </div>

        </Layout>
    );
}

export default Advertise;
