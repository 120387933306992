import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, changeQuantity, removeFromCart } from "../redux/actions";
import { useHistory } from "react-router-dom";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import api from "../api";
import { useSnackbar } from "../lib/context";
import { items } from "../lib/header-data";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Cart(props) {
  const [quantity, setQuantity] = useState(1);
  const myState = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const { openSnackbar } = useSnackbar();
  const [hideAddToCart, setHideAddToCart] = useState(true);

  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "price",
    sortOrder: "asc",
    search: "",
  });
  const fetchData = async () => {
    try {
      const { data } = await api.post("home/homepage", option);

      setFeaturedProducts(data.content);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const addWishlist = async (productId) => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null
    ) {
      openSnackbar("info", "Please login first to add watchlist");
    } else {
      try {
        const { data } = await api.post(`wishlist/add?productid=${productId}`);
        // setFeaturedProducts(data);
        openSnackbar("success", "Watchlist added");
        fetchData();
      } catch (error) {
        if (
          error.response !== null ||
          error.response.data !== null ||
          error.response.data.errors.length !== 0
        ) {
          error.response.data.errors.forEach((e) => openSnackbar("error", e));
        } else {
          openSnackbar("error", error.message);
        }
      }
    }
  };
  const removeWishlist = async (productId) => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null
    ) {
      openSnackbar("info", "Please login first to add watchlist");
    } else {
      try {
        const { data } = await api.post(
          `wishlist/remove?productid=${productId}`
        );
        openSnackbar("success", "Watchlist removed.");
        // setFeaturedProducts(data);
        fetchData();
      } catch (error) {
        if (
          error.response !== null ||
          error.response.data !== null ||
          error.response.data.errors.length !== 0
        ) {
          error.response.data.errors.forEach((e) => openSnackbar("error", e));
        } else {
          openSnackbar("error", error.message);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null ||
      !localStorage.getItem("data") ||
      localStorage.getItem("data") == null
    ) {
      setRedirectToLogin(true);
    }
  }, []);

  const productQty = () => {
    let total = 0;
    for (let p of myState) {
      total +=
        p.discountprice !== ""
          ? p.discountprice * p.quantity
          : p.price * p.quantity;
    }
    return total;
  };
  return (
    <Layout pageName="Cart">
      <div className="main">
        <div className="container ">
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              className="btn btn-lg"
              onClick={() => push("/xchangeShop")}
              style={{ cursor: "pointer" }}
            >
              <ArrowBackIcon sx={{ fontSize: 30 }} />
            </button>
          </div>
          {!myState || myState == null || myState.length == 0 ? (
            <h5 className="my-5 heading-style1 font-weight-bold">
              No items found in cart
            </h5>
          ) : (
            <div
              className="row my-3 justify-content-sm-between"
              // style={{
              // 	display: 'flex',
              // 	justifyContent: 'space-between',
              // }}
            >
              <div className="col-xl-9 col-lg-8 col-md-6">
                {myState.map((item, i) => (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "24px",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-4">
                            <div className="row">
                              <div className="col-md-1">
                                <input type="checkbox" name="horns" />
                              </div>

                              <div className="col-md-11">
                                <div className="pro-img">
                                  {item.primaryfile && item.primaryfile.url ? (
                                    <img
                                      src={item.primaryfile.url}
                                      alt=""
                                      className="img-responsive"
                                      style={{
                                        maxWidth: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="assets/images/pro-img.jpg"
                                      alt=""
                                      className="img-responsive"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7 col-lg-8 col-md-8  cart-items">
                            <h2>{item.productname}</h2>
                            <p>{item.description}</p>
                            <hr />
                            <p className="mb-1">
                              <strong>Category:</strong>{" "}
                              {item.sellinginstrument}
                            </p>
                            <p className="mb-1">
                              <strong>Brand:</strong> {item.manufacturer}
                            </p>
                            <p>
                              <strong>Seller:</strong> SeqGen
                            </p>
                            <div className="row mt-3 cart-total mt-2">
                              <label className="col-lg-12 col-md-12">
                                $
                                {item.discountprice !== ""
                                  ? item.discountprice
                                  : item.price}{" "}
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    dispatch(removeFromCart(item.id))
                                  }
                                >
                                  Delete
                                </a>
                              </label>
                            </div>
                          </div>
                          {/* <div className="col-xl-2 col-lg-2 col-md-2">
                            <label for="quantitySel" className="form-label">
                              Quantity
                            </label>
                            <select
                              value={item.quantity}
                              className="form-control"
                              onChange={(event) => {
                                setQuantity(parseInt(event.target.value));
                                dispatch(
                                  changeQuantity(
                                    item.id,
                                    parseInt(event.target.value)
                                  )
                                );
                              }}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                            </select>
                          </div> */}
                          <div className="col-xl-2 col-lg-2 col-md-2">
                            <label htmlFor="quantitySel" className="form-label">
                              Quantity
                            </label>
                            <div className="input-group">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  const newQuantity = Math.max(
                                    item.quantity - 1,
                                    0
                                  );
                                  setQuantity(newQuantity);
                                  if (newQuantity === 0) {
                                    // Remove item from cart when quantity becomes 0
                                    dispatch(removeFromCart(item.id));
                                  } else {
                                    dispatch(
                                      changeQuantity(item.id, newQuantity)
                                    );
                                  }
                                }}
                              >
                                -
                              </button>
                              <span className="form-control">
                                {item.quantity}
                              </span>
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                  const newQuantity = item.quantity + 1;
                                  setQuantity(newQuantity);
                                  dispatch(
                                    changeQuantity(item.id, newQuantity)
                                  );
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-5" />
                  </div>
                ))}
              </div>
              <div
                className="col-xl-3 col-lg-4 col-md-6 cart-right"
                // style={{ backgroundColor: 'white' }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "24px",
                  }}
                >
                  <div className="row"></div>
                  <h3>Order Details</h3>
                  {/* <div className="row mt-2">
                    <div className="col-lg-12 col-md-12">
                      <select className="form-control">
                        <option>Preferred Shipping Method</option>
                        <option> Method 1</option>
                        <option> Method 2</option>
                        <option> Method 3</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12">
                      <select className="form-control">
                        <option>Preferred Payment Method</option>
                        <option> Method 1</option>
                        <option> Method 2</option>
                        <option> Method 3</option>
                      </select>
                    </div>
                  </div> */}

                  <div className="row mt-5 cart-total">
                    <label className="col-lg-5 col-md-6">Sub Total</label>
                    <div className="col-lg-7 col-md-6">${productQty()} </div>
                  </div>

                  <div className="row mt-3 cart-total">
                    <label className="col-lg-5 col-md-6">Total</label>
                    <div className="col-lg-7 col-md-6">${productQty()} </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12">
                      <button
                        className="btn btn-block btn-style1 btn-green btn-proceedCheckout"
                        onClick={() => {
                          redirectToLogin
                            ? push("/loginpage")
                            : push(`/ordersummary`);
                        }}
                      >
                        Proceed to Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <h5>Featured Products</h5>
          <div
            style={{
              backgroundColor: "#48a068",
              width: "4em",
              height: ".3em",
              borderRadius: "1em",
            }}
            className="mb-4"
          ></div>
          <div className="row">
            {featuredProducts &&
              featuredProducts
                .filter((item) => item.featured == true)
                .slice(0, 4)
                .map((item) => (
                  <div
                    className="col-md-3 col-6"
                    //   onClick={() => handleDialogOpen(item)}
                  >
                    <div className="pro-block">
                      <div className="pro-block-img">
                        {item.primaryfile && item.primaryfile.url ? (
                          <img src={item.primaryfile.url} alt="" />
                        ) : (
                          <ImageNotSupportedIcon
                            style={{
                              fontSize: "200px",
                              color: "#6e6e6e",
                            }}
                          />
                        )}
                        <div
                          className={
                            item.isStarred
                              ? "icon-wish-list active"
                              : "icon-wish-list"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            item.isStarred
                              ? removeWishlist(item.id)
                              : addWishlist(item.id);
                          }}
                        >
                          <i className="fas fa-heart" />
                        </div>
                        {!hideAddToCart && (
                          <div
                            className={
                              myState.includes(item)
                                ? "icon-add-cart active"
                                : "icon-add-cart"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              myState.includes(item)
                                ? dispatch(removeFromCart(item.id))
                                : dispatch(addToCart(item));
                            }}
                          >
                            <i className="fas fa-shopping-cart" />
                          </div>
                        )}
                      </div>
                      <div className="pro-block-content">
                        <h3>{item.productname}</h3>
                        <div className="pro-price">
                          $
                          {item.discountprice !== ""
                            ? item.discountprice
                            : item.price}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {/* end col */}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Cart;
