import React from 'react'
import Layout from '../../layout/Layout'
import { Grid, Typography } from '@material-ui/core'
// import backgroundImage from '../assets/images/icon-10.jpg';
import backgroundImage from '../../assets/images2/main-bg2.jpg'
import LoginForm from '../../Forms/AdminLoginForm'
import ForgotPasswordForm from '../../Forms/ForgotPasswordForm'
import { useState } from 'react'

function Loginpage (props) {
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  return (
    <Layout pageName=''>
      <div
        style={{
          minHeight: '80vh',
          height: 'fit-content',
          width: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'round'
        }}
      >
        {/* <img
					src={backgroundImage}
					style={{ width: '100%', height: '100%' }}
				/> */}
        <Grid
          container
          spacing={3}
          justifyContent='center'
          alignItems='center'
          style={{ height: '90%', paddingBottom: '12px' }}
        >
          <Grid
            item
            sm={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showForgotPassword ? (
              <ForgotPasswordForm
                setShowForgotPassword={setShowForgotPassword}
              />
            ) : (
              <LoginForm setShowForgotPassword={setShowForgotPassword} />
            )}
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default Loginpage
