import {
	AppBar,
	Toolbar,
	Typography,
	Grid,
	Hidden,
	Container,
} from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Badge } from '@material-ui/core';
import logo from '../assets/images/logo.png';
import cart from '../assets/images/icon-cart-new.png';
import { items } from '../lib/header-data';
import AndroidIcon from '@mui/icons-material/Android';
import AdbIcon from '@mui/icons-material/Adb';
import AppleIcon from '@mui/icons-material/Apple';
import menu from "../assets/images/list.png"
import close from "../assets/images/close.png"

function Header(props) {
	const { showMenu, setShowMenu, pageName } = props;
	const [showMyAccount, setShowMyAccount] = useState(false);
	{ }
	const [accountType, setAccountType] = useState('');
	const isMobileorTablet = useMediaQuery('(max-width:768px)');
	const isMobile = useMediaQuery('(max-width:425px)');
	const { push } = useHistory();
	const location = useLocation();
	const isComingSoonScreen = location.pathname === '/';
	const myState = useSelector((state) => state.cartReducer);

	const useStyles = makeStyles((theme) => ({
		appBar: {
			backgroundColor: 'rgba(255, 255, 255, 0.78)',
			// theme.palette.header.main,
			zIndex: theme.zIndex.drawer + 1,
			height: 'fit-content',
			justifyContent: 'center',
			// padding: '1em 0',
			height: isMobile ? 61 : 122,
			paddingLeft: '6em',
			paddingRight: '6em',
		},
		listItem: {
			// fontFamily: 'Cabin',
			fontSize: '19px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			margin: '0 0 0 2rem',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		imageDiv: {
			margin: 'auto',
			padding: 0,
			align: 'center',
			display: 'inline-block',
			position: 'relative',
			// overflow: 'hidden',
			maxWidth: '240px',
		},
		image: {
			width: '100%',
			height: 'auto',
		},
		customBadge: {
			top: '-10px',
			right: '-25px',
			// marginLeft: '24px',
			// position: 'absolute',
		},
	}));

	const classes = useStyles();
	const [openMenu, setOpenMenu] = useState(false);
	const [hideAddToCart, setHideAddToCart] = useState(false);
	useEffect(() => {
		if (
			localStorage.getItem('token') &&
			localStorage.getItem('token') != null &&
			localStorage.getItem('data') &&
			localStorage.getItem('data') != null
		) {
			setShowMyAccount(true);
			setAccountType(localStorage.getItem('type'));
		} else {
			setShowMyAccount(false);
		}
	}, []);
	useEffect(() => {
		if (
			localStorage.getItem('token') != null &&
			localStorage.getItem('type') == 'seller'
		) {
			setHideAddToCart(true);
		}
	}, []);
	return (
		<AppBar elevation={0} className={classes.appBar} position="absolute">
			<Container maxWidth={false} style={{ maxWidth: '1720px' }}>
				<Grid container justify={'space-between'} alignItems="center">
					<Grid item>
						<div className={classes.imageDiv}>
							<img
								className={classes.image}
								alt="Image"
								src={logo}
							/>
						</div>
					</Grid>
					<Hidden smDown>
						<Grid
							item
							style={{
								display: 'flex',
								// marginRight: isMobileorTablet
								//     ? '0em'
								//     : '2em',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							{/* {items.map((item) => (
								<Typography
									key={item.id}
									className={classes.listItem}
									style={{
										color:
											item.name == pageName
												? // 	||
												  // (window.location.pathname ==
												  // 	'/loginpage/buyer' &&
												  // 	item.name == 'Buyer Hub') ||
												  // (window.location.pathname ==
												  // 	'/loginpage/seller' &&
												  // 	item.name == 'Seller Hub')
												  '#59595a'
												: '#59595a',
										fontWeight:
											item.name == pageName
												? // 	||
												  // (window.location.pathname ==
												  // 	'/loginpage/buyer' &&
												  // 	item.name == 'Buyer Hub') ||
												  // (window.location.pathname ==
												  // 	'/loginpage/seller' &&
												  // 	item.name == 'Seller Hub')
												  '600'
												: 'normal',
										borderBottom:
											item.name == pageName ||
											(window.location.pathname ==
												'/loginpage/buyer' &&
												item.name == 'Buyer Hub') ||
											(window.location.pathname ==
												'/loginpage/seller' &&
												item.name == 'Seller Hub')
												? '3px solid #4ea36a'
												: '0px solid red',
									}}
									onClick={
										() => push(item.link)
										// 	.then(() =>
										// 	window.scrollTo(0, 0)
										// )
									}
								>
									{item.name.toUpperCase()}
								</Typography>
							))} */}
							{items.map((item) => (
								<Typography
									key={item.id}
									className={classes.listItem}
									style={{
										color:
											item.name === pageName
												? '#59595a'
												: '#59595a',
										fontWeight: item.name === pageName ? '600' : 'normal',
										borderBottom:
											item.name === pageName
												? '3px solid #4ea36a'
												: '0px solid red',
										pointerEvents:
											pageName === 'ComingSoon' ? 'none' : 'auto', // Disable links on ComingSoon page
									}}
									onClick={() => push(item.link)}
								>
									{item.name.toUpperCase()}
								</Typography>
							))}


							{/* <div
								style={{
									backgroundColor: '#f89e23',
									padding: '7px 18px',
									borderRadius: '25px',

								}}

								className={classes.listItem}
								onClick={() => {
									if (showMyAccount) {
										accountType == 'buyer'
											? push('/myacc')
											: push('/overview');
									} else {
										push('/loginpage');
									}
								}}
							>
								<Typography
									style={{ color: 'white', fontSize: '16px' }}
								>
									{showMyAccount
										? 'My Account'
										: 'LOGIN / JOIN US'}
								</Typography>
							</div> */}
							<div
								style={{
									backgroundColor: '#f89e23',
									padding: '7px 18px',
									borderRadius: '25px',
									cursor: isComingSoonScreen ? 'default' : 'pointer', // Corrected cursor style
								}}
								className={classes.listItem}
								onClick={() => {
									if (!isComingSoonScreen) {
										if (showMyAccount) {
											if (accountType === 'buyer') {
												push('/myacc');
											} else if (accountType === 'seller') {
												push('/overview');
											} else {
												push('/loginpage');
											}
										} else {
											window.location.href = 'https://thexchangemarketplace.com/join-us-xchange-marketplace';
										}
									}
								}}
							>
								<Typography
									style={{ color: 'white', fontSize: '16px' }}
								>
									{showMyAccount ? 'My Account' : 'LOGIN / JOIN US'}
								</Typography>
							</div>

							{/* <div
								style={{
									backgroundColor: '#489f68',
									padding: '7px 18px',
									borderRadius: '25px',
									display: 'flex',
									alignItems: 'center',
								}}
								className={classes.listItem}
							>
								<Typography
									style={{ color: 'white', fontSize: '16px' }}
								>
									{' '}
									GET THE APP
								</Typography>
								<AppleIcon
									style={{
										color: 'white',
										marginLeft: '3px',
									}}
								/>
								<div
									style={{
										width: '0.5px',
										height: '18px',
										backgroundColor: '#7c7c7c',
										margin: 'auto 3px',
									}}
								/>
								<AdbIcon style={{ color: 'white' }} />
							</div> */}
							{/* <div
								style={{
									backgroundColor: isComingSoonScreen ? '#489f68' : '#489f68', // Change color when disabled
									cursor: isComingSoonScreen ? 'default' : 'default', // Change color when disabled
									padding: '7px 18px',
									borderRadius: '25px',
									display: 'flex',
									alignItems: 'center',
									pointerEvents: isComingSoonScreen ? 'none' : 'auto', // Prevents clicking events
								}}
								className={classes.listItem}
								onClick={() => {
									if (!isComingSoonScreen) {
										// Your navigation logic here
									}
								}}
							>
								<Typography
									style={{ color: 'white', fontSize: '16px' }}
								>
									{' '}
									GET THE APP
								</Typography>
								<AppleIcon
									style={{
										color: 'white',
										marginLeft: '3px',
									}}
								/>
								<div
									style={{
										width: '0.5px',
										height: '18px',
										backgroundColor: '#7c7c7c',
										margin: 'auto 3px',
									}}
								/>
								<AdbIcon style={{ color: 'white' }} />
							</div> */}
							{!hideAddToCart && (
								<IconButton
									aria-label="show new notifications"
									color="inherit"
									onClick={() => {
										if (!isComingSoonScreen) {
											// window.location.pathname = '/cart';
											push('cart');
										}
									}}
									style={{
										pointerEvents: isComingSoonScreen ? 'none' : 'auto', // Prevents clicking events
										cursor: isComingSoonScreen ? 'default' : 'default', // Change color when disabled

									}}
								>
									<Badge
										badgeContent={
											myState ? myState.length : 0
										}
										color="secondary"
										classes={{
											badge: classes.customBadge,
										}}
										style={{
											position: 'absolute',
											top: 0,
											// right:10,
											marginLeft: '24px',
										}}
									>
										{/* <i
            className="fas fa-shopping-cart"
            style={{ fontSize: '0.8em' }}
        /> */}
										<img
											src={cart}
											style={{
												// marginLeft: '12px',
												fontSize: '20px',
												position: 'absolute',
												top: '-8px',
											}}
										/>
									</Badge>
								</IconButton>
							)}
							{/* {!hideAddToCart && (
								<IconButton
									aria-label="show new notifications"
									color="inherit"
									onClick={() => {
										if (!isComingSoonScreen) {
											// window.location.pathname = '/cart';
											push('cart');
										}
									}}
									style={{
										pointerEvents: isComingSoonScreen ? 'none' : 'auto', // Prevents clicking events
									}}
								>
									<Badge
										badgeContent={
											myState ? myState.length : 0
										}
										color="secondary"
										classes={{
											badge: classes.customBadge,
										}}
										style={{
											position: 'absolute',
											top: 0,
											// right:10,
											marginLeft: '24px',
										}}
									>
										<i
            className="fas fa-shopping-cart"
            style={{ fontSize: '0.8em' }}
        />
										<img
											src={cart}
											style={{
												// marginLeft: '12px',
												fontSize: '20px',
												position: 'absolute',
												top: '-8px',
											}}
										/>
									</Badge>
								</IconButton>
							)} */}
						</Grid>
					</Hidden>
					<Hidden mdUp>
						<Grid item style={{ display: 'flex' }}>
							{showMenu ? (
								<img
									onClick={() => setShowMenu(false)}
									src={close}
									style={{
										height: '20px',
									}}
								/>
							) : (
								<img
									onClick={() => setShowMenu(true)}
									src={menu}
									style={{
										height: '28px',
									}}
								/>
							)}
						</Grid>
					</Hidden>
				</Grid>
			</Container>
		</AppBar>
	);
}

export default Header;
