import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import productImage from "../../assets/images/pro-img.jpg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Checkbox,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import api from "../../api";
import { useSnackbar } from "../../lib/context";
import { toast } from "react-toastify";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";
import Image from "../../assets/images/img-02.jpg";
import CustomLoader from "../../components/CustomLoader";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { logout } from "../../utils/utils";
import AdminSidebar from "../Sidebar/AdminSidebar";
import AdminTitle from "../PageTitle/AdminTitle";
import editImg from "../../assets/images/icon-edit.png";
import deleteImg from "../../assets/images/icon-delete2.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import CustomTextField2 from "../../components/CustomTextField2";
import CustomTextarea from "../../components/CustomTextarea";
import FormikSelect from "../../components/FormikSelect";
// import FormikDatePicker from '../../components/FormikDatePicker'
import { formatDate3 } from "../../utils/utils";
import dayjs from "dayjs";
import { Switch } from "@mui/material";
import Footer from "../../layout/Footer";
import * as Yup from "yup";

const DropDownListContainer = styled("div");

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem
  font-weight: 800;
  font-size: 1rem;
`;

const options = [
  <li className="nav-item">
    <a href="/order" className="nav-link">
      <Link to="/order"> In Progress</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/completedorder" className="nav-link">
      <Link to="/completedorder">Completed</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/shipped" className="nav-link">
      <Link to="/shipped">Shipped</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/canceled" className="nav-link">
      <Link to="/canceled">Canceled</Link>{" "}
    </a>
  </li>,
];

const optionz = [
  <li id="active" className="nav-item">
    <a href="/products" className="nav-link">
      <Link to="/products">Recent Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/allproducts" className="nav-link">
      <Link to="/allproducts">All Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="addproduct" className="nav-link">
      <Link to="addproduct">Add A Product</Link>{" "}
    </a>
  </li>,
];

function SellerPromotions() {
  const { push } = useHistory();
  const [deleteId, setDeleteId] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState({ open: false });
  const [tabVal, setTabVal] = useState(2);
  const handleDialogClose = () => {
    setIsDialogOpen({ open: false });
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDeleteDialogOpen = (id) => {
    setDeleteId(id);
    setIsDeleteDialogOpen({ open: true });
  };
  const handleDeleteDialogClose = () => {
    setDeleteId("");
    setIsDeleteDialogOpen();
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(true);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const fetchInfo = async () => {
    try {
      const { data } = await api.get("/home/lookups");
      setProducts(data);
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.message.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const [search, setSearch] = useState();

  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "sellername",
    sortOrder: "asc",
    search: "",
    status: "false",
  });
  const [totalElem, setTotalElem] = useState();
  const fetchData = async () => {
    try {
      const { data } = await api.post("promotions/getPromotions", option);
      // const filterData = data.content();
      data.content.sort((a, b) => {
        if (dayjs(a.startdate).isAfter(dayjs(b.startdate))) return 1;
        else if (dayjs(a.startdate).isBefore(dayjs(b.startdate))) return -1;
        else return 0;
      });
      setPromotions(data.content);
      setTotalElem(data.totalElements);
    } catch (error) {
      if (error.response.data.message) {
        setPromotions([]);
      } else if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.content.message.forEach((e) =>
        //   openSnackbar("error", e)
        // );
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  const handleDelete = async (productId) => {
    try {
      const { data } = await api.put(
        `/promotions/deletePromotion?id=${deleteId}`
      );
      openSnackbar("success", "Promotion is deleted.");
      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      console.log("values", values);
      const seller = products.seller.find((item) => item.id == values.sellerId);

      const sellerName = seller
        ? `${seller.firstname} ${seller.lastname}`
        : null;
      const companyName = seller ? `${seller.company}` : null;

      if (values.type === "Add") {
        const { data } = await api.post(`promotions`, {
          ...values,
          sellername: sellerName,
          companyName: companyName,
          status: "false",
        });

        openSnackbar("success", "New Promotion Added Successfully");
        fetchData();
        handleDialogClose();
      } else {
        const { data } = await api.post(`/promotions/updatePromotion`, {
          ...values,
          sellername: sellerName,
          companyName: companyName,
        });
        openSnackbar("success", "Promotion Edited Successfully");
        fetchData();

        handleDialogClose();
      }
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  useEffect(() => {
    fetchInfo();
    fetchData();
  }, [option]);

  if (!products) {
    return <CustomLoader />;
  }
  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };
  return (
    <div>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDeleteDialogClose}
          />
          <div>
            <Typography>
              Are you sure you want to delete this promotion?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
              <Button onClick={() => handleDelete()}>Confirm</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDialogOpen.open}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogTitle>
          {isDialogOpen.type == "Add" ? "Add" : "Edit"} Promotions
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              sellerId: "",
              sellername: "",
              companyName: "",
              // startdate: formatDate3(new Date()),
              // enddate: formatDate3(new Date()),
              startdate: "",
              enddate: "",
              ...isDialogOpen,
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              sellerId: Yup.string().required("Seller is required"),
              startdate: Yup.date().required("Start Date is required"),
              enddate: Yup.date()
                .required("End Date is required")
                .min(
                  Yup.ref("startdate"),
                  "End Date must be later than or equal to Start Date"
                ),
            })}
          >
            {({ values, setFieldValue }) => {
              console.log(values, "dil");
              return (
                <Form>
                  {console.log("Promo ", values)}
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                      <Field
                        name='messagetext'
                        label='Type Message'
                        // className="form-control style2"
                        placeholder='Type Message'
                        // rows={3}
                        as={CustomTextField2}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label={"Seller"}
                        variant="standard"
                        // name="callCenterIds"
                        size="small"
                        options={products.seller.map((item) => {
                          return {
                            value: item.id,
                            label: item.firstname + " " + item.lastname,
                          };
                        })}
                        textField="label"
                        valueField="value"
                        value={values.sellerId}
                        onChange={(e) => {
                          setFieldValue("sellerId", e.target.value);
                          // setFieldValue('sellername', e.target.label)
                        }}
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <ErrorMessage
                      name="sellerId"
                      component="div"
                      style={{ color: "red" }}
                    />
                    <Grid item xs={12}>
                      <Field
                        component={FormikSelect}
                        label={"Company Name"}
                        variant="standard"
                        // name="callCenterIds"
                        size="small"
                        options={products.seller.map((item) => {
                          return {
                            value: item.id,
                            label: item.company,
                          };
                        })}
                        textField="label"
                        valueField="value"
                        value={values.sellerId}
                        onChange={(e) => {
                          setFieldValue("companyName", e.target.value);
                          // setFieldValue('sellername', e.target.label)
                        }}
                        // inputProps={{ readOnly: view }}
                      />
                    </Grid>
                    <ErrorMessage
                      name="company"
                      component="div"
                      style={{ color: "red" }}
                    />
                    {/* <Grid item xs={12}>
                      <TextField
                        // id='date'
                        label="Start Date"
                        type="date"
                        fullWidth
                        // defaultValue={values.startdate}
                        // '2017-05-24'
                        // className={classes.textField}
                        value={dayjs(values.startdate).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setFieldValue(
                            "startdate",
                            formatDate3(new Date(e.target.value))
                          );
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={
                            values.startdate ? dayjs(values.startdate) : null
                          }
                          onChange={(date) => {
                            if (date && dayjs(date).isValid()) {
                              const formattedDate =
                                dayjs(date).format("MM-DD-YYYY");
                              setFieldValue("startdate", formattedDate);
                            } else {
                              // Handle invalid date or clear the field if needed
                              setFieldValue("startdate", null);
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="MM-DD-YYYY"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <ErrorMessage
                      name="startdate"
                      component="div"
                      style={{ color: "red" }}
                    />

                    {/* <Grid item xs={12}>
                      <TextField
                        // min={values.startdate}
                        // id='date'
                        label="End Date"
                        type="date"
                        fullWidth
                        // defaultValue={values.enddate}
                        // className={classes.textField}
                        onChange={(e) => {
                          console.log("E ", e.target.value);
                          setFieldValue(
                            "enddate",
                            // formatDate3(new Date(e.target.value))
                            dayjs(e.target.value).format("MM-DD-YYYY")
                          );
                        }}
                        value={dayjs(values.enddate).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={values.enddate ? dayjs(values.enddate) : null}
                          onChange={(date) => {
                            if (date && dayjs(date).isValid()) {
                              const formattedDate =
                                dayjs(date).format("MM-DD-YYYY");
                              setFieldValue("enddate", formattedDate);
                            } else {
                              // Handle invalid date or clear the field if needed
                              setFieldValue("enddate", null);
                            }
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="MM-DD-YYYY"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <ErrorMessage
                      name="enddate"
                      component="div"
                      style={{ color: "red" }}
                    />

                    <Grid item xs={12}>
                      <Button
                        style={{
                          float: "right",
                          backgroundColor: "#51a470",
                          marginBottom: 10,
                          color: "white",
                        }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <AdminSidebar pageName="admin-seller-promotion" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <AdminTitle />
          </div>
          {/* end box-content */}
          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div
                    className="nav nav-tabs"
                    id="nav-tab"
                    role="tablist"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <a
                        className={`nav-item ${tabVal == 1 ? "active" : ""}`}
                        id="nav-account-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="true"
                        onClick={() => {
                          setTabVal(1);
                        }}
                        style={{ fontSize: "17px" }}
                      >
                        <Link to="/admin-seller-promotion">
                          Active SELLER PROMOTIONS
                        </Link>
                      </a>
                      <a
                        className={`nav-item ${tabVal == 2 ? "active" : ""}`}
                        id="nav-account-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="true"
                        // href="/admin-upcoming-seller-promotion"
                        onClick={() => {
                          setTabVal(2);
                        }}
                        style={{ fontSize: "17px" }}
                      >
                        <Link to="/admin-upcoming-seller-promotion">
                          Upcoming SELLER PROMOTIONS
                        </Link>
                      </a>
                      {/* <a
                        className="nav-item"
                        id="nav-account-tab"
                        data-toggle="tab"
                        href="#nav-account"
                        role="tab"
                        aria-controls="nav-account"
                        aria-selected="true"
                      >
                        <Link to="/allproducts">ALL PRODUCTS</Link>
                      </a> */}
                    </div>
                    <div style={{ flexGrow: 1 }}></div>

                    <div
                      className="search-blk"
                      style={{ width: "300px", marginRight: "10px" }}
                    >
                      <input
                        type="text"
                        placeholder="Search all Upcoming Promotion"
                        className="form-control"
                        value={search}
                        onChange={(e) => {
                          // setSearch(e.target.value);
                          setOption({ ...option, search: e.target.value });
                        }}
                      />
                      <button
                        type="submit"
                        defaultValue="Search"
                        className="btn-search"
                        onClick={() => setOption({ ...option, search })}
                      >
                        Search
                      </button>
                    </div>
                    {/* <a
											id="addprod"
											href="/addproduct"
											className="nav-item"
										> */}
                    <Button
                      style={{
                        float: "right",
                        marginBottom: 10,
                        color: "white",
                        backgroundColor: "#51a470",
                      }}
                      variant="contained"
                      color="primary"
                      // href="/addproduct"
                      onClick={() =>
                        setIsDialogOpen({ open: true, type: "Add" })
                      }
                    >
                      Add Promotion
                    </Button>
                    {/* </a> */}
                  </div>
                </nav>
              </div>
              {/* <div className="col-md-3 text-right">
										<label>&nbsp;</label>
										<button className="btn btn-style1 btn-green btn-save">
											Add A Product
										</button>
									</div> */}
              <div
                className="tab-content"
                id="nav-tabContent"
                style={{
                  width: "100%",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }}
              >
                <div
                  className="tab-pane fade show active"
                  id="nav-activeOrders"
                  role="tabpanel"
                  aria-labelledby="nav-activeOrders-tab"
                >
                  <div className="payments-content">
                    {promotions.length == 0 ? (
                      <label>No Record Found</label>
                    ) : (
                      <div className="paymentsCon-thead">
                        {/* <div id="prod" className="th-col tg-pro">
                          Product
                        </div> */}
                        {/* <div className="th-col tg-email ml-3">Seller Name</div>{" "} */}
                        <div
                          className="th-col tg-email ml-3"
                          onClick={() => handleSort("sellername")}
                        >
                          Seller Name{" "}
                          <span>
                            {option.sortBy === "sellername" &&
                            option.sortOrder === "asc"
                              ? "↑"
                              : "↓"}
                          </span>
                        </div>{" "}
                        {/* <div className="th-col tg-email ">Company Name</div>{" "} */}
                        <div
                          className="th-col tg-email"
                          onClick={() => handleSort("companyName")}
                        >
                          Company Name{" "}
                          <span>
                            {option.sortBy === "companyName" &&
                            option.sortOrder === "asc"
                              ? "↑"
                              : "↓"}
                          </span>
                        </div>{" "}
                        <div className="th-col tg-email ">Start Date</div>
                        <div className="th-col tg-email">End Date</div>
                        <div className="th-col tg-email ">Status</div>
                        {/* <div className="th-col tg-action"></div> */}
                        <div className="th-col tg-action"></div>
                      </div>
                    )}
                    {promotions.map((item) => (
                      <div
                        // onClick={handleDialogOpen}
                        className="paymentsCon-item-row"
                      >
                        {/* <div id="prod" className="td-col tg-pro">
                          <div className="tg-pro-img">
                            {item.primaryfile && item.primaryfile.url ? (
                              <img
                                src={item.primaryfile.url}
                                style={{
                                  width: "60px",
                                }}
                                alt=""
                                className="img-responsive"
                              />
                            ) : (
                              <ImageNotSupportedIcon
                                style={{
                                  fontSize: "50px",
                                  color: "#6e6e6e",
                                }}
                              />
                            )}
                          </div>
                          {item.itemname + " " + item.description}
                        </div> */}
                        <div className="td-col tg-email ml-3  ">
                          {item.sellername}
                        </div>
                        <div className="td-col tg-email">
                          {item.companyName}
                        </div>
                        <div
                          className="td-col tg-email "
                          style={{ textAlign: "start" }}
                        >
                          {item.startdate}
                        </div>
                        <div
                          className="td-col tg-email "
                          style={{ textAlign: "start" }}
                        >
                          {item.enddate}
                        </div>
                        <div className="td-col tg-email">
                          <Switch
                            size="medium"
                            color="primary"
                            checked={item.status === "true"}
                            onChange={(e) =>
                              handleSubmit({
                                ...item,
                                status: e.target.checked ? "true" : "false",
                              })
                            }
                          />{" "}
                        </div>
                        {/* <div className="td-col tg-email">{item.status}</div> */}
                        {/* <div
                          id="edit"
                          className="td-col tg-action"
                          onClick={() => {
                            push(`editproduct/${product.id}`);
                          }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "36px",
                              color: "black",
                            }}
                          />
                        </div> */}

                        <div id="delete" className="td-col tg-action">
                          <IconButton
                            className="mr-4"
                            onClick={() => {
                              setIsDialogOpen({
                                open: true,
                                type: "Edit",
                                ...item,
                              });
                            }}
                          >
                            <img src={editImg} alt="" width="25"></img>
                          </IconButton>
                          <IconButton
                            // className="mr-3"
                            onClick={() => {
                              // handleDelete(product.id)
                              handleDeleteDialogOpen(item.id);
                            }}
                          >
                            <img src={deleteImg} alt="" width="25"></img>
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <div>
                <select
                  className="form-select mt-2 mx-2"
                  value={option.size}
                  onChange={(e) =>
                    setOption({ ...option, size: e.target.value })
                  }
                >
                  {/* <option selected>{option.size}</option> */}
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="100">100</option>
                </select>
              </div>

              <nav aria-label="...">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      option.page === 1 ? "disabled" : ""
                    }`}
                  >
                    <span
                      className="page-link"
                      onClick={() =>
                        setOption({
                          ...option,
                          page: option.page - 1,
                        })
                      }
                      style={{ color: "black" }}
                    >
                      Previous
                    </span>
                  </li>
                  {Array.from(
                    { length: Math.ceil(totalElem / option.size) },
                    (_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          option.page === index + 1 ? "active" : ""
                        }`}
                      >
                        <span
                          className="page-link"
                          onClick={() =>
                            setOption({
                              ...option,
                              page: index + 1,
                            })
                          }
                          style={
                            {
                              // backgroundColor: "#4ba26b",
                              // color: "black",
                            }
                          }
                        >
                          {index + 1}
                        </span>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      option.page === Math.ceil(totalElem / option.size)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <span
                      className="page-link"
                      onClick={() =>
                        setOption({
                          ...option,
                          page: option.page + 1,
                        })
                      }
                      style={{ color: "black" }}
                    >
                      Next
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* Optional JavaScript */}
          {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SellerPromotions;
