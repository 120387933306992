import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function HowToSell() {
    return (
        <Layout pageName="HowToSell">
            {/* <div className="container text-center my-2 mb-4">
                <h1>HowToSell with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>
                            HOW TO SELL ON THE XCHANGE MARKETPLACE                         </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.1rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                HOW TO SELL ON THE XCHANGE MARKETPLACE
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        HOW TO SELL ON THE XCHANGE MARKETPLACE                     </h2>
                    <p style={{ fontSize: "17px" }}>
                        Selling on a marketplace website can be an effective way to promote your company to a targeted audience. Here are steps to help you sell on a marketplace website:                     </p>
                </div>

                <div className="container my-2">

                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Review Marketplace Policies:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Familiarize yourself with THE XCHANGE MARKETPLACE’S rules, policies, and guidelines for sellers. Selling on a marketplace website can be an effective way to promote your products or services to a targeted audience. Here are steps to help you sell on a marketplace website:
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Create an Account:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Contact THE XCHANGE MARKETPLACE to Sign up to sell
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        List Your Featured Products or Services:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Create listings for your Featured Products or Service. Provide high-quality images and/or videos.                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Optimize Your Listings:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Use SEO techniques to optimize your listings for search engines within the XCHANGE MARKETPLACE                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Monitor Performance Metrics:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Keep track of your performance metrics and use analytics tools provided by THE XCHANGE MARKETPLACE. This data can help you make informed decisions to improve your selling strategy
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Evaluate and Adjust:
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        Regularly evaluate your selling efforts and adjust your strategy based on what works best for your business.
                        Remember that success on a marketplace website often requires patience and ongoing effort. Building a compelling reputation and brand presence takes time, so be persistent in
                        your efforts to sell effectively and provide a positive customer experience.

                    </p>

                </div>

            </div>

        </Layout>
    );
}

export default HowToSell;
