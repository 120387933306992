import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
// import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logout } from "../../utils/utils";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Grid,
} from "@material-ui/core";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import CustomLoader from "../../components/CustomLoader";
import { useSnackbar } from "../../lib/context";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import api from "../../api";
import CustomTextarea from "../../components/CustomTextarea";
import { formatDate2, formatTime } from "../../utils/utils";
import { Button, styled } from "@mui/material";
import Footer from "../../layout/Footer";

const Dot = styled("div")({
  height: "13px",
  width: "13px",
  borderRadius: "50%",
  backgroundColor: "red",
});
function Msg() {
  const { push } = useHistory();
  const [messages, setMessages] = useState();
  const [messageColumn, setMessageColumn] = useState();
  const { openSnackbar } = useSnackbar();
  const [isMessageColumnOpen, setIsMessageColumnOpen] = useState(true);

  const handleCloseButtonClick = () => {
    setIsMessageColumnOpen(false);
  };
  const fetchData = async () => {
    try {
      const { data } = await api.get(`/message/getAll`);
      //   ?user=${JSON.parse(localStorage.getItem("data")).id}
      setMessages(data);
      if (!messageColumn) {
        data.length
          ? setMessageColumn({
              message: data[0].messageContentObject,
              orderid: data[0].orderid,
              buyerId: data[0].orderData.buyerAccountData.id,
              sellerId: data[0].orderData.sellerAccountData.id,
            })
          : setMessageColumn({
              message: "",
              orderid: "",
              buyerId: "",
              sellerId: "",
            });
      } else {
        let oid = data.find((item) => item.orderid == messageColumn.orderid);
        setMessageColumn({
          message: oid.messageContentObject,
          orderid: oid.orderid,
          buyerId: oid.orderData.buyerAccountData.id,
          sellerId: oid.orderData.sellerAccountData.id,
        });
      }
    } catch (error) {
      // if (
      //   error.response !== null ||
      //   error.response.data !== null ||
      //   error.response.data.errors.length !== 0
      // ) {
      //   error.response.data.errors.forEach((e) => openSnackbar("error", e));
      // } else {
      //   openSnackbar("error", error.message);
      // }
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const newVal = { ...values, notify: true };
      const { data } = await api.post("message", newVal);
      resetForm();
      fetchData();
      openSnackbar("success", "Message Sent Successfully");
      // setOrders(data.filter((d) => d.orderstatus == 'New Order'));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const handleUpdateNotification = async (id) => {
    try {
      const { data } = await api.put(
        `/message/closeNotification?id=${id}&status=false`
      );
      // fetchData();
      // openSnackbar("success", "Message Sent Successfully");
      // setOrders(data.filter((d) => d.orderstatus == 'New Order'));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (!messages || !messageColumn) {
    return <CustomLoader />;
  }
  // const userId = JSON.parse(localStorage.getItem("data")).id;

  for (let message of messages) {
    for (let item of message.messageContentObject) {
      if (item.usertype === "seller" && item.notify) {
        message.notify = true;
        break;
      }
    }
  }
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <BuyerSidebar pageName="messages" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <BuyerTitle />
          </div>
          {/* end box-content */}
          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item active"
                      id="nav-activeOrders-tab"
                      data-toggle="tab"
                      href="#nav-activeOrders"
                      role="tab"
                      aria-controls="nav-activeOrders"
                      aria-selected="true"
                    >
                      Messages
                    </a>
                    {/* <div className="order-right-filter">
                      <div className="search-blk">
                        <input
                          type="text"
                          placeholder="Search all orders"
                          className="form-control"
                        />
                        <input
                          type="submit"
                          defaultValue="Search"
                          className="btn-search"
                        />
                      </div>
                    </div> */}
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-activeOrders"
                    role="tabpanel"
                    aria-labelledby="nav-activeOrders-tab"
                  >
                    <div className="form-sec" id="messages-sec">
                      {messages.map((item) => (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setMessageColumn({
                              message: item.messageContentObject,
                              orderid: item.orderid,
                              buyerId: item.orderData.buyerAccountData.id,
                              sellerId: item.orderData.sellerAccountData.id,
                            });
                            setIsMessageColumnOpen(true); // Set isMessageColumnOpen to true
                          }}
                        >
                          <div
                            className="row order-details align-items-center"
                            onClick={() =>
                              handleUpdateNotification(item.orderid)
                            }
                          >
                            <div className="col-md-10">
                              <h3>
                                {item.orderData.sellerAccountData.firstname +
                                  " " +
                                  item.orderData.sellerAccountData.lastname}
                              </h3>
                              <p>
                                <div>
                                  {item.orderData.product[0].productname}
                                </div>
                                ${item.orderData.product[0].price}
                              </p>
                            </div>
                            <div className="col-md-2">
                              {item.notify && (
                                <Dot sx={{ ml: "auto", mr: 2 }} />
                              )}
                            </div>
                          </div>
                        </a>
                      ))}
                      {/* end row */}
                    </div>
                    {isMessageColumnOpen &&
                      messageColumn.message.length > 0 && (
                        <div
                          className="messages-sec-right"
                          style={{ backgroundColor: "#f6f6f6" }}
                        >
                          <div className="msgWraper">
                            <>
                              <div
                                className="mb-4"
                                style={{ textAlign: "end" }}
                              >
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={handleCloseButtonClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  Close
                                </button>
                              </div>
                              {messageColumn.message &&
                                messageColumn.message.map((item) => (
                                  // <div
                                  //   style={{
                                  //     textAlign:
                                  //       item.usertype == "buyer" ? "right" : "",
                                  //     width: "100%",
                                  //   }}
                                  // >
                                  //   <div style={{}}>
                                  //     <div className="date">
                                  //       {formatDate2(
                                  //         new Date(item.messagedate)
                                  //       )}
                                  //     </div>
                                  //     <div className="message">
                                  //       {item.messagetext}
                                  //     </div>
                                  //     <div className="time">
                                  //       {formatTime(new Date(item.messagedate))}
                                  //     </div>
                                  //   </div>
                                  // </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent:
                                        item.usertype === "buyer"
                                          ? "right"
                                          : "left",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          item.usertype === "buyer"
                                            ? "#DCF8C6"
                                            : "#E2E2E2",
                                        borderRadius: "10px",
                                        padding: "10px",
                                        width:
                                          item.usertype === "buyer"
                                            ? "50%"
                                            : "50%",
                                        margin: "10px 0",
                                        marginLeft:
                                          item.usertype === "buyer"
                                            ? "0"
                                            : "1%",
                                        marginRight:
                                          item.usertype === "buyer"
                                            ? "1%"
                                            : "0",
                                      }}
                                    >
                                      <div
                                        className="message"
                                        style={{
                                          fontSize: "18px",
                                          wordWrap: "break-word",
                                          textAlign:
                                            item.usertype === "buyer"
                                              ? "right"
                                              : "start",
                                        }}
                                      >
                                        {item.messagetext}
                                      </div>
                                      <div
                                        className="date"
                                        style={{
                                          textAlign:
                                            item.usertype === "buyer"
                                              ? "right"
                                              : "right",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formatDate2(
                                          new Date(item.messagedate)
                                        )}
                                        {"   "}
                                        {formatTime(new Date(item.messagedate))}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          </div>
                          <div className="full-block mt-3 btn-reply">
                            <Formik
                              enableReinitialize
                              initialValues={{
                                orderid: messageColumn.orderid,
                                buyerId: messageColumn.buyerId,
                                sellerId: messageColumn.sellerId,
                                messagetext: "",
                                usertype: "buyer",
                              }}
                              validationSchema={yup.object().shape({
                                messagetext: yup.string().required(""),
                              })}
                              onSubmit={handleSubmit}
                            >
                              {({ values, setFieldValue }) => {
                                return (
                                  <Form>
                                    <Grid container>
                                      <Grid item xs={8} md={9}>
                                        <Field
                                          name="messagetext"
                                          as={CustomTextarea}
                                          // label="Type Message"
                                          // className="form-control style2"
                                          placeholder="Type Message"
                                          // className="form-control style2"
                                          rows={2}
                                          className="mx-4 mb-4"
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={3}>
                                        <Button
                                          // className="btn btn-style1 btn-green mt-2 pt-3"
                                          sx={{
                                            px: { xs: 1, md: 6 },
                                            mr: { xs: 7, md: 2 },
                                            fontSize: {
                                              xs: "10px",
                                              md: "1.5rem",
                                            },
                                            mt: 2,
                                            borderRadius: "20px",
                                            backgroundColor: "#48a068",
                                          }}
                                          variant="contained"
                                          size="small"
                                          type="submit"
                                        >
                                          Reply
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Form>
                                );
                              }}
                            </Formik>
                            {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <div className="full-block mt-3 btn-reply">
                        <div className="row">
                          <div className="col-md-8">
                            <textarea
                              className="form-control style2"
                              placeholder="Type Message"
                              defaultValue={""}
                              rows={2}
                            />
                          </div>
                          <div className="col-md-4">
                            <button
                              className="btn btn-style1 btn-green"
                              style={{
                                alignSelf: "center",
                              }}
                            >
                              Reply
                            </button>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end right -sidebar */}
      <Footer />
    </div>
  );
}

export default Msg;
