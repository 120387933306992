import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function Career() {
    return (
        <Layout pageName="Career">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Career with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>
                            CAREER OPPORTUNITIES                         </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                CAREER OPPORTUNITIES
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                        fontFamily: 'Montserrat'

                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-start" style={{ fontSize: "24px", fontWeight: "600" }}>
                        EXCITING. CHALLENGING. REWARDING.                   </h2>
                    <p style={{ fontSize: "17px" }}>
                        THE XCHANGE MARKETPLACE constantly looks for people with the interest and talent to fulfill a broad range of roles and functions from finance and sales to human resources and IT and beyond.
                        Marketplace Events is committed to its vision and values. As an employer, we support employees in finding a work/life balance and recognize the importance of a vibrant and dynamic work environment.
                        If you are interested in joining this type of organization, please Email Careers@TheXCHANGEMARKETPLACE.COM to learn more about positions listed on our website and other available opportunities.

                    </p>
                </div>

                <div className="container my-2">

                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        VIEW CURRENT JOB OPENINGS
                    </h4>
                    <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                        THE XCHANGEPLACE MARKETPLACE SALES MANAGER:
                    </h5>
                </div>


            </div>
            <div className="container my-2">
                <div className="row">
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Qualification
                        </h5>
                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>
                                Bachelor’s degree in business or related field is required
                            </li>
                            <li>3+ years managing and directing a sales team</li>
                            <li>Experience in planning and implementing sales strategies</li>
                            <li>Excellent written and verbal communication skills</li>
                            <li>Dedication to providing great customer service</li>
                            <li>Ability to lead a sale team</li>
                            <li>Driven to exceed goals</li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Responsibilities
                        </h5>
                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>
                                This position is responsible for supervising and organizing our team
                            </li>
                            <li>You will be responsible for managing organizational sales by developing business plans, meeting and exceeding planned goals, and coordinating with our marketing department on lead generation</li>
                            <li>You will also be tasked with overseeing the activities and performance of your sales team by tracking goals, setting individual sales targets, and facilitate the ongoing training of your salespeople</li>
                            <li>Build and manage a solid team that exceeds sales goals</li>
                            <li>Ensure that sales pipeline remains full of qualified leads & prospects</li>
                            <li>Coordinate sales & operations activities to assist reps in winning deals</li>
                            <li>Monitor employee productivity and motivate team to reach daily goals</li>
                            <li>Ensure accurate and complete information is captured in CRM-Salesforce</li>
                            <li>Supervise team in accordance with company policies & procedures</li>
                            <li>Conduct employee interviews, orientations, and hiring of staff</li>
                            <li>Report to senior management on sales metrics, opportunities, and threats</li>
                            <li>Develop your sales team through motivation, coaching and product knowledge education</li>
                            <li>Understand our ideal customers and how they relate to our products</li>

                        </ul>
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>Benefits</h5>
                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>Competitive comp (base + uncapped commission)</li>
                            <li>Medical, dental and vision insurance</li>
                            <li>Life insurance</li>
                            <li>Flexible Time Off</li>
                            <li>Maternity & Paternity leave</li>
                            <li>Free coffee</li>
                            <li>And more!</li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="container my-2">
                <h4 style={{ fontSize: "19px", fontWeight: "600" }}>THE XCHANGEPLACE MARKETPLACE WEBSTORE EXPERT</h4>
                <div className="row">
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Qualification
                        </h5>
                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>
                                Must be fluent in english
                            </li>
                            <li>Experienced or have knowledge of eCommerce</li>
                            <li>Proficient with excel</li>
                            <li>Team skills & communication</li>
                            <li>Fast to learn computer or web applications</li>
                            <li>Able to Multitask</li>

                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Responsibilities
                        </h5>

                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>Uploading to marketplaces</li>
                            <li>Assisting in gathering images and taking photos for products</li>
                            <li>Search & fix product errors & submitting support tickets</li>
                            <li>Updating product images, titles, pricing</li>
                            <li>Matching codes on excel</li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className="container my-2">
                <h5 style={{ fontSize: "18px", fontWeight: "600" }}>Job description</h5>
                <p style={{ fontSize: "17px" }}>Online retail company in need of an expert level person for a position to assist in daily marketplace uploads and to other ecommerce website (Amazon Webstore). hours are from 8:30-5:30 EST. Primary Tasks · Uploading to marketplaces · Assisting in gathering images and taking photos for products. · Search & fix product errors & submitting support tickets. · Updating product images, titles, pricing · Matching codes on excel Must be: · Must be fluent in english · Experienced or have knowledge of eCommerce · Proficient with excel · Team skills & communication · Fast to learn computer or web applications · Able to Multitask</p>
            </div>
            <div className="container my-2">
                <h4 style={{ fontSize: "19px", fontWeight: "600" }}>THE XCHANGEPLACE MARKETPLACE ECOMMERCE MANAGER</h4>
                <div className="row">
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Qualification
                        </h5>
                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>Bachelor’s degree in Marketing and/or other related degree</li>
                            <li>2-5+ years of E-commerce experience</li>
                            <li>Extensive knowledge of Amazon, Ebay, and other marketplaces and the idiosyncrasies of each</li>
                            <li>Experience with marketplace selling tools ie Sellbrite, Channel Advisor, etc</li>
                            <li>Experience with marketplace digital marketing/sponsored promotions</li>
                            <li>Demonstrated understanding of marketplace analytics, reporting and content</li>

                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                            Responsibilities
                        </h5>

                        <ul style={{ fontFamily: 'Montserrat', color: "black" }}>
                            <li>The Ecommerce Marketplace Manager will leverage their extensive knowledge of marketplaces, managing multiple storefronts, to grow sales marketplaces.(managing listing, assortment, product information gathering, analyzing traffic data, and solving problems)</li>
                            <li>Manage Listings and optimize product visibility</li>
                            <li>Research new opportunities, analyze data and grow revenue through each marketplace</li>
                            <li>Generate bold and innovative ideas for growth</li>
                            <li>Manage overall strategy, sales, forecasts and results</li>
                            <li>These include short- and long-term objectives, customer metrics and overall performance</li>
                            <li>Establish and manage marketplace relationships</li>
                            <li>Develop reports and reporting formats</li>
                            <li>Weekly/Monthly/Quarterly Sales reporting and analysis</li>
                            <li>Collaborate closely with our acquisition partners to develop and execute on new Marketplace initiatives and opportunities designed to meet and exceed the Marketplace E-Commerce revenue goals</li>
                            <li>Act as a liaison with internal and external parties and vendors to educate and collaborate on category/product launches, updates, and organization-wide initiatives</li>
                            <li>Oversee the day-to-day marketplace
                                E-commerce operations, sales, updates, post listings, promotions, merchandising, maintenance and performance
                            </li>
                            <li>Ensure that daily site maintenance is conducted; correcting product descriptions, product placement, new item posting, out of stock item removal and functionality</li>
                            <li>Develop time and action calendars with processes and procedures to ensure actions are successfully completed</li>
                            <li>Plan out and execute marketplace sponsored promotions to maximize growth</li>
                            <li>Sponsored promotions tracking and coordination; report as necessary on specific promotional plans and performance</li>
                            <li>Compare past and current sales trends to assist in determining future inventory demand</li>
                            <li>Develop and implement the assortment and inventory plan to match the sales plan</li>
                            <li>Work with other divisions to maximize inventory</li>
                            <li>Competitive research on marketplaces, translate findings into insights and actionable growth.tactics</li>
                            <li>Proactively research online marketplace to ensure accuracy and delivery of our content, while continuously looking at ways to improve brand position</li>


                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Career;
