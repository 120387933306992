import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import styled from "styled-components";
import { formatDate, logout } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import { changeQuantity, clearCart, removeFromCart } from "../../redux/actions";
import api from "../../api";
import { useSnackbar } from "../../lib/context";
import CustomLoader from "../../components/CustomLoader";
import { useParams } from "react-router-dom";
import { date } from "yup";
import Footer from "../../layout/Footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function OrderSummary() {
  const { push } = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState({
    state: false,
    data: "",
  });

  const { openSnackbar } = useSnackbar();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const myState = useSelector((state) => state.cartReducer);
  const [otherDetails, setOtherDetails] = useState({
    deliveryaddress: "",
    postcode: "",
  });
  const dispatch = useDispatch();
  const handleDialogOpen = (data) => {
    console.log(data);
    setIsDialogOpen({ state: true, data });
  };
  const handleDialogClose = () => {
    setIsDialogOpen({ state: false, data: {} });
  };
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") == null ||
      !localStorage.getItem("data") ||
      localStorage.getItem("data") == null
    ) {
      setRedirectToLogin(true);
    }
  }, []);

  const placeOrder = async () => {
    try {
      const totalAmount = myState.reduce((acc, item) => {
        const itemTotal =
          item.discountprice !== ""
            ? item.discountprice * item.quantity
            : item.price * item.quantity;
        return acc + itemTotal;
      }, 0);

      const { data } = await api.post("order/save", {
        product: myState,
        sellerid: myState[0].sellerid,
        deliveryaddress: otherDetails.deliveryaddress,
        postcode: otherDetails.postcode,
        total: totalAmount,
      });
      console.log(data, "orderrr");
      // setOrders(data);
      openSnackbar("success", "Order placed successfully.");
      dispatch(clearCart());
      push("/buyerorder");
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  // const productQty = () => {
  //   let total = 0;
  //   for (let p of myState) {
  //     total +=
  //       p.discountprice !== ""
  //         ? p.discountprice * p.quantity
  //         : p.price * p.quantity;
  //   }
  //   return total;
  // };
  console.log(isDialogOpen);
  return (
    <div>
      <Dialog
        open={isDialogOpen.state}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* {isDialogOpen.data && <h2>{isDialogOpen.data.productname}</h2>} */}
        </DialogContent>
        {isDialogOpen.data && (
          <>
            <div className="px-3">
              <HighlightOffIcon
                style={{ color: "#48a068", float: "right" }}
                onClick={handleDialogClose}
              />
              <div className="order-details brdr-none">
                <h2>ORDER DETAILS</h2>
                {/* <div className="od-list">
                  <label>Order Date:</label> {formatDate(data.orderdate)}
                </div> */}
                {/* <div className="od-list">
                  <label>Order Number:</label> #{myState.ordernumber}
                </div> */}
                <div className="od-list">
                  <label>Order Total:</label> ${" "}
                  {isDialogOpen.data.discountprice !== ""
                    ? isDialogOpen.data.discountprice *
                      isDialogOpen.data.quantity
                    : isDialogOpen.data.price * isDialogOpen.data.quantity}
                </div>
              </div>
              {/* end  order-details */}
              <div className="order-details brdr-none">
                <h2>PRODUCT DETAILS</h2>
                <div className="od-list">
                  <strong>Product</strong> <br />
                </div>
                <div className="od-list mt-2 mb-2">
                  {isDialogOpen.data &&
                  isDialogOpen.data &&
                  isDialogOpen.data.primaryfile &&
                  isDialogOpen.data.primaryfile.url ? (
                    <img
                      src={isDialogOpen.data.primaryfile.url}
                      style={{
                        width: "80px",
                      }}
                      alt=""
                      // className="img-responsive"
                    />
                  ) : (
                    <ImageNotSupportedIcon
                      style={{
                        fontSize: "50px",
                        color: "#6e6e6e",
                      }}
                    />
                  )}
                </div>
                <div className="od-list mb-2">
                  <label>Product Name:</label>

                  {isDialogOpen.data.productname}
                </div>
                <div className="od-list">
                  <label>Catagory:</label> Test &amp; Measurement
                </div>
                <div className="od-list">
                  <label>Brand:</label> {isDialogOpen.data.manufacturer}
                </div>
                {/* <div className="od-list">
                  <label>Seller:</label> SeqGen
                </div> */}
              </div>
            </div>
          </>
        )}
      </Dialog>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <BuyerSidebar pageName="cart" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <BuyerTitle />
          </div>
          {/* end box-content */}
          <div
            className="my-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              className="btn btn-lg"
              onClick={() => push("/cart")}
              style={{ cursor: "pointer" }}
            >
              <ArrowBackIcon sx={{ fontSize: 30 }} />
            </button>
          </div>
          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div className="nav nav-tabs">
                    <a className="nav-item active">Order Summary</a>
                  </div>
                </nav>
                <div className="payments-content">
                  {!myState || myState == null || myState.length == 0
                    ? "No Items Found in Cart"
                    : myState.map((item, index) => (
                        <>
                          {index == 0 && (
                            <div className="paymentsCon-thead">
                              <div id="prod" className="th-col tg-pro">
                                Product
                              </div>
                              <div className="th-col tg-price">Price</div>
                              <div className="th-col tg-price">Quantity</div>
                              <div className="th-col tg-price">Total</div>
                              <div className="th-col tg-button"></div>
                              <div className="th-col tg-action"></div>
                            </div>
                          )}
                          <div className="paymentsCon-item-row">
                            <div id="prod" className="td-col tg-pro">
                              <div className="tg-pro-img">
                                {item.primaryfile && item.primaryfile.url ? (
                                  <img
                                    src={item.primaryfile.url}
                                    style={{
                                      width: "60px",
                                    }}
                                    alt=""
                                    className="img-responsive"
                                  />
                                ) : (
                                  <ImageNotSupportedIcon
                                    style={{
                                      fontSize: "50px",
                                      color: "#6e6e6e",
                                    }}
                                  />
                                )}
                              </div>
                              {item.productname}

                              <button
                                type="button"
                                className="view-more pt-3"
                                data-toggle="modal"
                                data-target="#viewOrderDetail"
                                onClick={() => handleDialogOpen(item)}
                              >
                                View Details
                              </button>
                            </div>
                            <div className="td-col tg-price">
                              ${/* {item.price} */}
                              {item.discountprice !== ""
                                ? item.discountprice
                                : item.price}
                            </div>
                            <div className="td-col tg-price">
                              {item.quantity}
                            </div>
                            <div className="td-col tg-price">
                              ${/* {item.price} */}
                              {item.discountprice !== ""
                                ? item.discountprice * item.quantity
                                : item.price * item.quantity}
                            </div>

                            <div id="delete" className="td-col tg-action">
                              <DeleteIcon
                                style={{
                                  fontSize: "36px",
                                  color: "red",
                                }}
                                onClick={() => {
                                  const prod = myState.find(
                                    (p) => p.id == item.id
                                  );
                                  console.log(item.quantity);

                                  if (item.quantity == 1) {
                                    dispatch(removeFromCart(item.id));
                                  } else {
                                    dispatch(
                                      changeQuantity(item.id, item.quantity - 1)
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                </div>
                {myState && myState != null && myState.length > 0 && (
                  <div
                    // className="full-block mt-3 btn-reply"
                    style={{ marginTop: "42px" }}
                  >
                    <div className="row">
                      <div className="col-md-8 mb-3">
                        <textarea
                          className="form-control style2"
                          placeholder="Notes"
                          defaultValue={""}
                          maxLength={500}
                          rows={2}
                          onChange={(event) =>
                            setOtherDetails({
                              ...otherDetails,
                              deliveryaddress: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-style1 btn-green"
                          style={{
                            alignSelf: "center",
                          }}
                          // onClick={() => {
                          // 	redirectToLogin
                          // 		? push(
                          // 				'/loginpage/buyer/order'
                          // 		  )
                          // 		: push(
                          // 				'/buyerorder'
                          // 		  );
                          // }}
                          onClick={placeOrder}
                        >
                          Place Order
                        </button>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control style2"
                          placeholder="Preferred Payment Method"
                          defaultValue={""}
                          onChange={(event) =>
                            setOtherDetails({
                              ...otherDetails,
                              postcode: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* {myState &&
									myState != null &&
									myState.length > 0 && (
										<Button
											style={{
												color: 'white',
												textTransform: 'uppercase',
												height: '2em',
												fontSize: '1.3em',
												float: 'right',
												whiteSpace: 'nowrap',
												backgroundColor: '#51a470',
												marginBottom: '2px',
												borderRadius: '1em',
											}}
											variant="contained"
											color="primary"
											onClick={() => {
												redirectToLogin
													? push(
															'/loginpage/buyer/order'
													  )
													: push('/buyerorder');
											}}
										>
											Order
										</Button>
									)} */}
              </div>
            </div>
          </div>
        </div>
        {/* end right -sidebar */}
      </div>

      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
      <Footer />
    </div>
  );
}

export default OrderSummary;
