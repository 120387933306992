import React, { useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import CustomTextField from '../components/CustomTextField';
import CustomCheckbox from '../components/CustomCheckbox';
import CustomButton from '../components/CustomButton';
import { useHistory } from 'react-router-dom';
import api from '../api';
import OtpInput from 'react-otp-input';
import { Box } from '@mui/material';

function ForgotPasswordForm({ setShowForgotPassword }) {
	const { push } = useHistory();
	const initialValues = { email: '', typer: 'buyer' };
	const handleSubmit = (values) => {
		setShowForgotPassword(false);
	};
	const [showStripe, setShowStripe] = useState(false)

	const handleForgetPassword = async (values, { resetForm }) => {
		try {
			const { data } = await api.post(
				values.type == 'buyer' ? 'signup/buyer' : 'signup/seller',

			)
			localStorage.setItem('data', JSON.stringify(data.data))
			localStorage.setItem('token', 'Bearer ' + data.authToken)
			localStorage.setItem('type', values.type == 'buyer' ? 'buyer' : 'seller')
			values.type == 'buyer'
				? push('/loginpage')
				: values.type == 'seller'
					? setShowStripe(true)
					: push('/loginpage')
			//   push('/overview');
			resetForm()
			// openSnackbar('success', 'Signup successfully.')
		} catch (error) {
			if (
				error.response !== null ||
				error.response.data !== null ||
				error.response.data.errors.length !== 0
			) {
				// error.response.data.errors.forEach(e => openSnackbar('error', e))
			} else {
				// openSnackbar('error', error.message)
			}
		}
	}
	return (
		<div style={{ maxWidth: '420px' }}>
			<Typography
				style={{
					fontSize: '28px',
					color: '#4aa56d',
					// textTransform: 'uppercase',
					fontWeight: 600,
					marginBottom: '8px',
				}}
			>
				Forgot your password?
			</Typography>
			<Typography
				style={{
					fontSize: '14px',
					color: '#737979',
					marginBottom: '16px',
				}}
			>
				Please enter the email address registered on your account to
				reset your password
			</Typography>
			<Formik initialValues={initialValues} onSubmit={handleSubmit}>
				{({ values, setFieldValue, ...rest }) => {
					console.log('values', values);
					return (
						<Form>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field
										as={CustomTextField}
										name="email"
										label="Email"
										placeholder="Email"
										setFieldValue={setFieldValue}
									/>
								</Grid>
								<Box>
									{/* <Button onClick={() => handleSubmit(sendOtp())}>Send OTP</Button> */}
								</Box>
								<Grid item xs={12}>
									<Typography sx={{ fontSize: "3rem", fontWeight: "bold" }} variant='h6'>
										Enter Verification Code
									</Typography>

									<OtpInput
										inputType='tel'
										numInputs={4}
										renderSeparator={<span> - </span>}
										renderInput={(props) => <input {...props} />}
										inputStyle={{ width: "45px", borderRadius: "10px", height: "45px" }}
										containerStyle={{ display: "flex", justifyContent: "center", alignItems: "center" }}
										onChange={(otp) => setFieldValue('otp', otp)}
										value={values.otp}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									style={{ marginTop: '12px' }}
								>
									<CustomButton
										type="submit"
										variant="contained"
										style={{ width: '100%' }}
										color="primary"
										disabled={rest.isSubmitting}
									>
										Reset Password
									</CustomButton>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}

export default ForgotPasswordForm;
