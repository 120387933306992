import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/brands.css';
import '../src/assets/css/fontawesome.min.css';
import '../src/assets/css/solid.css';
import '../src/assets/css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
// import { SnackbarProvider } from 'material-ui-toast';
import { SnackbarProvider } from '../src/components/snackbar';

ReactDOM.render(
	<React.StrictMode>
			<SnackbarProvider
				// SnackbarProps={{ autoHideDuration: 3000 }}
				// direction={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<App />
			</SnackbarProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
