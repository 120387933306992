import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";

function TermsAndCondition() {
    return (
        <Layout pageName="TermsAndCondition">
            {/* <div className="container text-center my-2 mb-4">
                <h1>TermsAndCondition with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Terms And Condition
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Terms And Condition
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        Terms And Conditions                  </h2>

                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        The XCHANGE MARKETPLACE User Agreement/Terms & Conditions:                        </h3>

                    <p style={{ fontSize: "17px" }}>
                        The following document defines the terms on which The XCHANGE MARKETPLACE offers
                        service access and under no circumstances can these terms and conditions be altered without
                        the written consent of a The XCHANGE MARKETPLCE authorized officer.                        </p>

                    <p style={{ fontSize: "17px" }}>
                        This agreement may be modified by The XCHANGE MARKETPLACE at any time without notice
                        and it is the deemed responsibility of the users of The XCHANGE MARKETPLACE to check
                        periodically for modifications.                    </p>

                    <ol style={{ fontSize: "17px" }}>
                        <li>Membership Eligibility <br />
                            The XCHANGE MARKETPLACE offers its services only to individuals who are able to enter into
                            legally binding contracts. The XCHANGE MARKETPLACE does not offer services to minors or
                            to users who have been temporarily or permanently suspended from using LabX for any reason.
                            Further, The XCHANGE MARKETPLACE may refuse services to individuals or entities which, at
                            the sole discretion of The XCHANGE MARKETPLACE, have been determined to pose harm or
                            detriment to The XCHANGE MARKETPLACE website.</li>
                        <li>
                            Fees and Services <br />
                            The XCHANGE MARKETPLACE offers many services at no charge. These services include but
                            are not limited to browsing, searching, registering, and bidding. We reserve the right to modify
                            our fee schedule at any time without advance notice. Notification of any charges to your account
                            will be sent before they are incurred. All fees for The XCHANGE MARKETPLACE services are
                            quoted in U. S. Dollars. Users are responsible for paying all fees and any applicable taxes
                            incurred while using The XCHANGE MARKETPLACE services.                            </li>
                        <li>
                            The XCHANGE MARETPLACE is a Media and Classified Advertising Service and Does Not
                            Take Any Title to Goods
                            <ul>
                                <li>
                                    3.1 Listings and LabAuctions Although The XCHANGE MARKETPLACE provides a forum for
                                    buyers and sellers to negotiate a price and purchase used and surplus laboratory equipment,
                                    The XCHANGE Marketplace is not a traditional auctioneer. The XCHANGE Marketplace acts as
                                    a media service which allows anyone to offer, sell, solicit a purchase, and buy anything related
                                    to the laboratory, medical, or test/measurement areas, at any time, from anywhere around the
                                    world, in many different formats, including but not limited to a fixed-price format, an auction-style
                                    format (commonly referred to as a "LabAuction"), and an equipment-wanted format. The
                                    XCHANGE MARKETPLACE is not and will not become involved in the actual transaction
                                    between buyers and sellers. Subsequently, THe XCHANGE Marketplace can exercise no
                                    control over the quality, safety, or legality of the items advertised. The XCHANGE
                                    MARKETPLACE also has no ability to verify the truth or accuracy of the listings, the ability of the
                                    sellers to sell items, or the ability of the buyers to buy items. WE CANNOT ENSURE THAT A
                                    BUYER OR A SELLER WILL COMPLETE A TRANSACTION.
                                </li>
                                <li>
                                    3.2 User Authentication. Due to the anonymous nature of the Internet, it is difficult and
                                    sometimes impossible to confirm each user's purported identity. The XCHANGE
                                    MARKETPLACE also encourages communication directly with potential trading partners
                                    through the tools available on our site. To add an additional level of security to any transaction,
                                    The XCHANGE MARKETPLACE recommends the use of a third-party payment service or
                                    escrow provider when conducting a transaction with an unknown individual or company.
                                </li>
                                <li>
                                    3.3 Release. THE XCHANGE MARKETPLACE provides only the "service" which allows buyers
                                    and sellers to connect. THE XCHANGE MARKETPLACE is not involved in the actual
                                    transaction. In the event that a dispute erupts with one or more users, The XCHANGE
                                    MAARKETPLACE (its officers, directors, agents, employees, and subsidiaries) is released from
                                    claims, demands and damages (actual and consequential) of every kind and nature, known and
                                    unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any
                                    connection with such disputes.
                                </li>
                                <li>
                                    3.4 Information Control. The XCHANGE MARKETPLACE does not control the information
                                    provided by any user and made available through the The XCHANGE MARKETPLACE system.
                                    Please be aware that other users' information may be found to be offensive, harmful, inaccurate,
                                    or deceptive. Exercise caution, common sense, and safe trading practices when using The
                                    XCHANGE MARKETPLACE website. Please note that there are also risks of dealing with
                                    underage persons or people acting under false pretenses. Additionally, there may be risks when
                                    dealing with international trade and foreign nationals.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Listing and Selling
                            <ul>
                                <li>
                                    4.1 Listing Description. All users must be legally able to sell any item(s) posted for sale on the
                                    XHANGE MARKETPLACE website. A full description of the item, including all terms of sale,
                                    should be included on the listing page. Listings may include text descriptions, graphics, pictures,
                                    videos and other content relevant to the sale of that item. All listed items must be listed in an
                                    appropriate category. If no category is selected, The XCHANGE MARKETPLACE will categorize
                                    each classified advertisement or auction as it deems appropriate.
                                </li>
                                <li>
                                    4.1.1 Hyperlinks. The use of a hyperlink in any XCHANGE MARKETPLACE listing is
                                    exclusively reserved for The XCHANGE MARKETPLACE Advertising Sponsors. The
                                    XCHANGE MARKETPLACE will automatically include a hyperlink for all sponsors in their
                                    posted ads. Sponsors who have a specific need to include an additional hyperlink in their ad
                                    should contact their The XCHANGE MARKETPLACE sales representative for details. Wanted
                                    Ads are not permitted to have hyperlinks in them from any sponsor or non-sponsor. Any
                                    hyperlinks submitted by non-sponsors into The XCHANGE MARKETPLACE listings will be
                                    removed without notice. For more information about the use of hyperlinks in your ads contact
                                    The XCHANGE MARKETPLACE Customer Support.
                                </li>
                                <li>
                                    4.2 Price Manipulation. Price manipulation is prohibited. If MARKETPLACE determines that a
                                    user has manipulated or attempted to manipulate the final sale price of any item, that user will
                                    be immediately suspended from the The XCHANGE MARKETPLACE system without notice.
                                </li>
                                <li>
                                    4.3 Fraud. In addition to other penalties, The XCHANGE MARKETPLACE may suspend or
                                    terminate a user account if fraud is suspected. Any reports of fraudulent behavior on The
                                    XCHANGE MARKETPLACE are taken very seriously and thoroughly investigated.
                                </li>
                                <li>
                                    4.4 Cancellations and Refunds. As the seller of an item, withdrawal of any item from an auction
                                    is only acceptable if the item has been damaged or stolen in the period between the initial listing
                                    date and the date of the close of the auction, or if the condition or description has been
                                    inadvertently misrepresented in the auction text. It is not acceptable to withdraw an item from
                                    the auction forum prior to the close of the auction to accommodate alternate sales
                                    arrangements. Under no circumstances is a bidder permitted to offer to purchase the item
                                    immediately except in cases where the Auction Stop feature is utilized. Refunds for cancelled
                                    advertisements will be made according to the following refund schedule:
                                </li>
                                <li>
                                    4.4.1 All Other XCHANGE MARKETPLACE Classified Ads. Credit will be awarded for any ad
                                    cancelled in writing, if you submit your request to cancel the advertisement to
                                    help@XCHANGEMARKETPLACE.com within 24 hours of posting the advertisement.
                                    XCHANGE MARKETPLACE will process all cancellation requests regardless of the time frame
                                    in which they were received; however, credit will only be refunded to those classified
                                    advertisements whose request was received within 24 hours of the original posting.
                                </li>

                            </ul>
                        </li>
                        <li>
                            Solicitation And Interference <br />
                            The XCHANGE MARKETPLACE system encourages the open exchange of all types of
                            industrial and scientific equipment, supplies, and other artifacts. Use of the XCHANGE
                            MARKETPLACE system to solicit against the interests of The XCHANGE MARKETPLACE and
                            its users is prohibited. XCHANGE MARKETPLACE users who solicit in the following ways will
                            be liable for damages suffered by The XCHANGE MARKETPLACE.
                            <ul>
                                <li>
                                    5.1 Solicitation Against The XCHANGE MARKETPLACE. Any user who mines The XCHANGE
                                    MARKETPLACE system to refer our users to competitive websites will be suspended from the
                                    The XCHANGE MARKETPLACE system.
                                </li>
                            </ul>
                        </li>
                        <li>
                            The XCHANGE MARKETPLACE Wanted Ads <br />
                            LabX is a forum dedicated to serving the needs of scientific, medical and test/measurement
                            users all around the globe. In an effort to help users locate equipment which is obsolete or "hard
                            to find," LabX has established this free service for this purpose. This service is limited for use as
                            follows:
                            <ul>
                                <li>
                                    6.1 Non-commercial Use. The XCHANGE MARKETPLACE Wanted Ads are not to be used to
                                    promote traffic to another website or to promote a business endeavor. The XCHANGE
                                    MARKETPLACE Wanted Ads are to be used by individuals or companies looking to find an
                                    instrument, accessory, or part for their own use. Wanted Ads are not for use by companies
                                    looking to obtain instruments or equipment for immediate resale or for repair and resale. Any
                                    Wanted Ads which are overtly commercial or perceived to be commercial by The XCHANGE
                                    MARKETPLACE will be removed from The XCHANGE MARKETPLACE system without notice.
                                    Vendors are encouraged to use The XCHANGE MARKETPLACE Announcement ads or
                                    purchase a Various Items Wanted ad to locate equipment for resale.
                                </li>
                                <li>
                                    6.2 Relevant Requests. The XCHANGE MARKETPLACE Wanted Ads are limited in scope. Ads
                                    must pertain to requests for scientific, medical, or test/measurement instruments, equipment,
                                    accessories, parts, or supplies. Any advertisement which does not meet this criterion will be
                                    removed from the The XCHANGE MARKETPLACE system without notice.
                                </li>
                                <li>
                                    6.3 All Rights Reserved. The XCHANGE MARKETPLACE reserves all rights to approve or
                                    decline a Wanted Ad for any reason it deems appropriate without explanation or notice.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Personal Information
                            <ul>
                                <li>
                                    7.1 Definition. "Personal Information" is defined as any information provided during the
                                    registration, bidding, or listing process, in any public message area, or through any e-mail
                                    feature. Users are solely responsible for all personal information, and The XCHANGE
                                    MARKETPLACE acts as a passive conduit for online distribution and publication of the user's
                                    personal information.
                                </li>
                                <li>
                                    7.2 Restrictions. Personal Information (or any items listed): must be explicitly and implicitly
                                    accurate; must not be fraudulent; must not be illegal; must be appropriate and pertinent to the
                                    subject matter of the The XCHANGE MARKETPLACE website; and must not contain any
                                    hyperlinks to locations outside of the The XCHANGE MARKETPLACE site without prior
                                    authorization from The XCHANGE MARKETPLACE or in cases in which this information is
                                    specifically requested by The XCHANGE MARKETPLACE. The XCHANGE MARKETPLACE
                                    reserves the right to modify or remove any information from the site for any reason it deems
                                    necessary.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Access and Interference <br />
                            It is agreed that there will be no use of any robot, spider, other communication device, or
                            manual process to monitor or copy The XCHANGE MARKETPLACE web pages or the content
                            contained herein without prior expressed written permission from The XCHANGE
                            MARKETPLACE. It is agreed that use of any device, software, or routine to interfere or attempt
                            to interfere with the proper working of the The XCHANGE MARKETPLACE site or any auction
                            being conducted is prohibited. It is agreed that no action will be taken that imposes an

                            unreasonable or disproportionately large load on The XCHANGE MARKETPLACE
                            infrastructure. Much of the information on the The XCHANGE MARKETPLACE site is updated
                            on a real-time basis. It is agreed that no user shall copy, reproduce, alter, modify, create
                            derivative works, or publicly display any content from the The XCHANGE MARKETPLACE
                            website without the prior expressed written permission of The XCHANGE MARKETPLACE or
                            the appropriate third party.
                        </li>
                        <li>
                            Non-compliance <br />
                            In addition to other potential penalties, The XCHANGE MARKETPLACE may immediately issue
                            a warning, temporarily suspend, indefinitely suspend, or terminate membership and refuse to
                            provide services if: (a) there is a breach of this Agreement or the documents to which it refers;
                            (b) The XCHANGE MARKETPLACE is unable to verify or authenticate any information
                            provided; (c) The XCHANGE MARKETPLACE believes that a user's actions may cause legal
                            liability.
                        </li>
                        <li>
                            Privacy <br />
                            The XCHANGE MARKETPLACE understands that our buyers and sellers are central to our
                            continued success. From time to time, we may use your information to send you e-mail and
                            other communications about our The XCHANGE MARKETPLACE service or on behalf of third
                            parties where it supports the activities of our users. When you provide information to this site,
                            you will be given an opportunity to "opt out" of our sending you offers on behalf of third parties.
                            In addition, each of these communications will include instructions for opting out of receiving
                            future third party offers from us. You may also opt out of receiving third party offers from this
                            Web Site at any time by sending us an e-mail. In rare instances, The XCHANGE
                            MARKETPLACE may be compelled to provide information to law enforcement authorities in the
                            event that illegal activity has occurred or is suspected to have occurred in connection with the
                            LabX.com website. To review our current privacy policy see: The XCHANGE MARKETPLACE
                        </li>
                        <li>
                            Additional Legal Information
                            <ul>
                                <li>
                                    11.1 No Warranty. The XCHANGE MARKETPLACE and its suppliers provide its website and
                                    services "as is" and without any warranty or condition, expressed, implied or statutory. The
                                    XCHANGE MARKETPLACE and its suppliers specifically disclaim any implied warranties of
                                    title, merchantability, fitness for a particular purpose, and non-infringement.
                                </li>
                                <li>
                                    11.2 Liability Limit. In no event shall The XCHANGE MARKETPLACE or our suppliers be liable
                                    for lost profits or any special, incidental, or consequential damages arising out of or in
                                    connection with the site, the services, or this agreement (however arising, including negligence).
                                </li>
                                <li>
                                    11.3 Indemnity. It is agreed to indemnify and hold The XCHANGE MARKETPLACE and (as
                                    applicable) its parent, subsidiaries, affiliates, officers, directors, agents, and employees
                                    harmless from any claim or demand, including reasonable attorneys' fees, made by any third
                                    party due to or arising out of any breach of this Agreement or the documents to which it refers,
                                    or violation of any law or rights of a third party.
                                </li>
                                <li>
                                    11.4 Legal Compliance. All users shall comply with all applicable domestic and international
                                    laws, statutes, ordinances, and regulations regarding the use of this service and bidding on,
                                    listing, purchase, solicitation of offers to purchase, and sale of items.
                                </li>
                                <li>
                                    11.5 No Agency. All users and The XCHANGE MARKETPLACE are independent contractors,
                                    and no agency, partnership, joint venture, employer- employee, or franchiser-franchisee
                                    relationship is intended or created by this Agreement.
                                </li>
                                <li>
                                    11.6 Notices. Except as explicitly stated otherwise, any notices shall be given by certified postal
                                    mail to The XCHANGE MARKETPLACE, ATTN: Legal Department, 2878 Antoine Drive,
                                    Houston TX, 77092 (in the case of The XCHANGE MARKETPLACE) or to the e-mail address
                                    the user provides to The XCHANGE MARKETPLACE during the registration process (in the
                                    user's case). Notice shall be deemed given 24 hours after the e-mail is sent, unless the sending
                                    party is notified that the e-mail address is invalid. Alternatively, The XCHANGE MARKETPLACE
                                    may give notice by certified mail, postage prepaid and return receipt requested, to the address
                                    provided to The XCHANGE MARKETPLACE during the registration process. In such case,
                                    notice shall be deemed given three (3) days after the date of mailing.
                                </li>
                                <li>
                                    11.7 General. This Agreement shall be governed in all respects by the State of TX as such laws
                                    are applied to agreements entered into and to be performed entirely within Texas between
                                    Texas residents. The XCHANGE MARKETPLACE does not guarantee continuous,
                                    uninterrupted, or secure access to services, and operation of the site may be interfered with by
                                    many factors beyond The XCHANGE MARKETPLACE's control. If any provision of this
                                    Agreement is held to be invalid or unenforceable, such provision shall be struck, and the
                                    remaining provisions shall be enforced. It is agreed that this Agreement and all incorporated
                                    agreements may be automatically assigned by The XCHANGE MARKETPLACE, in sole
                                    discretion, to a third party in the event of a merger or acquisition. Headings are for reference
                                    purpose only and in no way define, limit, construe, or describe the scope or extent of such
                                    section. The XCHANGE MARKETPLACE's failure to act with respect to a breach does not
                                    waive the right to act with respect to subsequent or similar breaches. This Agreement sets forth
                                    the entire understanding and agreement between parties with respect to the subject matter
                                    hereof.
                                </li>
                                <li>
                                    11.8 Disclosures. The services hereunder are offered by The XCHANGE MARKETPLACE,
                                    located at 2878 Antoine Drive, Houston TX, 77092.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Additional Terms
                        </li>
                        <li>
                            Privacy Policy <br />
                            Our Privacy Policy is available for your review at The XCHANGE MARKETPLACE Privacy
                            Policy <br />
                            The XCHANGE MARKETPLACE may change our Privacy Policy from time to time without
                            notice.
                        </li>

                    </ol>
                </div>




                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        For more information Contact Us                    </h3>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Buyer & Seller Best Practices                                        </h4>
                    <p style={{ fontSize: "17px" }}>
                        The XCHANGE MARKETPLACE takes all user-to-user communications very seriously and we
                        reserve the right to monitor, delete or ban users who we consider inappropriate, disrespectful,
                        etc. The XCHANGE MARKETPLACE uses the services of ??? to reduce spam and improve the
                        customer experience. Members are encouraged to do their part in creating smooth and simple
                        transactions. The XCHANGE MARKETPLACE uses
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        While contacting other members, please:
                    </h4>
                    <ul>
                        <li>
                            Use only your real information (name, phone number, company name, etc.) Fake identification
                            or information will not be tolerated
                        </li>
                        <li>
                            Treat other users with respect and kindness; rude comments, racism, hate speech, etc. is
                            prohibited
                        </li>
                        <li>
                            Only inquire about the relevant item in the ad; spamming and/or commercial offers are not
                            allowed
                        </li>
                        <li>
                            If available, include the tracking number after the sale has been completed
                            Keep in mind that failure to respond respectfully or in a timely manner may mean poor ratings
                            from buyers
                        </li>
                        <li>
                            Try your best to describe/show your items as accurately as possible, to avoid any
                            miscommunication between the buyers and sellers.
                        </li>
                    </ul>
                    <p style={{ fontSize: "17px" }}>
                        Issues or disputes between buyers and sellers should be resolved respectfully between the two
                        of them, however The XCHANGE MARKETPLACE will become involved if necessary. If you
                        have received any communications that violate any of the above guidelines, or are having an
                        issue with another user, please let us know at Help@The XCHANGEMARKETPLACE.com
                    </p>

                    <p style={{ fontSize: "17px" }}>
                        If any of the above guidelines are violated, The XCHANGE MARKETPLACE may place certain
                        limitations on your selling/buying privileges, which may include placing your ads on hold, or
                        limiting access to certain parts of The XCHANGE MARKETPLACE.
                    </p>



                </div>

            </div>

        </Layout>
    );
}

export default TermsAndCondition;
