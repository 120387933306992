import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import productImage from "../assets/images/pro-img.jpg";
import { useHistory } from "react-router-dom";
import SellerSidebar from "./Sidebar/SellerSidebar";
import { logout } from "../utils/utils";
import SellerTitle from "./PageTitle/SellerTitle";
import Footer from "../layout/Footer";
import { Formik, Field, Form, useFormik } from "formik";

import api from "../api";
import { useSnackbar } from "../lib/context";

function Payments() {
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { openSnackbar } = useSnackbar();

  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(false);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const handleSubmit = async (values) => {
    console.log(document.activeElement.dataset.rowindex);
    const row = values.orders[document.activeElement.dataset.rowindex];
    const postValues = {
      orderid: row.id,
      comments: row.orderStatusComments,
      orderStatus: row.orderstatus,
      paymentStatus: row.paymentStatus,
      sellerid: row.sellerAccountData.id,
    };
    console.log(values);
    console.log(row);
    try {
      const { data } = await api.post("/order/update-status", postValues);
      openSnackbar("success", "Updated Successfully");
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      orders: [],
    },
    onSubmit: handleSubmit,
  });
  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "buyerAccountData.firstname",
    sortOrder: "asc",
    // status: "New Order",
    search: "",
  });

  const [totalElem, setTotalElem] = useState();
  const fetchData = async (signal) => {
    try {
      const response = await api.post("/order/getSellerOrders", option, {
        signal: signal,
      });
      console.log("API Response:", response);

      const { data } = response;
      console.log("Extracted Data:", data);
      formik.resetForm({
        values: {
          orders: data.body.content,
        },
      });
      // setOrders(data.body.content);
      setTotalElem(data?.body.totalElements);

      // setOrders(
      //   data.filter(
      //     (d) => d.orderstatus == "New Order" || d.orderstatus == "In Progress"
      //   )
      // );
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [option]);

  // if (!formik.values.orders) {
  //   return <CustomLoader />;
  // }
  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };

  return (
    <div>
      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <SellerSidebar pageName="payments" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <SellerTitle />
            </div>
            {/* end box-content */}
            <div className="box-content minColheight">
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <nav>
                    <div className="nav nav-tabs">
                      <a className="nav-item active">Payments</a>
                      <div className="order-right-filter">
                        <div className="search-blk">
                          <input
                            type="text"
                            placeholder="Search all orders"
                            className="form-control"
                          />
                          <input
                            type="submit"
                            defaultValue="Search"
                            className="btn-search"
                          />
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="form-sec" id="order-details-sec">
                    {formik.values.orders && formik.values.orders.length > 0 ? (
                      <form onSubmit={formik.handleSubmit}>
                        <table className="table">
                          <thead>
                            <tr>
                              {/* <th>Name</th> */}
                              <th
                                scope="col"
                                onClick={() =>
                                  handleSort("buyerAccountData.firstname")
                                }
                              >
                                Name{" "}
                                <span>
                                  {option.sortBy ===
                                    "buyerAccountData.firstname" &&
                                  option.sortOrder === "asc"
                                    ? "↑"
                                    : "↓"}
                                </span>
                              </th>
                              <th>Order Number</th>
                              <th>Address</th>
                              <th>Payment Status</th>
                              <th>Payment Method</th>
                              {/* <th>Message</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {formik.values.orders.map((item, i) => (
                              <tr key={item.ordernumber}>
                                <td>
                                  <h3>
                                    {item.buyerAccountData.firstname}{" "}
                                    {item.buyerAccountData.lastname}
                                  </h3>
                                </td>
                                <td>
                                  <p
                                    className="order-number"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <strong>{item.ordernumber}</strong>
                                  </p>
                                </td>
                                <td>
                                  <p style={{ fontSize: "15px" }}>
                                    {item.buyerAccountData.address}
                                  </p>
                                </td>

                                <td>
                                  <div className="row">
                                    <div className="col-md-12 order-status">
                                      <select
                                        className="form-control style2 statusinprogress"
                                        // value={values.paymentStatus}
                                        // onChange={(e) => {
                                        //   setFieldValue(
                                        //     "paymentStatus",
                                        //     e.target.value
                                        //   );
                                        // }}
                                        {...formik.getFieldProps(
                                          `orders.${i}.paymentStatus`
                                        )}
                                      >
                                        <option>Paid</option>
                                        <option selected>Unpaid</option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                                <td>Stripe</td>

                                {/* <td>
                                <div
                                  className="td-col tg-pay-status"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <MessageIcon />
                                </div>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    ) : (
                      <div>No Payment found</div>
                    )}
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <div>
                      <select
                        className="form-select mt-2 mx-2"
                        value={option.size}
                        onChange={(e) =>
                          setOption({ ...option, size: e.target.value })
                        }
                      >
                        {/* <option selected>{option.size}</option> */}
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            option.page === 1 ? "disabled" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({ ...option, page: option.page - 1 })
                            }
                            style={{ color: "black" }}
                          >
                            Previous
                          </span>
                        </li>
                        {Array.from(
                          { length: Math.ceil(totalElem / option.size) },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                option.page === index + 1 ? "active" : ""
                              }`}
                            >
                              <span
                                className="page-link"
                                onClick={() =>
                                  setOption({
                                    ...option,
                                    page: index + 1,
                                  })
                                }
                                style={
                                  {
                                    // backgroundColor: "#4ba26b",
                                    // color: "black",
                                  }
                                }
                              >
                                {index + 1}
                              </span>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            option.page === Math.ceil(totalElem / option.size)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({
                                ...option,
                                page: option.page + 1,
                              })
                            }
                            style={{ color: "black" }}
                          >
                            Next
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default Payments;
