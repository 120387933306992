import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function PrivacyPolicy() {
    return (
        <Layout pageName="PrivacyPolicy">
            {/* <div className="container text-center my-2 mb-4">
                <h1>PrivacyPolicy with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Privacy Policy & Disclaimer
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.7rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Privacy Policy & Disclaimer

                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        Privacy Policy & Disclaimer                            </h2>

                    <p style={{ fontSize: "17px" }}>This website and its affiliated micro-sites are owned and published by THE XCHANGE MARKETPLACE.</p>

                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        1.Your Personal Information and How We
                        Collect It                          </h3>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Information You Provide                  </h4>
                    <p style={{ fontSize: "17px" }}>
                        We collect information you provide to us through our websites and to affiliated micro-sites that
                        are owned and operated by The XCHANGE MARKETPLACE.. We collect your information
                        when you register for an account, purchase a product, submit and inquiry, subscribe to a
                        newsletter or email, download digital materials (e.g. infographics, ebooks, white papers etc.),
                        sign-up for a webinar or otherwise communicate with us through our websites.
                    </p>
                    <p style={{ fontSize: "17px" }}>
                        The data we collect from you can include personal information. Personal information is data that
                        can be used to identify an individual (such as first and last name, e-mail address, address,
                        telephone number etc.). Furthermore, to ensure that you have a genuine interest in our content,
                        products and services, we may also ask for work-related information such as your job title, your
                        company name, technologies you use, your area of work or industry, the type of business you
                        work for or own, and your scientific interests. We do this to ensure that we are providing you
                        with information that is relevant to you and your field of study or work. At no point will we ask for
                        any “sensitive” information such as religious beliefs, political affiliation, racial or ethnic origin or
                        any other information that does not pertain to your activity or interest in the sciences.
                    </p>

                    <p style={{ fontSize: "17px" }}>
                        The XCHANGE MARKETPLACE may also collect the following information from you based on your
                        interaction with our website:
                        <ul>
                            <li>
                                Subscription: When you subscribe to THE XCHANGE MARKETPLACE, we required that you
                                provide us with certain information in exchange for the services that we provide to you. The
                                information requested was necessary in order to protect the integrity of the THE XCHANGE
                                MARKETPLACE, to identify who uses our system, to provide you with a customized
                                experience, and to reduce any instances of fraudulent activity.
                            </li>
                            <li>
                                Ad Submission: When you submit a classified advertisement to THE XCHANGE
                                MARKETPLACE, we identify the ad as yours and record the information and images you
                                submit with the advertisement. We record any changes you make to the ad and keep all

                                changes on file. We also record the statistics associated with the advertisement such as (but
                                not limited to) hits, views, inquiries, and fees.
                            </li>
                            <li>
                                Service / Parts Mail Server: If you have chosen to subscribe to the Service Connect, you will
                                receive occasional messages from other users wishing to communicate their immediate
                                need for parts and service for specific items. Any requests you submit via the Service / Parts
                                Mail Server will be recorded.
                            </li>
                            <li>
                                THE XCHANGE MARKETPLACE Message Center: If you wish to contact dealers,
                                manufacturers, distributors, laboratories, or other companies via our The XCHANGE
                                MARKETPLACE Message Center to obtain service, or purchase laboratory equipment or
                                supplies, your message submitted via the will be recorded and any details you provide will be
                                sent to applicable third parties.
                            </li>
                            <li>
                                Payment System: If you provide payment to THE XCHANGE MARKETPLACE for
                                XCHANGE MARKETPLACE services, XCHANGE MARKETPLACE will record specific
                                information to help validate the payment request. If you pay by credit card, we will not record
                                the credit card number you use. We will record the addressing information on the credit card
                                as well as the approval or non-approval codes sent back from our credit card processor. If
                                you pay by bank check or money order, we will retain a copy of your payment instrument for
                                our records for a period of up to three years.
                            </li>
                            <li>
                                Other Correspondence: If you send personal correspondence, such as e-mail, letters,
                                documents, or if third parties send us correspondence about your activities which pertain to
                                the XCHANGE MARKETPLACE website, we may collect this information in your XCHANGE
                                MARKETPLACE file.
                            </li>
                        </ul>
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Information We Collect
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        To optimize our website, match content to your needs and deliver a more personalized user
                        experience, XCHANGE MARKETPLACE also automatically collects information regarding your use
                        of our websites. When visiting our brands online, we log information about your visit and also use
                        tracking mechanisms such as cookies, pixels and scripts to collect information about your interaction
                        with our websites or emails. These can be used to provide you with additional information that is
                        relevant to your work or scientific areas of interest.
                    </p>
                    <p style={{ fontSize: "17px" }}> <strong>Cookies</strong>  are digital text files placed on your computer. Using cookies is a common practice. We
                        may place cookies on your computer to allow us to recognize you on our websites when you visit us,
                        to measure online traffic, to present you with relevant products or services, or to customize your
                        experience. We use session cookies to make navigating our website easier, and they expire when
                        you close your browser. Additionally, tracking cookies are used to help us understand how you use
                        our websites, they enhance your user experience, and they remain on your computer or device for
                        an extended period of time. For more details, please see our <a target="_blank" style={{ cursor: "pointer" }} href="https://www.labx.com/cms/cookie-policy">cookie policy.</a> </p>
                    <p style={{ fontSize: "17px" }}>Your browser may include settings that allow you to block cookies or be notified when cookies are
                        being used. Please be aware that if you block or remove cookies, certain features and functions of
                        our websites may be unavailable or may not operate optimally.</p>
                    <p style={{ fontSize: "17px" }}> <strong>Pixels</strong>  are small graphic images placed in web pages and e-mail messages. They may be used to
                        count the number of visitors to our websites, to monitor how users navigate our websites, or to verify

                        how many articles or links were viewed.</p>
                    <p style={{ fontSize: "17px" }}> <strong>Scripts</strong>  are used to collect information about your interactions with our websites. They are
                        temporarily downloaded on your computer from our server, or the server of a third-party we work
                        with. They are active while you are connected to our websites, and are deleted or deactivated
                        thereafter. We do not install or download any software to your computer as part of your user
                        experience.</p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Information from Other Sources
                    </h4>
                    <p style={{ fontSize: "17px" }}>We may also collect or purchase information from other scientific industry sources, such as
                        scientific organizations, research conferences, academic institutions, scientific directories, etc.
                        Your personal information is only collected and stored in our databases if it is publicly available
                        and there is a clear link between your area of work and the content, products, and services we
                        offer.We may also combine this information with information we have already collected about
                        you in order to understand your needs better and provide you with a better user experience.</p>
                    <p style={{ fontSize: "17px" }}>If you are a resident of the European Union or the United States and all the information we have
                        collected about you is from third-party scientific sources, we will only communicate with you on a
                        trial basis with a limited number of attempts before we remove your information from our
                        database. We will also only contact you about scientific content and topics that you have shown
                        interest in or about scientific products you have purchased in the past that have been deemed
                        relevant to you and your industry, job, scientific interests or area of work. If we see that you have
                        not engaged with our communications or digital content, we will remove your personal
                        information from our database. If you do engage with our communications and digital content
                        before the end of our trial period, we will consider you an active contact for as long as you
                        continue to engage with our content and our websites in the future, or until you choose to
                        unsubscribe from future communications. We offer a means of unsubscribing on every
                        communication that we send to you and through our privacy policy. If you would like to know
                        more about how we engage with you, please <a style={{ cursor: "pointer" }} href="/contactus">contact us.</a> </p>
                    <p style={{ fontSize: "17px" }}>Known Canadian residents may be contacted if they have provided their personal information
                        through our websites, affiliated micro-sites, over the phone or at an event or conference where
                        they have expressed consent to be contacted by THE XCHANGE MARKETPLACE and its
                        affiliated brands.</p>
                    <p style={{ fontSize: "17px" }}>Canadian residents may be contacted, as allowed by law, if they have met one or more of the
                        following conditions of implied consent: having an existing business relationship (EBR) based
                        on a previous commercial transaction with the recipient; or having an existing non-business
                        relationship based on, for example, membership in your club, or if the recipient participated as a
                        volunteer for your charitable organization; persons who make their email address publicly
                        available by publishing it on a website may be contacted if it is not accompanied by a statement
                        indicating they do not want to receive commercial electronic messages at that address. If the
                        statement is not present, the message must relate to the recipient's business role, functions or
                        duties in an official or business capacity.</p>
                </div>



                <div className="container my-2">

                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        2.Your Personal Information and How You
                        Share It                                            </h4>
                    <p style={{ fontSize: "17px" }}>
                        Our websites may include features, such as message boards or comment sections, that allow
                        you to freely submit your personal information that can be viewed by others. We and others can
                        store, display, reproduce, publish, distribute, or otherwise use such information in any format,
                        and there is no guarantee that we will attribute the content to you. Keep in mind that if you post
                        information on our websites, others can access and share that information with third-parties as
                        well.
                    </p>

                </div>
                <div className="container my-2">

                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        3.Your Use of the THE XCHANGE
                        MARKETPLACE Communication Tools                               </h4>
                    <p style={{ fontSize: "17px" }}>
                        You may not use any XCHANGE MARKETPLACE communication tool to send spam, harassing
                        messages, or any other content which would violate this policy or the THE XCHANGE
                        MARKETPLACE User Agreement. The XCHANGE MARKETPLACE Communication tools have
                        been created to help facilitate the exchange of used and surplus scientific equipment on the
                        THE XCHANGE MARKETPLACE system. Any use contrary to this end is prohibited by THE
                        XCHANGE MARKETPLACE and will result in the suspension of these THE XCHANGE
                        MARKETPLACE privileges.
                    </p>
                </div>
            </div>

        </Layout>
    );
}

export default PrivacyPolicy;
