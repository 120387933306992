import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Paper, Typography, Container } from '@material-ui/core';
import { items } from '../lib/header-data';
import { useHistory } from 'react-router-dom';

function Layout(props) {
	const { pageName, children } = props;
	const isMobile = useMediaQuery('(max-width:425px)');
	const [showMenu, setShowMenu] = useState(false);
	// const { history } = useRouter();
	const { push } = useHistory();

	const useStyles = makeStyles((theme) => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		content: {
			backgroundColor:
				pageName == 'Home' ? '#f5f5fe' : theme.palette.default.main,
			paddingTop: isMobile ? 61 : 135,
			flexGrow: 1,
			minHeight: '90vh',
		},
		list: {
			height: 'calc(100vh - 61px)',
			width: '100vw',
			marginTop: 61,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(255, 255, 255, 0.78)',
			margin: '56px 0px 0px 0px',
			padding: 0,
			overflowY: 'hidden',
		},
		listItem: {
			fontFamily: 'Montserrat, sans-serif',
			fontSize: '18px',
			fontWeight: 600,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			color: '#898f8f',
			margin: '20px 0px',
			height: '24px',
			'&:hover': {
				cursor: 'pointer',
			},
		},
	}));
	const classes = useStyles();

	useEffect(() => {
		// if (!localStorage.getItem('authToken')) {
		// 	push('/signout');
		// }
	}, []);

	return (
		<div className={classes.root} style={{ overflowX: 'hidden' }}>
			<Header
				showMenu={showMenu}
				setShowMenu={setShowMenu}
				pageName={pageName}
			/>
			<Fade in>
				{showMenu ? (
					<Paper className={classes.list}>
						{items.map((item) => (
							<Typography
								key={item.id}
								className={classes.listItem}
								onClick={() => push(item.link)}
							>
								{item.name}
							</Typography>
						))}
					</Paper>
				) : (
					<main className={classes.content}>
						<Container
							maxWidth={false}
							style={{ maxWidth: '1720px' }}
						>
							{children}
						</Container>
					</main>
				)}
			</Fade>
			{/* <div
				style={{
					bottom: 0,
					// , marginBottom: 'calc(5% + 60px)'
				}}
			>
				<Footer />
			</div> */}
			<Footer />
		</div>
	);
}

Layout.propTypes = {
	pageName: PropTypes.string,
	children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
	pageName: '',
};

export default Layout;
